import React, { Component } from 'react';
import { Grid, Image, Header, Container } from 'semantic-ui-react'
import '../Css/custom.css';
import { Get,ServerUrl } from "../Classes/ApiHelper";

class MainHeader extends Component {
    
    state = {
        loading: true,
        status: -1,
        message: "",
        data: [],
        error: null
    }

    componentDidMount() {
        this.setState({ loading: true })
        let ExamLoadData = JSON.parse(sessionStorage.getItem('ExamData'));
        let ApiUrl = 'ExamLists/'+ ExamLoadData.ExamID;
        var ExamData = Get(ApiUrl)
        ExamData.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            })
        ).catch(error => this.setState({ error, loading: false }));
    }

    render() {
        const { data } = this.state;
        return (
            <div>
                <Container fluid className="HeaderBg">
                    <Container>
                        <Grid columns={3} stackable textAlign='center'>
                            <Grid.Row verticalAlign='middle'>
                                <Grid.Column width={4} >
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Header as='h1' textAlign='center' >{data.sName}</Header>
                                    <Header as='h4' textAlign='center'>{data.sTitle}</Header>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                <Image  src={ServerUrl + data.sLogo} />

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Container>
            </div>
        );
    }
}

export default MainHeader;