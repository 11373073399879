import React, { Component } from 'react';
import { Form, Radio, Segment, Header, Grid, Input, Dropdown, Button, Menu, Checkbox, Label, GridColumn } from 'semantic-ui-react'
import PropTypes from 'prop-types';
import { Get, Post } from "../Classes/ApiHelper";
import '../Css/custom.css';
import _ from 'lodash';
import $ from 'jquery';

const Date = [
    { key: 1, text: '1', value: 1 },
    { key: 2, text: '2', value: 2 },
    { key: 3, text: '3', value: 3 },
    { key: 4, text: '4', value: 4 }, { key: 5, text: '5', value: 5 }, { key: 6, text: '6', value: 6 }, { key: 7, text: '7', value: 7 }, { key: 8, text: '8', value: 8 }, { key: 9, text: '9', value: 9 }, { key: 10, text: '10', value: 10 },
    { key: 11, text: '11', value: 11 }, { key: 12, text: '12', value: 12 }, { key: 13, text: '13', value: 13 }, { key: 14, text: '14', value: 14 },
    { key: 15, text: '15', value: 15 }, { key: 16, text: '16', value: 16 }, { key: 17, text: '17', value: 17 }, { key: 18, text: '18', value: 18 },
    { key: 19, text: '19', value: 19 }, { key: 20, text: '20', value: 20 }, { key: 21, text: '21', value: 21 }, { key: 22, text: '22', value: 22 },
    { key: 23, text: '23', value: 23 }, { key: 24, text: '24', value: 24 }, { key: 25, text: '25', value: 25 },
    { key: 26, text: '26', value: 26 },
    { key: 27, text: '27', value: 27 }, { key: 28, text: '28', value: 28 }, { key: 29, text: '29', value: 29 },
    { key: 30, text: '30', value: 30 }, { key: 31, text: '31', value: 31 },
]
const Month = [
    { key: 0, text: 'Select', value: 0 },
    { key: 1, text: 'Jan', value: 1 },
    { key: 2, text: 'Feb', value: 2 },
    { key: 3, text: 'March', value: 3 },
    { key: 4, text: 'April', value: 4 }, { key: 5, text: 'May', value: 5 }, { key: 6, text: 'June', value: 6 }, { key: 7, text: 'July', value: 7 },
    { key: 8, text: 'Aug', value: 8 }, { key: 9, text: 'Sep', value: 9 }, { key: 10, text: 'Oct', value: 10 },
    { key: 11, text: 'Nov', value: 11 }, { key: 12, text: 'Dec', value: 12 },
]
const Year = [
    { key: 1, text: '2001', value: 2001 },
    { key: 2, text: '2000', value: 2000 },
    { key: 3, text: '1999', value: 1999 },
    { key: 4, text: '1998', value: 1998 }, { key: 5, text: '1997', value: 1997 }, { key: 6, text: '1996', value: 1996 }, { key: 7, text: '1995', value: 1995 },
    { key: 8, text: '1994', value: 1994 }, { key: 9, text: '1993', value: 1993 }, { key: 10, text: '1992', value: 1992 },
    { key: 11, text: '1991', value: 1991 }, { key: 12, text: '1990', value: 1990 },
    { key: 13, text: '1989', value: 1989 },
    { key: 14, text: '1988', value: 1988 },
    { key: 15, text: '1987', value: 1987 },
    { key: 16, text: '1986', value: 1986 },
    { key: 17, text: '1985', value: 1985 },
    { key: 18, text: '1984', value: 1984 },
    { key: 19, text: '1983', value: 1983 },
    { key: 20, text: '1982', value: 1982 },
    { key: 21, text: '1981', value: 1981 },
    { key: 22, text: '1980', value: 1980 },
    { key: 22, text: '1979', value: 1979 }, { key: 22, text: '1969', value: 1969 },
    { key: 22, text: '1978', value: 1978 }, { key: 22, text: '1968', value: 1968 },
    { key: 22, text: '1977', value: 1977 }, { key: 22, text: '1967', value: 1967 },
    { key: 22, text: '1976', value: 1976 }, { key: 22, text: '1966', value: 1966 },
    { key: 22, text: '1975', value: 1975 }, { key: 22, text: '1965', value: 1965 },
    { key: 22, text: '1974', value: 1974 }, { key: 22, text: '1964', value: 1964 },
    { key: 22, text: '1973', value: 1973 }, { key: 22, text: '1963', value: 1963 },
    { key: 22, text: '1972', value: 1972 }, { key: 22, text: '1962', value: 1962 },
    { key: 22, text: '1971', value: 1971 }, { key: 22, text: '1961', value: 1961 },
    { key: 22, text: '1970', value: 1970 }, { key: 22, text: '1960', value: 1960 },
]
const Nationality = [
    { key: 1, text: 'Indian', value: 1 },
    { key: 2, text: 'Other', value: 2 },

]
const Disability = [
    { key: 0, text: 'Select', value: 0 },
    { key: 1, text: 'Hard of hearing', value: 1 },
    { key: 2, text: 'Locomotor Disability', value: 2 },
    { key: 3, text: 'Multiple Disabilities', value: 3 },


]

const Religion = [
    { key: 1, text: 'Buddhist', value: 1 },
    { key: 2, text: 'Christian', value: 2 },
    { key: 8, text: 'Muslim', value: 8 },
    { key: 3, text: 'Hindu', value: 3 },
    { key: 4, text: 'Jain', value: 4 },
    { key: 5, text: 'Sikh', value: 5 },
    { key: 6, text: 'Zoroastrian', value: 6 },
    { key: 7, text: 'Others', value: 7 },

]

class Personalinfo extends Component {

    state = {
        loading: true,
        status: -1,
        message: "",
        data: [],
        CityData: {},
        StateData: {},
        FormData: {
            lPaperId: 0,
            lCategoryId: 0,
            bDisablility: null,
            lDisabilityType: '',
            bJnkDomicile: null,
            bExserviceman: null,
            lExserviceId: 0,
            sNationality: 0,
            dtDOB: '',
            abcd:0,
            date1:0,
            date3:0,
            iGenderId: null,
            iMaritalStatusId: 0,
            sFatherName: '',
            sMotherName: '',
            sSpouseName: '',
            sAddress: '',
            sAddress2: '',
            sAddress3: '',
            sDistrict: '',
            iState: 0,
            scity:0,
            iPincode: '',
            sCorrespondenceAddress: '',
            sCorrespondenceAddress2: '',
            sCorrespondenceAddress3: '',
            sCorrespondenceDistrict: '',
            iCorrespondenceState: 0,
            iCorrespondencePincode: '',
            Date: 0,
            Month: 0,
            Year: '0',
            lExamCenter1Id: 0,
            lExamCenter2Id: 0,
            lExamCenter3Id: 0,
            sAadharNumber: '',
            lServicePeriod: 0,
            lReligionId: 0,
            bReligionCmnty: 0,
            checked: true
        },
        errors: {}
    }

    CategoryChange = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, lCategoryId: value }
        })
    }

    FormRadioChange = (e, { value, name }) => {
        this.setState({
            FormData: { ...this.state.FormData, name: value }
        }, console.log(name + "=" + value))
    }
    
    Disabilityperson = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, bDisablility: value }
        })
    }
    ExamCenter2Id = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, lExamCenter2Id: value }
        })
    }

    Gendername = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, iGenderId: value }
        })
    }

    MaritalStatus = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, iMaritalStatusId: value }
        })
    }

    FormChange = e =>
        this.setState({
            FormData: { ...this.state.FormData, [e.target.name] : e.target.value }
        }, console.log(this.state.FormData))

    Religiousminority = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, bReligionCmnty: value }
        })
    }

    Disabilitytype = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, lDisabilityType: value }
        })
    }
    jandkdomicile = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, bJnkDomicile: value }
        })
    }
    exserviceman = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, bExserviceman: value }
        })
    } 
    dob = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, dtDOB: value }
        }, console.log(value))
    }

    state1 = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, iState: value }
        }, console.log(value))
    }
    state2 = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData,iCorrespondenceState: value }
        }, console.log(value))
    }

    NationalityData = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, sNationality: value }
        })
    }

    adharvalidation = (text) => {
        const reg = /^[0]?[91911]\d{11}$/;
        if (reg.test(text) === false) {
            return false;
        } else {
            return true;
        }
    }

    validate = (data) => {
        const errors = {};
        console.log('data',data);
        // if (data.lPaperId === 0) {
        //     errors.lPaperId = "Must Select";
        // }
        if (data.lCategoryId === 0) {
            errors.lCategoryId = "Must Select";
        }
        if (data.bDisablility === null) {
            errors.bDisablility = "Must Select";
        }
        if (data.lDisabilityType == "" && data.bDisablility == 1) {
            errors.lDisabilityType = "Must Select";
        }
        if (data.sNationality == 0) {
            errors.sNationality = "Must Select";
        }
        if (data.bJnkDomicile === null) {
            errors.bJnkDomicile = "Must Select";
        }
        // if (data.bExserviceman === null) {
        //     errors.bExserviceman = "Must Select";
        // }

        if ((data.lServicePeriod == 0 || data.lServicePeriod == "") && data.lCategoryId ==6 ) {
            errors.lServicePeriod = "Can't be blank";
        }

        if (data.iGenderId === null) {
            errors.iGenderId = "Must Select";
        }
        // if (data.sSpouseName === 0) {
        //     errors.sSpouseName = "Must Select";
        // }
        if (data.iMaritalStatusId === 0) {
            errors.iMaritalStatusId = "Must Select";
        }
        if (data.sFatherName === '') {
            errors.sFatherName = "Can't be blank.";
        }
        if (data.sMotherName === '') {
            errors.sMotherName = "Can't be blank.";
        }
        // if (data.sSpouseName === '') {
        //     errors.sSpouseName = "Can't be blank.";
        // }
        if (data.sAddress === '') {
            errors.sAddress = "Can't be blank.";
        }
        // if (data.sAddress2 === '') {
        //     errors.sAddress2 = "Can't be blank.";
        // }
        // if (data.sAddress3 === '') {
        //     errors.sAddress3 = "Can't be blank.";
        // }
        if (data.sDistrict === '') {
            errors.sDistrict = "Can't be blank.";
        }
        if (data.iState === 0) {
            errors.iState = "Must Select";
        }
        if (data.Month === 0 ||data.Month=="Select") {
            errors.abcd = "Must Select";
        }
        if (data.Date === 0 ||data.Date=="Select") {
            errors.date1 = "Must Select";
        }
        if (data.Year === 0 ||data.Year=="Select") {
            errors.date3 = "Must Select";
        }
        
        if (data.lExamCenter1Id === 0 ||data.lExamCenter1Id=="Select") {
            errors.scity1 = "Must Select";
        }
        // if (data.lExamCenter2Id === 0 ||data.lExamCenter2Id=="Select") {
        //     errors.scity2 = "Must Select";
        // }
        // if (data.lExamCenter3Id === 0 ||data.lExamCenter3Id=="Select") {
        //     errors.scity3 = "Must Select";
        // }

        if (data.sDistrict === '') {
            errors.sDistrict = "Can't be blank.";
        }
        if (data.iPincode === '') {
            errors.iPincode = "Can't be blank.";
        }
        if ($('#cadr1').val() === '') {
            data.sCorrespondenceAddress=$('#cadr1').val();
            errors.sCorrespondenceAddress = "Can't be blank.";
        }
        // if (data.sCorrespondenceAddress2 === '') {
        //     errors.sCorrespondenceAddress2 = "Can't be blank.";
        // }
        // if (data.sCorrespondenceAddress3 === '') {
        //     errors.sCorrespondenceAddress3 = "Can't be blank.";
        // }

        if ($('#dstc').val() === '') {
            //data.sCorrespondenceDistrict=$('#dstc').val();
            errors.sCorrespondenceDistrict = "Can't be blank.";
        }
        if ($('#stt_c').val() === 0) {
            errors.iCorrespondenceState = "Can't be blank.";
        }
        if ($('#pin_c').val() === '') {
            errors.iCorrespondencePincode = "Can't be blank.";
        }
        if (data.dtDOB === '') {
            errors.dtDOB = "Must Select";
        }
       
        // if (data.bReligionCmnty === null ) {
        //     errors.bReligionCmnty = "Must Select";
        // }
        if (data.lReligionId === 0 || data.lReligionId === "0") {
            errors.lReligionId = "Can't be blank.";
        }
        console.log('data',data);
        console.log('error',errors);
        return errors;
    }


    SaveData = () => {
        const errors = this.validate(this.state.FormData);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            //alert("Done");
            this.setState({ loading: true })
            var PostData = this.state.FormData;
            PostData.lRegId = this.props.ApplicationNo;
            PostData.lId = 0;
            console.log(PostData);
            console.log(FormData);
            var ExamData = Post('CandidatePersonalDetail', PostData)
            
            ExamData.then(
                response => this.setState({
                    loading: false,
                    status: response.data.status,
                    message: response.data.message,
                    data: response.data.data
                    
                }, () => {
                    if (this.props.ApplicationNo !== '0') {
                        this.props.submit(4);
                    }
                })
            ).catch(error => {
                const errors = {};
                errors.FormError = error;
                this.setState({ errors, loading: false });
            });
        }else{ 
            alert('Please Fill All Required Information From The Page Before Procedding!')
            $("html, body").animate({ scrollTop: 0 }, "slow");
           }
        return false;
    }

     

    componentDidMount() {
        this.setState({ loading: true })
        this.setState({ ApplicationNo: this.props.ApplicationNo })
        var CanData = Get('CandidatePersonalDetail/' + this.props.ApplicationNo)
        CanData.then(
            response => this.setState({
              loading: false,
              message: response.data.message,
              FormData: response.data.data[0]
          })
        ).catch(error => this.setState({ error, loading: false }));
 
        let ApiUrl = 'CityList/0';
        var cityDataApi = Get(ApiUrl)
        cityDataApi.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                CityData: response.data.data
            })
        )

        ApiUrl = 'statelist/0';
        var StateDataApi = Get(ApiUrl)
        StateDataApi.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                StateData: response.data.data
            })
        )

        ApiUrl = 'ExamPaper/8';
        var examDataApi = Get(ApiUrl)
        examDataApi.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                examData: response.data.data
            }, console.log(this.state))
        )
      
        
        $('select[name=Date]').change(function(){ 
            getDOB();
          });  
        
          $('select[name=Month]').change(function(){ 
            getDOB();
            });  
        
        $('select[name=Year]').change(function(){ 
            getDOB();
        });  
        $('select[name=sNationality]').change(function(){ 
            if($(this).val()==2){
                alert('Only Indian nationals are eligible for this application');
                $(this).val(1);
            }
        });  

        function getDOB(){
          let sdate=$('select[name=Date]').val();
          let sMonth=$('select[name=Month]').val();
          let sYear=$('select[name=Year]').val();
          if(sdate!="0"&&sMonth!="0"&&sYear!="0"){
              var dob123=sdate+'-'+sMonth+'-'+sYear;
              //alert(dob);
              //$('.dob_cls').val(dob123);
              //$('.dob_cls').click();
              var now = new window.Date( dob123.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3") );
              //alert(now);
              FormData.dtDOB=dob123;
              getAge(dob123); 
              //$('.dob_cls').click();
          }
        }

        function getAge(dateString) {
            var mdate = dateString.split('-');
            var yearThen = parseInt(mdate[2]);
            var monthThen = parseInt(mdate[1]);
            var dayThen = parseInt(mdate[0]);
            var today = new window.Date("2019","07","01");
            var birthday = new window.Date(yearThen, monthThen-1, dayThen);
            
            var differenceInMilisecond = today.valueOf() - birthday.valueOf();
            
            var year_age = Math.floor(differenceInMilisecond / 31536000000);
            var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
          
            var month_age = Math.floor(day_age/30);

            day_age = day_age % 30;

            if (isNaN(year_age) || isNaN(month_age) || isNaN(day_age)) {
                alert("Invalid birthday - Please try again!");
            }
            else {
                $('.lbl_age').html( year_age + " years " + month_age + " months " + day_age + " days");
                var age_dob=dateString+"_"+ year_age + " years " + month_age + " months " + day_age + " days";
                $('.dob_cls').val(age_dob);
                $('.dob_cls').click();
                $('.dob_cls').click();
            }
        }

        // $(".dsblty").hide();
        // $("#dsblty").hide();
        // $('input[type=radio]').removeClass('hidden');
        // $('input[type=radio][name=bDisablility]').change(function(){ 
        //   if($(this).val()=='1'){
        //       $('.dsblty').show();
        //       $('#dsblty').show();
        //   } else{
        //       $('.dsblty').hide();
        //       $('#dsblty').hide();
        //       $('#dsblty').val('5');
        //   }
        // });

        $("#yes").click(function() {
            $("#dsblty").attr("disabled", false);
            //$("#discountselection").show(); //To Show the dropdown
        });
        $("#no").click(function() {
            $("#dsblty").attr("disabled", true);
            //$("#discountselection").hide();//To hide the dropdown
        });
        
        $('input[type=radio]').removeClass('hidden');
        $('input[type=checkbox]').removeClass('hidden');
        

        
        
        $("#yes1").click(function() {
            $("#Exserviceman").attr("disabled",false);
            //$("#discountselection").show(); //To Show the dropdown
        });
        $("#no1").click(function() {
            $("#Exserviceman").attr("disabled", true);
            //$("#discountselection").hide();//To hide the dropdown
        });
        

        $('input[type=radio][name=lCategoryId]').change(function(){ 
            if($(this).val()!='6'){
                $("#Exserviceman").attr("disabled",true);
            } else{
                $("#Exserviceman").attr("disabled",false);
            }
          }); 



        $('input[type=radio][name=iMaritalStatusId]').change(function(){ 
          if($(this).val()!='1'){
              $('.MaritalStatusId').show();
              $('#MaritalStatusId').show();
          } else{
              $('.MaritalStatusId').hide();
              $('#MaritalStatusId').hide();
              $('#MaritalStatusId').val('');
          }
        }); 
        $('#Exserviceman').change(function(){
if($(this).val()<=0){
    $(this).val(''); 
}
        });

        $('input[type=Checkbox][name=SameAdrs]').change(function(){ 
            if($(this).is(":checked")){
                $("#cadr1").attr("disabled",true);
                $("#adr2").attr("disabled",true);
                $("#adr3").attr("disabled",true);
                $("#dstc").attr("disabled",true);
                $("#stt_c").attr("disabled",true);
                $("#pin_c").attr("disabled",true);

            }else{
                $("#cadr1").attr("disabled",false);
                $("#adr2").attr("disabled",false);
                $("#adr3").attr("disabled",false);
                $("#dstc").attr("disabled",false);
                $("#stt_c").attr("disabled",false);
                $("#pin_c").attr("disabled",false);
            }
        }); 


        $('input[type=checkbox][name=SameAdrs]').removeClass('hidden');
        $('input[type=checkbox][name=SameAdrs]').change(function(){  
          if($(this).prop('checked') ==true){
             $('input[name=sCorrespondenceAddress]').val($('input[name=sAddress]').val());
             $('input[name=sCorrespondenceAddress2]').val($('input[name=sAddress2]').val());
             $('input[name=sCorrespondenceAddress3]').val($('input[name=sAddress3]').val());
             $('input[name=sCorrespondenceDistrict]').val($('input[name=sDistrict]').val());
             $('select[name=iCorrespondenceState]').val($('select[name=iState').val());
             $('input[name=iCorrespondencePincode]').val($('input[name=iPincode]').val());
          } else{
              $('input[name=sCorrespondenceAddress]').val('');
              $('input[name=sCorrespondenceAddress2]').val('');
              $('input[name=sCorrespondenceAddress3]').val('');
              $('input[name=sCorrespondenceDistrict]').val('');
              $('select[name=iCorrespondenceState]').val('');
              $('input[name=iCorrespondencePincode]').val('');
          }
          $('input[name=sCorrespondenceAddress]').click();
          $('input[name=sCorrespondenceDistrict]').click();
          $('input[name=iCorrespondenceState]').click();
          $('input[name=iCorrespondencePincode]').click();
          $('input[name=sCorrespondenceAddress]').click();
          $('input[name=sCorrespondenceDistrict]').click();
          $('input[name=iCorrespondenceState]').click();
          $('input[name=iCorrespondencePincode]').click();
          $('input[name=sCorrespondenceAddress]').click();
          
          $('input[name=sCorrespondenceDistrict]').click();
          
          $('input[name=iCorrespondenceState]').click();
          
          $('input[name=iCorrespondencePincode]').click();
        }); 
    }

    submit = (CurStep) => {
        this.props.submit(CurStep);
      }
      back = (CurStep) => {
        this.props.back(CurStep);
      }

    render() {
        const { examData, CityData, StateData, data, FormData, errors } = this.state;
        //$('#Year').val(FormData.Year).trigger('change');
       //$('#Date').val(FormData.Date).trigger('change');
        //$('select[name=Year]').val(FormData.Year).trigger('change');
        return (
            <div>
                <container >
                    <Form className="container" onSubmit={this.SaveData} >
                        <Grid >
                            <Grid.Column floated='left' width={16}>
                                <Segment inverted color='red' >
                                    <Header as='h5' >
                                        <Header.Content >Basic Details</Header.Content>
                                    </Header>

                                </Segment>
                            </Grid.Column>
                            {/* <Grid.Column floated='left' mobile={16} tablet={8} computer={10}>
                                <label>Are you an Ex-Serviceman : ?</label>
                                <span  class="colorblr"> * </span>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={8} computer={6}>
                                <Grid divided='vertically'>
                                    <Grid columns={4}>
                                        <Grid.Column>
                                            <Radio
                                                label='Yes'
                                                name='bExserviceman'
                                                value='1'
                                                checked={FormData.bExserviceman === '1'}
                                                onChange={this.exserviceman}
                                                id="yes1"
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='No'
                                                name='bExserviceman'
                                                value='0'
                                                checked={FormData.bExserviceman === '0'}
                                                onChange={this.exserviceman}
                                            id="no1"
                                            />
                                        </Grid.Column>
                                        {errors.bExserviceman && <Label basic color='red' pointing>{errors.bExserviceman}</Label>}
                                    </Grid>
                                </Grid>

                            </Grid.Column> */}

                            {/* <Grid.Column floated='left' mobile={6} tablet={8} computer={10}>
                                <label>Select Paper</label>
                                <span class="colorblr"> * </span>
                            </Grid.Column>
                            <Grid.Column floated='left' mobile={10} tablet={8} computer={3}>
                                <Form.Select fluid placeholder='Paper' control='select' name='lPaperId' onChange={this.FormChange}   >
                                    <option value={0} key={0} >Select Paper</option>
                                    {_.map(examData, StateSingleData => (
                                        <option value={StateSingleData.in_id} key={StateSingleData.in_id} >{StateSingleData.nvc_paper_name}</option>
                                    ))}
                                </Form.Select>
                                {errors.lPaperId && <Label basic color='red' pointing>{errors.lPaperId}</Label>}
                            </Grid.Column>
                            <Grid.Column floated='right' only='computer' computer={3}>
                            </Grid.Column> */}

                            <Grid.Column floated='left' mobile={16} tablet={4} computer={6}>
                                <label>Category</label> <span class="colorblr" > * </span>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={12} computer={10}>
                                <Grid divided='vertically'>
                                    <Grid columns={5}>
                                        <Grid.Column>
                                            <Radio
                                                label='General'
                                                name='lCategoryId'
                                                value='1'
                                                checked={this.state.FormData.lCategoryId == '1'}
                                                onChange={this.CategoryChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='OBC'
                                                name='lCategoryId'
                                                value='5'
                                                checked={this.state.FormData.lCategoryId == '5'}
                                                onChange={this.CategoryChange}

                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='SC'
                                                name='lCategoryId'
                                                value='3'
                                                checked={this.state.FormData.lCategoryId == '3'}
                                                onChange={this.CategoryChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='ST'
                                                name='lCategoryId'
                                                value='4'
                                                checked={this.state.FormData.lCategoryId == '4'}
                                                onChange={this.CategoryChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='EWS'
                                                name='lCategoryId'
                                                value='8'
                                                checked={this.state.FormData.lCategoryId == '8'}
                                                onChange={this.CategoryChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='Ex-Serviceman'
                                                name='lCategoryId'
                                                value='6'
                                                checked={this.state.FormData.lCategoryId == '6'}
                                                onChange={this.CategoryChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='PH'
                                                name='lCategoryId'
                                                value='7'
                                                checked={this.state.FormData.lCategoryId == '7'}
                                                onChange={this.CategoryChange}
                                            />
                                        </Grid.Column>
                                        {errors.lCategoryId && <Label basic color='red' pointing>{errors.lCategoryId}</Label>}
                                    </Grid>
                                </Grid>
                            </Grid.Column>
                        
                            <Grid.Column floated='left' mobile={16} tablet={8} computer={10}>
                                <label class='Exserviceman'>Service Period of Ex-Serviceman (in months) :</label>

                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                                <input type="number" id='Exserviceman' size='large' name='lServicePeriod' onChange={this.FormChange} value={FormData.lServicePeriod} />
                                {errors.lServicePeriod && <Label basic color='red' pointing>{errors.lServicePeriod}</Label>}

                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                            </Grid.Column>
                        </Grid>
                        
                        <Grid>
                            <Grid.Column floated='left' width={16}>
                                <label className="colorblr fnt-size_16">No change in category will be permitted after final submission of your application.</label>
                            </Grid.Column>
                        </Grid>
                        <Grid>
                            <Grid.Column floated='left' mobile={16} tablet={8} computer={10}>
                                <label>Are you a Person with Benchmark Disability of 40% and above ? </label>
                              <span  class="colorblr"> * </span>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={8} computer={6}>
                                <Grid divided='vertically'>
                                    <Grid columns={4}>
                                        <Grid.Column>
                                            <Radio
                                                label='Yes'
                                                name='bDisablility'
                                                value='True'
                                                checked={FormData.bDisablility == 'True'}
                                                onChange={this.Disabilityperson}
                                               id="yes"
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='No'
                                                name='bDisablility' 
                                                value='False'
                                                id="no"
                                                checked={FormData.bDisablility == 'False'}
                                                onChange={this.Disabilityperson}
                                            />
                                        </Grid.Column>
                                        {errors.bDisablility && <Label basic color='red' pointing>{errors.bDisablility}</Label>}
                                    </Grid>
                                </Grid>
                            </Grid.Column>

                            <Grid.Column floated='left' mobile={16} tablet={8} computer={10} >
                                <label  class='dsblty'>Type of Disability </label>
                                <span  class="colorblr dsblty"> * </span>

                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                            <input type="text" name='lDisabilityType' onChange={this.FormChange} value={FormData.lDisabilityType} id='dsblty'/>
                                {/* <Dropdown placeholder="Select" options={Disability} selection name='lDisabilityType' onChange={this.Disabilitytype} id='dsblty'/>*/}
                                {errors.lDisabilityType && <Label basic color='red' pointing>{errors.lDisabilityType}</Label>} 
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                            </Grid.Column>
                              
                            <Grid.Column floated='left' mobile={16} tablet={8} computer={10}>
                                <label>Do you belong to J&K domicile  </label>
                                <span  class="colorblr"> * </span>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={8} computer={6}>
                                <Grid divided='vertically'>
                                    <Grid columns={4}>
                                        <Grid.Column>
                                            <Radio
                                                label='Yes'
                                                name=' bJnkDomicile'
                                                value='True'
                                                checked={FormData.bJnkDomicile == 'True'}
                                                onChange={this.jandkdomicile}
                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='No'
                                                name=' bJnkDomicile'
                                                value='False'
                                                checked={FormData.bJnkDomicile == 'False'}
                                                onChange={this.jandkdomicile}
                                            />
                                        </Grid.Column>
                                        {errors.bJnkDomicile && <Label basic color='red' pointing>{errors.bJnkDomicile}</Label>}
                                    </Grid>
                                </Grid>

                            </Grid.Column>

                            {/* <Grid.Column floated='left' mobile={16} tablet={8} computer={10}>
                                <label>Adhar card :</label>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                                <input type="text" name='sAadharNumber' onChange={this.FormChange} value={FormData.sAadharNumber} />
                                {errors.sAadharNumber && <Label basic color='red' pointing>{errors.sAadharNumber}</Label>}
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                            </Grid.Column> */}
                            
                            <Grid.Column floated='left' mobile={16} tablet={8} computer={10}> 
                                <label>Nationality:</label>
                                <span class="colorblr"> * </span>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                                <Form.Select fluid placeholder='Select' name='sNationality' value={FormData.sNationality} onChange={this.FormChange} control='select'>
                                <option value='0'>Select</option>
                                    {_.map(Nationality, StateSingleData => (
                                        <option value={StateSingleData.value} key={StateSingleData.key} >{StateSingleData.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.sNationality && <Label basic color='red' pointing>{errors.sNationality}</Label>}
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                            </Grid.Column>

                            <Grid.Column floated='left' mobile={16} tablet={8} computer={10}>
                                <label>Religion to which you belong  :</label>
                                <span class="colorblr"> * </span>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}> 
                                    <Form.Select fluid placeholder='Select' name='lReligionId' value={FormData.lReligionId} onChange={this.FormChange} control='select'>
                                <option value='0'>Select</option>
                                    {_.map(Religion, StateSingleData => (
                                        <option value={StateSingleData.value} key={StateSingleData.key} >{StateSingleData.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.lReligionId && <Label basic color='red' pointing>{errors.lReligionId}</Label>}
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                            </Grid.Column>
                            {/* <Grid.Column floated='left' mobile={16} tablet={8} computer={10}>
                                <label>Do you belong to Religious Minority Community ? : </label>
                                <span class="colorblr" > * </span>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={8} computer={6}>
                                <Grid divided='vertically'>
                                    <Grid columns={4}>
                                        <Grid.Column>
                                            <Radio
                                                label='Yes'
                                                name='bReligionCmnty'
                                                value='1'
                                                checked={FormData.bReligionCmnty === '1'}
                                                onChange={this.Religiousminority}

                                            />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio
                                                label='No'
                                                name='bReligionCmnty'
                                                value='0'
                                                checked={FormData.bReligionCmnty === '0'}
                                                onChange={this.Religiousminority}
                                            />
                                        </Grid.Column>
                                        {errors.bReligionCmnty && <Label basic color='red' pointing>{errors.bReligionCmnty}</Label>}
                                    </Grid>
                                </Grid>
                            </Grid.Column>*/}
                            <Grid.Column floated='left' mobile={16} tablet={8} computer={7}>
                                <label>Select Region of Posting (it will be city of examination as well) : </label>
                                <span class="colorblr" > * </span>
                            </Grid.Column>
                            <Grid.Column floated='left' mobile={16} tablet={4} computer={3}> 
                                <Form.Select fluid placeholder='State' name='lExamCenter1Id' value={FormData.lExamCenter1Id} onChange={this.FormChange} control='select'  >
                                         <option>Select</option>
                                    {_.map(CityData, StateSingleData => (
                                        <option placeholder="Select"  value={StateSingleData.in_id} key={StateSingleData.in_id} >{StateSingleData.nvc_city}</option>
                                    ))}
                                 

                                </Form.Select>
                                {errors.scity1 && <Label basic color='red' pointing>{errors.scity1}</Label>}
                            </Grid.Column>
                            {/* <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='lExamCenter2Id' onChange={this.FormChange} control='select' label='Choice:2'>
                                <option>Select</option>  
                                    {_.map(CityData, StateSingleData => (
                                        <option value={StateSingleData.in_id} key={StateSingleData.in_id} >{StateSingleData.nvc_city}</option>
                                    ))}
                                </Form.Select>
                                {errors.scity2 && <Label basic color='red' pointing>{errors.scity2}</Label>}
                            
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='lExamCenter3Id' onChange={this.FormChange} control='select' label='Choice:3'>
                                <option>Select</option>
                                    {_.map(CityData, StateSingleData => (
                                        <option value={StateSingleData.in_id} key={StateSingleData.in_id} >{StateSingleData.nvc_city}</option>
                                    ))}
                                </Form.Select>
                                {errors.scity3 && <Label basic color='red' pointing>{errors.scity3}</Label>}
                            
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                            </Grid.Column> */}
                        </Grid>

                      
                        <div className="container padngbtm">
                            <Grid>
                                <Grid.Column floated='left' mobile={16} tablet={8} computer={16}>
                                    <Segment inverted color='red' >
                                        <Header as='h5' >
                                            <Header.Content >Personal Details</Header.Content>
                                        </Header>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column floated='left' mobile={16} tablet={8} computer={4}>
                                    <label>Date of Birth</label> <span class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State'  value={FormData.Date} name='Date' id='Date' onChange={this.FormChange}   control='select' label='Date' >
                                        <option value={0}>Select</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.date1 && <Label basic color='red' pointing>{errors.date1}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' value={FormData.Month}  name='Month' id='dobMonth'  onChange={this.FormChange}   control='select' label='Month' >
                                        {/* <option value={0} >Select</option> */}
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.abcd && <Label basic color='red' pointing>{errors.abcd}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Select' name='Year' id='Year'  onChange={this.FormChange}  control='select' label='Year' >
                                        <option value='0'>Select</option>
                                    {_.map(Year, yr => (
                                        <option value={yr.value} key={yr.key} >{yr.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.date3 && <Label basic color='red' pointing>{errors.date3}</Label>}
                                </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                                <input type='hidden'name='dtDOB'  class='dob_cls' 
                                                onClick={this.FormChange} value={FormData.dtDOB} />
                            </Grid.Column>
                            </Grid>
                            <Grid>
                            <GridColumn >
                                <p class="colorblr fnt-size_16">NOTE: Please ensure you have already filled in the details under Basic Details in the online Form especially pertaining to your category. Change in Date of Birth will not be permitted</p>
                            </GridColumn>
                        </Grid>
                        <Grid>
                        <Grid.Column floated='left' mobile={16} tablet={8} computer={10}>
                                <label>Age Completed as on 01.08.2019</label>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                               <labe class='lbl_age'>{FormData.vc_age}</labe>
                               
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={16} tablet={4} computer={3}>
                            </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column textAlign='left' mobile={10} tablet={8} computer={4}>
                                    <label>Gender </label>
                                    <span class="colorblr" > * </span>
                                </Grid.Column>
                                <Grid.Column textAlign='right' mobile={6} tablet={8} computer={5}>
                                    <Grid divided='Horizontal'>
                                        <Radio
                                            label='Male'
                                            name='radioGroup'
                                            value='1'
                                            checked={this.state.FormData.iGenderId == '1'}
                                            onChange={this.Gendername}
                                        />
                                        <Radio
                                            label='Female'
                                            name='radioGroup'
                                            value='3'
                                            checked={this.state.FormData.iGenderId == '3'}
                                            onChange={this.Gendername}
                                        />
                                        <Radio
                                            label='Other'
                                            name='radioGroup'
                                            value='4'
                                            checked={this.state.FormData.iGenderId == '4'}
                                            onChange={this.Gendername}
                                        />
                                    {errors.iGenderId && <Label basic color='red' pointing>{errors.iGenderId}</Label>}
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column floated='right' only='mobile' mobile={0} tablet={8} computer={7}>
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column floated='left' mobile={16} tablet={8} computer={4}>
                                    <label>Marital Status : </label>
                                    <span class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={8} computer={12}>
                                    <Grid divided='Horizontal'>
                                        <Radio
                                            label='Unmarried '
                                            name='iMaritalStatusId'
                                            value='1'
                                            checked={FormData.iMaritalStatusId == '1'}
                                            onChange={this.MaritalStatus}

                                        />
                                        <Radio
                                            label='Married  '
                                            name='iMaritalStatusId'
                                            value='2'
                                            checked={FormData.iMaritalStatusId == '2'}
                                            onChange={this.MaritalStatus}

                                        />
                                        <Radio
                                            label='Widow '
                                            name='iMaritalStatusId'
                                            value='3'
                                            checked={FormData.iMaritalStatusId == '3'}
                                            onChange={this.MaritalStatus}

                                        />
                                        <Radio
                                            label='Widower'
                                            name='iMaritalStatusId'
                                            value='4'
                                            checked={FormData.iMaritalStatusId == '4'}
                                            onChange={this.MaritalStatus}

                                        />
                                        <Radio
                                            label='Divorced'
                                            name='iMaritalStatusId'
                                            value='5'
                                            checked={FormData.iMaritalStatusId == '5'}
                                            onChange={this.MaritalStatus}

                                        />
                                        <Radio
                                            label='Judicially Separated '
                                            name='iMaritalStatusId'
                                            value='6'
                                            checked={FormData.iMaritalStatusId == '6'}
                                            onChange={this.MaritalStatus}

                                        />
                                    {errors.iMaritalStatusId && <Label basic color='red' pointing>{errors.iMaritalStatusId}</Label>}
                                    </Grid>
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column floated='left' mobile={7} tablet={8} computer={4}>
                                    <label>Father's Name :</label>
                                    <span class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" name='sFatherName' onChange={this.FormChange} value={FormData.sFatherName} />
                                    {errors.sFatherName && <Label basic color='red' pointing>{errors.sFatherName}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column floated='left' mobile={7} tablet={8} computer={4}>
                                    <label>Mother's Name : </label>
                                    <span class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" name='sMotherName' onChange={this.FormChange} value={FormData.sMotherName} />
                                    {errors.sMotherName && <Label basic color='red' pointing>{errors.sMotherName}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column floated='left' mobile={7} tablet={8} computer={4}>
                                    <label class='MaritalStatusId'>Spouse's Name:</label>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" id='MaritalStatusId' name='sSpouseName' onChange={this.FormChange} value={FormData.sSpouseName} />
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Column floated='left' mobile={7} tablet={8} computer={4}>
                                    <label>Address for Correspondence:</label>
                                    <span class="colorblr" > * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" name='sAddress' value={FormData.sAddress} onChange={this.FormChange} value={FormData.sAddress} />
                                    {errors.sAddress && <Label basic color='red' pointing>{errors.sAddress}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column floated='left' mobile={7} tablet={8} computer={4}>
                                    <label>Address 2</label>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" name='sAddress2' value={FormData.sAddress2} onChange={this.FormChange} value={FormData.sAddress2} />
                                    {errors.sAddress2 && <Label basic color='red' pointing>{errors.sAddress2}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column floated='left' mobile={7} tablet={8} computer={4}>
                                    <label>Address 3</label>

                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" name='sAddress3' value={FormData.sAddress3} onChange={this.FormChange} value={FormData.sAddress3} />
                                    {errors.sAddress3 && <Label basic color='red' pointing>{errors.sAddress3}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column floated='left' mobile={7} tablet={8} computer={4}>
                                    <label>District </label>
                                    <span class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" name='sDistrict' onChange={this.FormChange} value={FormData.sDistrict} />
                                    {errors.sDistrict && <Label basic color='red' pointing>{errors.sDistrict}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column floated='left' mobile={6} tablet={8} computer={4}>
                                    <label>State </label>
                                    <span class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='left' mobile={10} tablet={8} computer={3}>
                                    <Form.Select fluid name='iState' placeholder='State' value={FormData.iState} control='select' onChange={this.FormChange}  >
                                    <option>Select</option>
                                        {_.map(StateData, StateSingleData => (
                                            <option value={StateSingleData.in_id} key={StateSingleData.in_id} >{StateSingleData.nvc_state}</option>
                                        ))}
                                    </Form.Select>
                                    {errors.iState && <Label basic color='red' pointing>{errors.iState}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' only='computer' computer={9}>
                                </Grid.Column>
                                <Grid.Column floated='left' mobile={7} tablet={8} computer={4}>
                                    <label>Pincode</label>
                                    <span class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" name='iPincode' value={FormData.iPincode} onChange={this.FormChange} />
                                    {errors.iPincode && <Label basic color='red' pointing>{errors.iPincode}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' only='computer' computer={9}>
                                </Grid.Column>
                                <Grid.Column floated='left' mobile={16} tablet={16} computer={16}>
                                    <Checkbox name='SameAdrs' toggle  
                            //                     checked={this.state.checked}
                            // onPress={() => this.setState({checked: !this.state.checked})}
                                        label="Same as Address for Correspondence ( Click if applicable)"
                                    />
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={16} computer={16}>
                                    <label> Permanent Address </label>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={7} tablet={8} computer={4}>
                                    <label>  Address 1: </label>
                                    <span class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    {/* <input type="text" value={this.state.address1} /> */}
                                    <input type="text" id="cadr1"  name='sCorrespondenceAddress' value={FormData.sCorrespondenceAddress} onClick={this.FormChange} onChange={this.FormChange}  />
                                    {errors.sCorrespondenceAddress && <Label basic color='red' pointing>{errors.sCorrespondenceAddress}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column textAlign='right' only="mobile" mobile={16} >
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={7} tablet={8} computer={4}>
                                    <label>  Address 2: </label>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    {/* <input type="text" value={this.state.address2} /> */}
                                    <input type="text" id="adr2" value={FormData.sCorrespondenceAddress2} name='sCorrespondenceAddress2' onChange={this.FormChange}  />
                                    {errors.sCorrespondenceAddress2 && <Label basic color='red' pointing>{errors.sCorrespondenceAddress2}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column textAlign='right' only="mobile" mobile={16} >
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={7} tablet={8} computer={4}>
                                    <label>  Address 3: </label>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" id="adr3" value={FormData.sCorrespondenceAddress3}  name='sCorrespondenceAddress3' onChange={this.FormChange}  />
                                    {errors.sCorrespondenceAddress3 && <Label basic color='red' pointing>{errors.sCorrespondenceAddress3}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column textAlign='right' only="mobile" mobile={16} >
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={7} tablet={8} computer={4}>
                                    <label>  District: </label>
                                    <span  class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" id="dstc"  value={FormData.sCorrespondenceDistrict} name='sCorrespondenceDistrict' onClick={this.FormChange} onChange={this.FormChange} />
                                    {errors.sCorrespondenceDistrict && <Label basic color='red' pointing>{errors.sCorrespondenceDistrict}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column textAlign='right' only="mobile" mobile={16} >
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={7} tablet={8} computer={4}>
                                    <label>  State: </label>
                                    <span  class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <Form.Select fluid name='iCorrespondenceState' value={FormData.iCorrespondenceState}  id="stt_c" placeholder='State' control='select' onClick={this.FormChange} onchange={this.FormChange}   >
                                    <option>Select</option>
                                        {_.map(StateData, StateSingleData => (
                                            <option 
                                           
                                            placeholder='Skills' value={StateSingleData.in_id} key={StateSingleData.in_id} >{StateSingleData.nvc_state}</option>
                                        ))  }
                                    </Form.Select>
                                    {errors.iCorrespondenceState && <Label basic color='red' pointing>{errors.iCorrespondenceState}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                    <p>( Maximum 35 Characters)</p>
                                </Grid.Column>
                                <Grid.Column textAlign='right' only="mobile" mobile={16} >
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={7} tablet={8} computer={4}>
                                    <label>  Pincode: </label>
                                    <span  class="colorblr"> * </span>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={9} tablet={8} computer={3}>
                                    <input type="text" id="pin_c" value={FormData.iCorrespondencePincode} name='iCorrespondencePincode' onClick={this.FormChange} onChange={this.FormChange}  />
                                    {errors.iCorrespondencePincode && <Label basic color='red' pointing>{errors.iCorrespondencePincode}</Label>}
                                </Grid.Column>
                                <Grid.Column textAlign='left' only="computer" computer={9}>
                                </Grid.Column>
                                <Grid.Column textAlign='right' only="mobile" mobile={16} >
                                </Grid.Column>
                                <Grid.Column floated='left' width={3}>
                                </Grid.Column>
                                <Grid.Column floated='left' width={9}>
                                </Grid.Column>
                              
                                <Grid.Column floated='right' only="computer,tablet" tablet={8} computer={13}>
                                    
                                    <Button primary labelPosition='left' primary icon='left chevron' content='Back' onClick={(e) => this.submit(2)} />
                                </Grid.Column> 
                                <Grid.Column floated='left' only="computer,tablet" tablet={8} computer={3} className="mg_20">
                                    <Button content='SAVE & Next' primary icon='right arrow' type='submit' labelPosition='right' />
                                </Grid.Column>
                                <Grid.Column floated='left' only="mobile" mobile={16}>
                                    <Button.Group>
                                        {/* <Button labelPosition='left' primary icon='left chevron' content='Back' /> */}
                                        <Button.Or />
                                        <Button primary>Validate details</Button>
                                        <Button.Or />
                                        <Button content='SAVE & Next' primary icon='right arrow' labelPosition='right' />
                                    </Button.Group>
                                </Grid.Column>
                            </Grid>
                        </div>
                    </Form>
                </container>
            </div>
        );
    }
}
Personalinfo.propTypes = {
    submit: PropTypes.func.isRequired
  }

export default Personalinfo;