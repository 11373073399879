import React, { Component } from 'react';
import { Get, Post } from '../Classes/ApiHelper';
import Footer from '../Components/Footer';
import MainHeader from '../Components/MainHeader';
import MainMenu from '../Components/MainMenu';
import StepProcess from '../Components/StepProcess';
import StudentForm from '../Components/StudentForm';
import Uploading from '../Components/Uploading';
import Personalinfo from '../Forms/Personalinfo';
import Preview from '../Forms/Preview';
import Qualification from '../Forms/Qualification';
import Payment from '../Components/Payment';
import PaymentCnfrmtn from '../Components/PaymentCnfrmtn';


class StudentRegistrationPage extends Component {

    state = {
        loading: true,
        status: -1,
        message: "",
        data: {},
        error: null,
        ApplicationNo : 0
    }

    componentDidMount() {
        this.setState({ loading: true })
        let UserData = JSON.parse(localStorage.getItem('user'));
        this.setState({ApplicationNo : UserData.ApplicationNo})
        var CandidateData = Get('CandidateBasicInfo/' + UserData.ApplicationNo)
        CandidateData.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            }, () => {
                this.setState({ 
                    data: { ...this.state.data, iCurStep: this.state.data.iStep }
                 })
            }, console.log(this.state))
        ).catch(error => this.setState({ error, loading: false }));
    }

    submit = (StepNo) => {
        this.setState({ loading: true })
        let UserData = JSON.parse(localStorage.getItem('user'));
        this.setState({ApplicationNo : UserData.ApplicationNo})
        var PostData  =  {
            lId: UserData.ApplicationNo,
            iStep: StepNo
        }
        var StepData = Post('CandidateStep', PostData)
        StepData.then(
            response => this.setState({
                loading: false,
            })
        ).catch(error => this.setState({ error, loading: false }));

        var CandidateData = Get('CandidateBasicInfo/' + UserData.ApplicationNo)
        CandidateData.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            }, () => {
                this.setState({ 
                    data: { ...this.state.data, iCurStep: StepNo }
                 })
            })
        ).catch(error => this.setState({ error, loading: false }));
    }

    render() {
        const { data, ApplicationNo } = this.state
        return (
            <div>
                <MainHeader />
                <MainMenu />
                <StepProcess StepNo={data.iStep} CurStepNo={data.iCurStep} />
                { data.iCurStep === 1 && <StudentForm  ApplicationNo={ApplicationNo} submit={this.submit} /> }
                { data.iCurStep === 2 && <Uploading ApplicationNo={ApplicationNo} submit={this.submit} /> }
                { data.iCurStep === 3 && <Personalinfo ApplicationNo={ApplicationNo} submit={this.submit} /> }
                { data.iCurStep === 4 && <Qualification ApplicationNo={ApplicationNo} submit={this.submit} /> }
                { data.iCurStep === 5 && <Preview ApplicationNo={ApplicationNo} submit={this.submit} /> }
                { data.iCurStep === 6 && <Payment ApplicationNo={ApplicationNo} submit={this.submit} /> }
                { data.iCurStep === 7 && <PaymentCnfrmtn ApplicationNo={ApplicationNo} submit={this.submit} /> }
                <Footer />
            </div>
        );
    }
}

export default StudentRegistrationPage;