import React, { Component } from 'react';
import { Form, Segment, Header, Grid, Container, Button } from 'semantic-ui-react';
import { Get, Post,ServerUrl } from "../Classes/ApiHelper";
import '../Css/custom.css';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'jquery';

class Payment extends Component {
  state = {
    data: {},
    loading: false,
    errors: {},
    status: -1,
    message: "",
    FormData: {
      in_candidate_id: 0,
      vc_payment_id: '', vc_amount: '',
    }
}
      constructor() {
        super()
        this.changeAmount = this.changeAmount.bind(this);
        this.openCheckout = this.openCheckout.bind(this);
      }
    
      changeAmount(e) {
        this.setState({amount: e.target.value})
      }
      componentDidMount() { 
          this.setState({ ApplicationNo: this.props.ApplicationNo })
          this.setState({ loading: true })
          var CanData = Get('PaymentApi/' + this.props.ApplicationNo)
          CanData.then(
              response => this.setState({
                loading: false,
                message: response.data.message,
                data: response.data.data[0]
            })
          ).catch(error => this.setState({ error, loading: false }));
  
        } 
         SaveData = (razorpay_payment_id) => {
          const errors = -1; 
          //alert(razorpay_payment_id);
          if(this.state.data.amount==0){
            razorpay_payment_id='PH_Candidate';
          }
          if (Object.keys(errors).length === 0) {
              this.setState({ loading: true }) 
              var PostData = this.state.FormData;
              PostData.in_candidate_id = this.props.ApplicationNo;
              PostData.vc_payment_id = razorpay_payment_id;
              PostData.vc_amount = this.state.data.amount;
              var ExamData = Post('PaymentApi', PostData)
              ExamData.then(
                  response => this.setState({
                      loading: false,
                      status: response.data.status,
                      message: response.data.message,
                      data: response.data.data
                  }, () => {
                      if (this.props.ApplicationNo !== '0') {
                          this.props.submit(7);
                      }
                  })
              ).catch(error => {
                  const errors = {};
                  errors.FormError = error;
                  this.setState({ errors, loading: false });
              });
          } 
          return false;
      }
      openCheckout() { //razorpay_payment_id
        let options = {
          "key": "rzp_live_CvCCJCBz19Grdu",//"rzp_live_CvCCJCBz19Grdu" rzp_test_mTaIuG3ULtDKuv,
          "amount": this.state.data.amount, // 2000 paise = INR 20, amount in paisa response.razorpay_payment_id
          "name": " ",
          "description": "Purchase Description",
          "image": ServerUrl+"/ClientImg/AirIndia.png",
          "handler":(response) => {
            this.SaveData(response.razorpay_payment_id);
        },
          "prefill": {
            "name": "Demo",
            "email": " "
          },
          "notes": {
            "address": "Hello World"
          },
          "theme": {
            "color": "#F37254"
          }
        };
    
        let rzp = new window.Razorpay(options);
        rzp.open(); 
      }
  
    render() {
      const { data ,errors} = this.state
        return (
            <div>

                <Container className="padingbtmnew">
                    <Form className="container" >
                        <Grid >
                            <Grid.Column floated='left' width={16}>
                                <Segment inverted color='red' >
                                    <Header as='h5' >

                                        <Header.Content >Payment</Header.Content>
                                    </Header>

                                </Segment>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={7} tablet={8} computer={6}>
                                <label> Amount: </label>
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={9} tablet={8} computer={10}>
                            {data.amount/100}
                          </Grid.Column>

                            <Grid.Column floated='right' mobile={16} tablet={8} computer={10} className="padngbtm">
                                {/* <Button primary >Check your previous transaction status</Button> */}
                                {data.amount==0 ? (
        <Button  onClick={this.SaveData} primary >Save</Button>
      ) : (
        <Button onClick={this.openCheckout} primary>Pay Rs. {data.amount/100}</Button> 
      )}
         
                            </Grid.Column>
                        </Grid>
                    </Form>
                </Container>


            </div>
        );
    }
}
Payment.propTypes = {
    submit: PropTypes.func.isRequired
}

export default Payment;