import React, { Component } from 'react';
import { Divider, Grid, Segment, Container, Modal, List, Button, Image, Message, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { ServerUrl, PostFormData, Get } from "../Classes/ApiHelper";


let   formdata = new FormData();

class Uploading extends Component {

  state = {
      data: {},
      loading: false,
      errors: {},
      status: -1,
      message: ""
  } 

  submit = (CurStep) => { 
    if($('img[name=CanImg]').attr('src')==undefined && $('img[name=CanSign]').attr('src')==undefined){
      alert('Please Click on Upload Button!');
    }else{
      this.props.submit(CurStep);
    }
    //this.props.submit(CurStep);
  }
  back = (CurStep) => {
    this.props.back(CurStep);
  }

  CanImageChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    formdata.append("image", file);
  }

  CanSignatureChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    formdata.append("signature", file);
  }

  validate = (data) => {
    const errors = {};
    if ($('#img').val()=='') {
        errors.image = "Must Select.";
    }
    if ($('#sgntr').val()=='') {
      errors.signature = "Must Select.";
    }
    return errors;
  }

  componentDidMount() {
   // alert(this.props.ApplicationNo);
    this.setState({ ApplicationNo: this.props.ApplicationNo })
    if (this.props.ApplicationNo !== '0') {
     // alert(this.props.ApplicationNo);
        this.setState({ loading: true })
        var CanUploadData = Get('UploadImg/' + this.props.ApplicationNo);
        console.log('qwert',CanUploadData);
        CanUploadData.then(
          response => this.setState({ 
              status: response.data.status ,
              loading: false, 
              message: response.data.message, 
              data: response.data.data[0]
        })
      ).catch(error => this.setState({ error, loading: false }));
    }
    else {
    }
    $('#img').change(function(){ 
      var img_size=(this.files[0].size)/1024;
      if(img_size>=20 && img_size<=50){
        //_this.CanImageChange(this);
        let file = this.files[0]
        formdata.append("image", file);
      }else{
        alert('Please Select Image With Size Between 20kb-50kb!');
        $('#img').val('');
      } 
    }); 
    $('#sgntr').change(function(){ 
      var img_size=(this.files[0].size)/1024;
      if(img_size>=10 && img_size<=20){
        //_this.CanImageChange(this);
        let file = this.files[0]
        formdata.append("signature", file);
      }else{
        alert('Please Select Signature With Size Between 10kb-20kb!');
        $('#sgntr').val('');
      } 
    }); 
  }

  Upload = () => {
    //alert('wertyu');
    const errors = this.validate(this.state.FormData)
    console.log("Upload", formdata)
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })
     // alert('wertyu');
      formdata.append("in_candidate_id", this.props.ApplicationNo);
      var UploadData = PostFormData('UploadImg', formdata)
      UploadData.then(
        response => this.setState({
            loading: false,
            status: response.data.status,
            message: response.data.message,
            data: response.data.data
        })
    ).catch(error => {
        const errors = {};
        errors.FormError = error;
        this.setState({ errors, loading: false });
    });
    }
  }

  render() {
    const { errors, loading, status, message } = this.state
    return (
      <div>
        <Container>
          <Segment placeholder>
            <Grid columns={2} relaxed='very' stackable>
              <Grid.Column  >
                <p >Scanned Photograph of the candidates:</p>
                <input type="file" color='blue' floated='left'  id="img"/>
                {errors.image && <Label basic color='red' pointing='left'>{errors.image}</Label>}
                <br /><br /><br />
                { this.state.data.vc_scanned_photo &&
                  <Image name="CanImg" src={ServerUrl + this.state.data.vc_scanned_photo} width={200} height={230} />
                }
                <Modal trigger={<p><u>Guidelines for uploading the Photograph </u></p>}>
                  <Modal.Content >
                    <Modal.Description>
                      <List ordered>
                        <List.Item>
                          <p> Photograph file type/ size:</p>
                          <List.List>
                            <List.Item as='a'>.  Photograph must be a recent passport style colour picture. </List.Item>
                            <List.Item as='a'>.  Size of file should be between 20 kb–50 kb and Dimensions 200 x 230 pixels (preferred). </List.Item>
                            <List.Item as='a'>.  Make sure that the picture is in colour, taken against a light-coloured, preferably white, background.</List.Item>
                            <List.Item as='a'>.  Look straight at the camera with a relaxed face .</List.Item>
                          </List.List>
                        </List.Item>
                      </List>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
                <p><b>Photo should be clearly visible in the adjacent box</b></p>
              </Grid.Column>
              <Grid.Column verticalAlign='middle'>
                <p>Scanned Signature of the candidates:</p>
                <input type="file" color='blue' floated='left'  id='sgntr' />
                {errors.signature && <Label basic color='red' pointing='left'>{errors.signature}</Label>}
                <br /><br />
                { this.state.data.vc_scanned_sign &&
                  <Image name="CanSign" src={ServerUrl + this.state.data.vc_scanned_sign} width={200} height={100} />
                }
                <br /><br />
                <Modal trigger={<p><u>Guidelines for uploading the Signature </u></p>}>
                  <Modal.Content >
                    <Modal.Description>
                      <List ordered>
                        <List.Item>
                          <p> Signature file type/ size:</p>
                          <List.List>
                            <List.Item as='a'>. Signature must be a recent passport style colour picture. </List.Item>
                            <List.Item as='a'>. Size of file should be between 10 kb–20 kb and Dimensions 200 x 100 pixels (preferred). </List.Item>
                            <List.Item as='a'>. Make sure that the picture is in colour, taken against a light-coloured, preferably white, background.</List.Item>
                            {/* <List.Item as='a'>. Look straight at the camera with a relaxed face .</List.Item> */}
                          </List.List>
                        </List.Item>
                      </List>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
                <p><b>Photo should be clearly visible in the adjacent box</b></p>
              </Grid.Column>
            </Grid>

            <Divider vertical>And</Divider>
          </Segment>
          {status === 1 &&
          <Message
              success
              header={'Success'}
              content={{message}}
          />
          }

          {status === 0 &&
            <Message
                negative
                header='Something went Wrong.'
                content={message}
            />
          }
          <Grid>
            {/* <Grid.Column floated='right' only="computer,tablet" tablet={8} computer={10} >
                < Button labelPosition='left' primary icon='left chevron' content='Back' onClick={(e) => this.submit(1)} />
              </Grid.Column> */}
               <Grid.Column floated='left' only="computer,tablet" tablet={8} computer={3}>
               {/* <Button primary labelPosition='left' primary icon='left chevron' content='Back' onClick={(e) => this.submit(1)} /> */}
            </Grid.Column>
            <Grid.Column floated='left' only="computer,tablet" tablet={8} computer={3}>
              < Button content='Upload' primary onClick={this.Upload} />
            </Grid.Column>
            <Grid.Column floated='left' only="computer,tablet" tablet={8} computer={3}>
                < Button content='Save & Next' primary icon='right arrow' className='nxt_btn' labelPosition='right' onClick={(e) => this.submit(3)} />
            </Grid.Column>
            <Grid.Column floated='left' only="mobile" mobile={16}>
              <Button primary content='Upload'/>
              <Button.Group>
                {/* <Button primary labelPosition='left' primary icon='left chevron' content='Back' onClick={(e) => this.submit(1)} /> */}
                <Button.Or />
                <Button.Or />
                <Button primary labelPosition='right' primary icon='right chevron' className='nxt_btn' content='Save & Next' onClick={(e) => this.submit(3)} />
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
}


Uploading.propTypes = {
  submit: PropTypes.func.isRequired
}

export default Uploading;