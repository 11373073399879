import React, { Component } from 'react';
import Createregistration from '../Forms/Createregistration';
import MainHeader from '../Components/MainHeader';
import MainMenu from '../Components/MainMenu';
import Footer from '../Components/Footer';
import {Container } from 'semantic-ui-react';

class LoginAndRegistration extends Component {
    render() {
        return (
            <div>
                <MainHeader />
                <MainMenu />
                <Container >
                    <Createregistration />
                </Container>
                <Footer />
            </div>
        );
    }
}

export default LoginAndRegistration;