import React, { Component } from 'react';
import LoginForm from '../Forms/LoginForm'

class LoginPage extends Component {
    submit = (data) => {
        console.log(data);
    }
    render() {
        
        return (
            <div>
                <style>{`
                div {
                    margin-top: 2%;
                }
                `}</style>
                <LoginForm submit={this.submit} />
            </div>
        );
    }
}

export default LoginPage;