import React, { Component } from 'react';
import {  Container } from 'semantic-ui-react'
import '../Css/custom.css';


class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <Container fluid className="HeaderBg" textAlign='center'>
                    <p>© Parakh Online Pvt. Ltd. All right reserved.</p>
                </Container>
            </div>
        );
    }
}

export default Footer;