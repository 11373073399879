import React, { Component } from 'react';
import { Divider, Button, Container,Header,Loader,Dimmer } from 'semantic-ui-react'
import { Post } from "../Classes/ApiHelper";
import {Link} from 'react-router-dom';

class HomeContent extends Component {
    state = {
        loading: true,
        status: -1,
        message: "",
        data: [],
        error: null
    }
  
    componentDidMount() {
        this.setState({ loading: true })
        let ExamLoadData = JSON.parse(sessionStorage.getItem('ExamData'));
        var PostData =
        {
            "lExamId": ExamLoadData.ExamID,
            "iContentType": 1
        }
        var ExamData = Post('ExamPageContent', PostData)
        ExamData.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            })
        ).catch(error => this.setState({ error, loading: false }));
        console.log(this.state);
    }

    
    render() {
        const { data, loading } = this.state
        return (
            <div>
                <Dimmer active={loading}>
                    <Loader size='medium'>Loading</Loader>
                </Dimmer>
                <Container textAlign='justified'>
                    <Header>{data.sTitle}</Header>
                    <Divider />
                    <div dangerouslySetInnerHTML={{__html: data.sContent}}></div>
                </Container>
                <Divider />
                <Container textAlign="center">
                    <h2>Please read the General Instructions (given above) carefully before starting online application process</h2>
                    <Button.Group >
                        <Button size="medium" color='violet' as={ Link } to='/LoginAndRegistration' >New Application</Button>
                        <Button.Or />
                        <Button size="medium" as={ Link } to='/LoginAndRegistration' positive>Existing Application</Button>
                    </Button.Group>

                </Container>
            </div>
        );
    }
}

export default HomeContent;