import React, { Component } from 'react';
import MainHeader from '../Components/MainHeader';
import MainMenu from '../Components/MainMenu';
import Footer from '../Components/Footer';
import Personalinfo from '../Forms/Personalinfo';
import StepProcess from '../Components/StepProcess';


class PreviewPage extends Component {
    render() {
        return (
            <div>
                 <MainHeader />
                <MainMenu />
                <StepProcess />
                <Personalinfo />
                <Footer />
            </div>
        );
    }
}

export default PreviewPage;