import React, { Component } from 'react';
import { Container, Divider } from 'semantic-ui-react';
import {  Dimmer, Loader } from 'semantic-ui-react'
import { Post } from "../Classes/ApiHelper";

class ExaminationSchedule extends Component {

  state = {
    loading: true,
    status: -1,
    message: "",
    data: [],
    error: null
}

componentDidMount() {
    this.setState({ loading: true })
    let ExamLoadData = JSON.parse(sessionStorage.getItem('ExamData'));
    var PostData =
    {
        "lExamId": ExamLoadData.ExamID,
        "iContentType": 3
    }
    var ExamData = Post('ExamPageContent', PostData)
    ExamData.then(
        response => this.setState({
            loading: false,
            status: response.data.status,
            message: response.data.message,
            data: response.data.data
        })
    ).catch(error => this.setState({ error, loading: false }));
    console.log(this.state);
}
  render() {
    const { data, loading } = this.state
    return (
      <div>
        <Dimmer active={loading}>
            <Loader size='medium'>Loading</Loader>
        </Dimmer>
        <Container>
          <h3 className="fee" textAlign='center'>{data.sTitle}</h3>
          <Divider />
          <div dangerouslySetInnerHTML={{__html: data.sContent}}></div>
        </Container>
      </div>
    );
  }
}

export default ExaminationSchedule;