import React, { Component } from 'react';
import { Grid, Segment, Container } from 'semantic-ui-react'
import MainHeader from '../Components/MainHeader';
import MainMenu from '../Components/MainMenu';
import EventList from '../Components/EventList';
import Footer from '../Components/Footer';
import { ServerUrl,Get,Post } from "../Classes/ApiHelper";
import ExaminationSchedule from '../Components/ExaminationSchedule';




class ExaminationSchedulePage  extends Component {
    render() {
        return (
            <div>
                  <MainHeader />
                  <MainMenu />
                  <Container >
                    <Grid columns={3} stackable className="MainBody">
                        <Grid.Row stretched>
                            {/* <Grid.Column width={4}>
                                <Segment>
                                    <EventList ConType={1}  />
                                </Segment>
                            </Grid.Column> */}
                            <Grid.Column width={16}>
                                <Segment>
                                <embed src={ServerUrl+"/instruction.pdf"} width="1112" height="1000" alt="pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"/>
                                </Segment>
                            </Grid.Column>
                          
                        </Grid.Row>
                    </Grid>
                </Container>

                <Footer />
          
            </div>
        );
    }
}

export default ExaminationSchedulePage;