import axios from "axios";

// let BaseUrl =  'http://192.168.1.12:8080/api/';
// export const ServerUrl = 'http://192.168.1.12:8080/';

let BaseUrl =  'https://regportal.ddmock.com/api/';
export const ServerUrl = 'https://regportal.ddmock.com/';

// let BaseUrl =  'http://localhost:52865/api/';
// export const ServerUrl = 'http://localhost:52865/';
export const ClientId = 1;

let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
};

export function Get(Url) {
    return (
        axios.get(BaseUrl + Url, axiosConfig)
    )
} 

export function Post(Url, PostData) {
    return (
        axios.post(BaseUrl + Url, PostData, axiosConfig)    
    )
}

export function PostFormData(Url, PostFormData) {
    return (
        axios({
            method: 'post',
            url: BaseUrl + Url,
            data: PostFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
    )
}