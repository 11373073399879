import React, { Component } from 'react';
import { Button, Divider, Form, Grid, Segment, Header, Label, Message } from 'semantic-ui-react'
import '../Css/custom.css';
import StudentForm from '../Components/StudentForm';
import Validator from 'validator';
import { Post } from "../Classes/ApiHelper";
import { Redirect } from 'react-router-dom'


class Createregistration extends Component {

    state = {
        FormData: {
            lId: 0,
            sPassword: ""
        },
        data: {},
        status: -1,
        message: "",
        loading: false,
        errors: {}
    }

    onChange = e =>
        this.setState({
            FormData: { ...this.state.FormData, [e.target.name]: e.target.value }
        })

    onsubmit = () => {
        this.setState({ loading: true });
        const errors = this.validate(this.state.FormData);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            var PostData = this.state.FormData;
            var LoginData = Post('CandidateLogin', PostData)
            LoginData.then(
                response => this.SaveLogin(response)
            ).catch(error => {
                    const errors = {};
                    errors.FormError = error;
                    this.setState({ errors, loading: false });
                }
            );
        }
        this.setState({ loading: false });
    }

    SaveLogin = (response) => {
        console.log(response);
        this.setState({
            loading: false,
            status: response.data.status,
            message: response.data.message,
            data: response.data.data
        });
        if(this.state.status !== 0)
        {
            localStorage.setItem('user', JSON.stringify(this.state.data));
            this.setState(
                {
                    redirectMe: true,
                }
            );
        }
    }

    validate = (data) => {
        const errors = {};
        if (data.lId === 0) {
            errors.AppNo = "Can't be blank";
        }
        else {
            if (!Validator.isNumeric(data.lId)) {
                errors.AppNo = "Invalid Application No.";
            }
        }
        if (!data.sPassword) {
            errors.password = "Can't be blank";
        }
        return errors;
    }


    render() {
        const { errors, loading, status, message } = this.state;
        let { redirectMe } = this.state;
        if (redirectMe) {
            return ( 
                <Redirect to="/StudentRegistration" />
            );
        }
        return (
            <div>
                <Segment placeholder className='mb-from'>
                    <Grid columns={2} relaxed='very' stackable>
                        <Grid.Column verticalAlign='top'  >
                            <Header size='huge' color='blue' textAlign='center'>New Candidate</Header>
                            <StudentForm ApplicationNo='0' />
                        </Grid.Column>
                        <Grid.Column verticalAlign='top' >
                            <Header size='huge' color='blue' textAlign='center'>Existing Candidate</Header>
                            <Form className="CustomFormField" onSubmit={this.onsubmit} loading={loading}>
                                <Form.Input name='lId' icon='user' label='Application Number' onChange={this.onChange} iconPosition='left' className="CustomField" placeholder='Enter Application Number' />
                                {errors.AppNo && <center><Label basic color='red' pointing>{errors.AppNo}</Label></center>}
                                <Form.Input name='sPassword' icon='lock' label='Password' onChange={this.onChange} iconPosition='left' type="password" className="CustomField" placeholder="Enter Password" />
                                {errors.password && <center><Label basic color='red' pointing>{errors.password}</Label></center>}
                                <br />
                                <Button content='Login' size='big' primary />
                            </Form>
                            <br />
                            {status === 0 &&
                                <Message
                                    negative
                                    header=' '
                                    content={message}
                                />
                            }

                            {errors.FormError &&
                                <Message
                                    negative
                                    header='Something went Wrong.'
                                    content={errors.FormError}
                                />
                            }
                        </Grid.Column>
                    </Grid>
                    <Divider vertical>Or</Divider>
                </Segment>
            </div>
        );
    }
}

export default Createregistration;