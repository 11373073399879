import React, { Component } from 'react';
import { Input, Form, Radio, Segment, Header,Label, Rating, Grid,  Dropdown,Icon, Table, Menu, Button, Checkbox, TextArea } from 'semantic-ui-react';
import '../Css/custom.css';  
import PropTypes from 'prop-types';
import {  ServerUrl,PostFormData,Post,Get } from '../Classes/ApiHelper';
import _ from 'lodash';
import $ from 'jquery';
//import { getEnabledCategories } from 'trace_events';


const Degree = [
    { key: 1, text: 'B.tech', value: 1 },
    { key: 2, text: 'B.A', value: 2 },
    { key: 3, text: 'B.C.A', value: 3 },
    { key: 4, text: 'B.B.A', value: 4 },
]

const GDegree = [
    { key: 1, text: 'Select', value: 1 },
    { key: 2, text: 'B.E.', value: 2 },
    { key: 3, text: 'B.Tech.', value: 3 },
    { key: 4, text: 'BCA', value: 4 },
    { key: 5, text: 'BBA', value: 5 },
    { key: 6, text: 'B.Sc', value: 6 },
    { key: 7, text: 'B.Com', value: 7 },
    { key: 8, text: 'B.A.', value: 8 },
    { key: 9, text: 'Other', value: 9 },
]
const GSubject = [
    { key: 1, text: 'Select', value: 1 },
    { key: 2, text: 'Arts', value: 2 },
    { key: 3, text: 'Science', value: 3 },
    { key: 4, text: 'Commerce', value: 4 },
    { key: 5, text: 'Law', value: 5 },
    { key: 6, text: 'Management', value: 6 },
    { key: 7, text: 'Engineering', value: 7 },
    { key: 9, text: 'Other', value: 8 },
]
const PDegree = [
    { key: 1, text: 'Select', value: 1 },
    { key: 2, text: 'M.E.', value: 2 },
    { key: 3, text: 'M.Tech.', value: 3 },
    { key: 4, text: 'M.Sc', value: 4 },
    { key: 5, text: 'M.Com', value: 5 },
    { key: 6, text: 'M.A', value: 6 },
    { key: 7, text: 'Other', value: 7 },

]
const Cmptknwg = [
    { key: 1, text: 'Certificate in', value: 1 },
    { key: 2, text: 'Diploma in', value: 2 },
    { key: 3, text: 'Degree in', value: 3 },
    { key: 4, text: 'Studied in School/College/Institute', value: 4 },


]
const ExperienceSoftware = [
    { key: 1, text: 'Certificate in', value: 1 },
    { key: 2, text: 'Diploma in', value: 2 },
    { key: 3, text: 'Degree in', value: 3 },
    { key: 4, text: 'Studied in School/College/Institute', value: 4 },


]
const SCmptknwg = [
    { key: 1, text: 'Aircraft Maintenance', value: 'Aircraft Maintenance' },
    { key: 2, text: 'Computer Language', value: 'Computer Language' },
    { key: 3, text: 'Computer as a Subject', value: 'Computer as a Subject' },
    { key: 4, text: 'I.T. as a Subject', value: 'I.T. as a Subject' },


]
const PostGraduation = [
    { key: 1, text: 'HR', value: 'HR' },
    { key: 2, text: 'Finance', value: 'Finance' },
    { key: 3, text: 'MMD', value: 'MMD' },
    { key: 4, text: 'Library Science', value: 'Library Science' },
    { key: 5, text: 'Stenography', value: 'Stenography' },


]
const DDegree = [
    { key: 0, text: 'Select', value: 0 },
    { key: 1, text: 'Aircraft Maintenance', value: 1 },
    { key: 2, text: 'Computer Science', value: 2 },
    { key: 3, text: 'Agriculture', value: 3 },
    { key: 4, text: 'Other', value: 4 },
]
const PSubject = [
    { key: 1, text: 'Select', value: 1 },
    { key: 2, text: 'Arts', value: 2 },
    { key: 3, text: 'Science', value: 3 },
    { key: 4, text: 'Commerce', value: 4 },
    { key: 5, text: 'Law', value: 5 },
    { key: 6, text: 'Management', value: 6 },
    { key: 7, text: 'Engineering', value: 7 },
    { key: 8, text: 'Other', value: 8 },

]
const Subject = [
    { key: 1, text: 'Science', value: 1 },

]
const Date = [
    { key: 1, text: '1', value: 1 },
    { key: 2, text: '2', value: 2 },
    { key: 3, text: '3', value: 3 },
    { key: 4, text: '4', value: 4 }, { key: 5, text: '5', value: 5 }, { key: 6, text: '6', value: 6 }, { key: 7, text: '7', value: 7 }, { key: 8, text: '8', value: 8 }, { key: 9, text: '9', value: 9 }, { key: 10, text: '10', value: 10 },
    { key: 11, text: '11', value: 11 }, { key: 12, text: '12', value: 12 }, { key: 13, text: '13', value: 13 }, { key: 14, text: '14', value: 14 },
    { key: 15, text: '15', value: 15 }, { key: 16, text: '16', value: 16 }, { key: 17, text: '17', value: 17 }, { key: 18, text: '18', value: 18 },
    { key: 19, text: '19', value: 19 }, { key: 20, text: '20', value: 20 }, { key: 21, text: '21', value: 21 }, { key: 22, text: '22', value: 22 },
    { key: 23, text: '23', value: 23 }, { key: 24, text: '24', value: 24 }, { key: 25, text: '25', value: 25 },
    { key: 26, text: '26', value: 26 },
    { key: 27, text: '27', value: 27 }, { key: 28, text: '28', value: 28 }, { key: 29, text: '29', value: 29 },
    { key: 30, text: '30', value: 30 }, { key: 31, text: '31', value: 31 },
]
const Month = [
    { key: 1, text: 'Jan', value: 1 },
    { key: 2, text: 'Feb', value: 2 },
    { key: 3, text: 'March', value: 3 },
    { key: 4, text: 'April', value: 4 }, { key: 5, text: 'May', value: 5 }, { key: 6, text: 'June', value: 6 }, { key: 7, text: 'July', value: 7 },
    { key: 8, text: 'Aug', value: 8 }, { key: 9, text: 'Sep', value: 9 }, { key: 10, text: 'Oct', value: 10 },
    { key: 11, text: 'Nov', value: 11 }, { key: 12, text: 'Dec', value: 12 },
]
const Year = [
    { key: 1, text: '2019', value: 2019 },
    { key: 1, text: '2018', value: 2018 },
    { key: 1, text: '2017', value: 2017 },
    { key: 1, text: '2016', value: 2016 },
    { key: 1, text: '2015', value: 2015 },
    { key: 1, text: '2014', value: 2014 },
    { key: 1, text: '2013', value: 2013 },
    { key: 1, text: '2012', value: 2012 },
    { key: 1, text: '2011', value: 2011 },
    { key: 1, text: '2010', value: 2010 },
    { key: 1, text: '2009', value: 2009 },
    { key: 1, text: '2008', value: 2008 },
    { key: 1, text: '2007', value: 2007 },
    { key: 1, text: '2006', value: 2006 },
    { key: 1, text: '2005', value: 2005 },
    { key: 1, text: '2004', value: 2004 },
    { key: 1, text: '2003', value: 2003 },
    { key: 1, text: '2002', value: 2002 }, 
    { key: 1, text: '2001', value: 2001 },
    { key: 2, text: '2000', value: 2000 },
    { key: 3, text: '1999', value: 1999 },
    { key: 4, text: '1998', value: 1998 }, { key: 5, text: '1997', value: 1997 }, { key: 6, text: '1996', value: 1996 }, { key: 7, text: '1995', value: 1995 },
    { key: 8, text: '1994', value: 1994 }, { key: 9, text: '1993', value: 1993 }, { key: 10, text: '1992', value: 1992 },
    { key: 11, text: '1991', value: 1991 }, { key: 12, text: '1990', value: 1990 },
    { key: 13, text: '1989', value: 1989 },
    { key: 14, text: '1988', value: 1988 },
    { key: 15, text: '1987', value: 1987 },
    { key: 16, text: '1986', value: 1986 },
    { key: 17, text: '1985', value: 1985 },
    { key: 18, text: '1984', value: 1984 },
    { key: 19, text: '1983', value: 1983 },
    { key: 20, text: '1982', value: 1982 },
    { key: 21, text: '1981', value: 1981 },
    { key: 22, text: '1980', value: 1980 },
    { key: 22, text: '1979', value: 1979 }, { key: 22, text: '1969', value: 1969 },
    { key: 22, text: '1978', value: 1978 }, { key: 22, text: '1968', value: 1968 },
    { key: 22, text: '1977', value: 1977 }, { key: 22, text: '1967', value: 1967 },
    { key: 22, text: '1976', value: 1976 }, { key: 22, text: '1966', value: 1966 },
    { key: 22, text: '1975', value: 1975 }, { key: 22, text: '1965', value: 1965 },
    { key: 22, text: '1974', value: 1974 }, { key: 22, text: '1964', value: 1964 },
    { key: 22, text: '1973', value: 1973 }, { key: 22, text: '1963', value: 1963 },
    { key: 22, text: '1972', value: 1972 }, { key: 22, text: '1962', value: 1962 },
    { key: 22, text: '1971', value: 1971 }, { key: 22, text: '1961', value: 1961 },
    { key: 22, text: '1970', value: 1970 }, { key: 22, text: '1960', value: 1960 },
]
const Grade = [
    { key: 1, text: 'Grade A', value: 1 },
    { key: 2, text: 'Grade B', value: 2 },
    { key: 3, text: 'Grade C', value: 3 },
    { key: 4, text: 'Grade D', value: 3 },

]

const getcmptknwlg = (number, prefix = 'Choice ') =>
    _.times(number, index => ({
        key: index,
        text: `${prefix}${index}`,
        value: index,
    }))

let   formdata = new FormData();

class Qualification extends Component {

    state = {
        loading: true,
        status: -1,
        message: "",
        data: [],
        FormData: {
            exampassed: '',
            stotlexp: '',
            prcntg_st: '',
            stdocumnt: '',
            ddegree1: null,
            dsubjct: null,
            dunivrsy: '',
            dprcnt_mark: '',
            dfile_upload: '',
            gdegree1: null,
            ssubjct: null,
            gunivrsy: '',
            gprcnt_mark: '',
            gfile_upload: '',
            tenth_sub: '',
            lang_one:'',
            clg_name: '',
            computerknowledge: null,
            iWorkGroup: null,
            iComputerGroup: null,
            iEligibleGroup: null,
            iEligibleGroup123:null,
            iQualNote:null
        },
        QualificationList: {},
        errors: {},
        ApplicationNo: 0,
        dataq: {},
        data_xprnc:{},
    }

    computerknowledgedata = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, computerknowledge: value }
        })
    }

    FormRadioChange = (e, { value, name }) => {
        this.setState({
            FormData: { ...this.state.FormData, name: value }
        }, console.log(name + "=" + value))
    }
    FormChange = e => {
        this.setState({
            FormData: { ...this.state.FormData, [e.target.name]: e.target.value }
        }, console.log(this.state.FormData))
    }

    WorkGroup = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, iWorkGroup: value }
        })
    }

    ComputerGroup = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, iComputerGroup: value }
        })
    }

    EligibleGroup = (e, { value }) => {
        this.setState({
            FormData: { ...this.state.FormData, iEligibleGroup: value }
        }) 
    }

    EligibleGroupjs (event) {
        //alert(event.target.value);
        if(event.target.value=='1'){
            $("#elg_yes").removeClass("display_none");
            $("#elg_no").addClass("display_none");
            $('.g_gegree').removeClass("display_none");
        } else if(event.target.value=='0'){
            $("#elg_yes").addClass("display_none");
            $("#elg_no").removeClass("display_none");
            $('.g_gegree').addClass("display_none");
        }else{
            $("#elg_yes").addClass("display_none");
            $("#elg_no").addClass("display_none");
            $('.g_gegree').removeClass("display_none");
        }
      }
      QualNotejs (event) {
         // alert(event.target.value); 
          if(event.target.value=='1'){ 
            $('.post_qulf').removeClass("display_none");
        }  else{ 
            $('.post_qulf').addClass("display_none");
        }
        }
  

    QualNote = (e, { value }) => { 
        this.setState({
            FormData: { ...this.state.FormData, iQualNote: value }
        }) 
    }


    validate = (data) => { 
        formdata = new FormData();
        const errors = {};
        if (data.stotlexp === null) {
            errors.stotlexp = "Can't be blank";
        }
        if (data.iQualNote== 1) { 
            if($('.post_graduadtion').val()==0){
                errors.post_graduadtion = "Can't be blank";
            }
            if($('.txt_post_graduadtion').val()=='' && $('.post_graduadtion').val()==-1){
                errors.post_graduadtion = "Can't be blank";
            }
            if($('.board_pg').val()==0){
                errors.board_pg = "Can't be blank";
            }
            if($('.date_pg').val()==0){
                errors.date_pg = "Can't be blank";
            }
            if($('.month_pg').val()==0){
                errors.month_pg = "Can't be blank";
            }
            if($('.year_pg').val()==0){
                errors.year_pg = "Can't be blank";
            }
            if($('.percentage_pg').val()==''){
                errors.percentage_pg = "Can't be blank";
            }
            if($('#pg').val()==''){
                errors.pg = "Can't be blank";
            }
        }
        let  date_10=$('.date_10').val();
        let month_10=$('.month_10').val();
        let  year_10=$('.year_10').val();
        if(date_10!="0"&&month_10!="0"&&year_10!="0"){
            var dob123=date_10+'-'+month_10+'-'+year_10;             
            formdata.append("passing_tenth", dob123);
        }
        let date_graduation=$('.date_graduation').val();
        let month_graduation=$('.month_graduation').val();
        let year_graduation=$('.year_graduation').val();
        if(date_graduation!="0"&&month_graduation!="0"&&year_graduation!="0"){
            var dob123=date_graduation+'-'+month_graduation+'-'+year_graduation;             
            formdata.append("passing_graduation", dob123);
        }
        let date_diploma=$('.date_diploma').val();
        let month_diploma=$('.month_diploma').val();
        let year_diploma=$('.year_diploma').val();
        if(date_diploma!="0"&&month_diploma!="0"&&year_diploma!="0"){
            var dob123=date_diploma+'-'+month_diploma+'-'+year_diploma;             
            formdata.append("passing_diploma", dob123);
        }
        let date_pg=$('.date_pg').val();
        let month_pg=$('.month_pg').val();
        let year_pg=$('.year_pg').val();
        if(date_pg!="0"&&month_pg!="0"&&year_pg!="0"){
            var dob123=date_pg+'-'+month_pg+'-'+year_pg;             
            formdata.append("passing_pg", dob123);
        }
        let date_other=$('.date_other').val();
        let month_other=$('.month_other').val();
        let year_other=$('.year_other').val();
        if(date_other!="0"&&month_other!="0"&&year_other!="0"){
            var dob123=date_other+'-'+month_other+'-'+year_other;             
            formdata.append("passing_other", dob123);
        }
        
        if ($('#subject_stream_tenth').val() == '') {
            //alert($('#subject_stream_tenth').val());
            errors.tenth_sub = "Can't be blank";
        } 
        if ($('.board_tenth').val() == '') {
            errors.clg_name = "Can't be blank";
        }
        if ($('.percentage_tenth').val() == '') {
            errors.prcntg_st = "Can't be blank";
        }
        // if ($('.inputfile').val() === '') {
        //     errors.stdocumnt = "Can't be blank";
        // }
       // alert(formdata.get("subject_stream_tenth"));
        
    //     if (formdata.get("subject_stream_tenth") === null) 
    //    // if (!data.has("subject_stream_tenth"))
    //     {
    //         errors.tenth_sub = "Can't be blank";
    //     }
        // if (data.iEligibleGroup === null) {
        //     errors.iEligibleGroup123 = "Must Select";
        // }
        if (data.iComputerGroup === null) {
            errors.iComputerGroup = "Must Select";
        }
        if(data.iEligibleGroup==1){
            if ($('.GDegree').val() == '0') {
                errors.gdegree = "Must Select";
            }
            if ($('.GDegree').val() == '9' && $('.degree_graduation').val() == '') {
                errors.gdegree = "Can't be blank";
            }
            if ($('.Gsubject').val() == '0') {
                errors.ssubjct = "Must Select";
            }
            if ($('.Gsubject').val() == '8' && $('.subject_stream_graduation').val() == '') {
                errors.ssubjct = "Can't be blank";
            }
            if ($('.board_graduation').val() == '') {
                errors.gunivrsy = "Can't be blank";
            }
            if ($('.date_graduation').val() == '0') {
                errors.date1_valid = "Must Select";
            }
            if ($('.month_graduation').val() == '0') {
                errors.date1_valid = "Must Select";
            }
            if ($('.year_graduation').val() == '0') {
                errors.date1_valid = "Must Select";
            }

            if ($('.percentage_graduation').val() == '') {
                errors.gprcnt_mark = "Can't be blank";
            }
            if ($('#graduation').val() == '') {
                errors.gfile_upload = "Must Select";
            }
        }
      if($('.Gsubject').val()=='3'){
        if ($('.month_diploma').val() == '0') {
            errors.subject_stream_diploma = "Must Select";
        } 
        if ($('.board_diploma').val() == '') {
            errors.dunivrsy = "Can't be blank";
        }
        if ($('.percentage_diploma').val() == '') {
            errors.dprcnt_mark = "Can't be blank";
        }
        if ($('#diploma').val() == '') {
            errors.diploma = "Must Select";
        }
        if ($('.date_diploma').val() == '0') {
            errors.date_diplo = "Can't be blank";
        }
        if ($('.month_diploma').val() == '0') {
            errors.date_diplo = "Can't be blank";
        }
        if ($('.year_diploma').val() == '0') {
            errors.date_diplo = "Can't be blank";
        }

      }
      if(data.iWorkGroup==1){
        if ($('.employer_name').val() == '') {
            errors.employer_name = "Can't be blank";
        }
        if ($('.designation').val() == '') {
            errors.designation = "Can't be blank";
        } 
        if ($('.date_xprnc').val() == '0') {
            errors.date_xprnc = "Must Select";
        }
        if ($('.month_xprnc').val() == '0') {
            errors.month_xprnc = "Must Select";
        }
        if ($('.year_xprnc').val() == '0') {
            errors.year_xprnc = "Must Select";
        }
        if ($('.date_xprnc2').val() == '0') {
            errors.date_xprnc2 = "Must Select";
        }
        if ($('.month_xprnc2').val() == '0') {
            errors.month_xprnc2 = "Must Select";
        }
        if ($('.year_xprnc2').val() == '0') {
            errors.year_xprnc2 = "Must Select";
        }
        if ($('.ddlexperience1').val() == '0') {
            errors.nature_duties = "Must Select";
        }
        if ($('.ddlexperience1').val() == '-1' && $('.nature_duties').val() == '') {
            errors.nature_duties = "Must Select";
        }
        if ($('#xprnc').val() == '') {
            errors.xprnc = "Must Select";
        }
      }
        if ($('#10th').val() == '') {
            errors.stdocumnt = "Must Select";
        }
        if ($('.year_10').val() === '0') {
            errors.date_valid = "Can't be blank";
        }
        if ($('.month_10').val() == '0') {
            errors.date_valid = "Can't be blank";
        }
        if ($('.date_10').val() == '0') {
            errors.date_valid = "Can't be blank";
        }
        if ($('#my_lang').val() == '') {
            errors.lang_one = "Can't be blank";
        } 
        if (data.iWorkGroup === null) {
            errors.iWorkGroup = "Must Select";
        }
        formdata.append("dc_percentage", $('.dc_percentage').val());
        formdata.append("degree_graduation", $('.degree_graduation').val());
         
        formdata.append("subject_stream_tenth", $('#subject_stream_tenth').val());
        formdata.append("board_tenth", $('.board_tenth').val());
        formdata.append("percentage_tenth", $('.percentage_tenth').val());
        if($('.GDegree').val()=="9"){
            formdata.append("degree_graduation", $('.degree_graduation').val());
        }else{
            formdata.append("degree_graduation", $('.GDegree').val());
        }
        if($('.Gsubject').val()=="8"){
            formdata.append("subject_stream_graduation", $('.subject_stream_graduation').val());
        }else{
            formdata.append("subject_stream_graduation", $('.Gsubject').val());

        }
        formdata.append("board_graduation", $('.board_graduation').val());
        formdata.append("percentage_graduation", $('.percentage_graduation').val());
       
        if($('.diploma_degree').val()=="-1"){
        formdata.append("degree_diploma", $('.degree_diploma').val());}else{
            formdata.append("degree_diploma", $('.diploma_degree').val());}
            if($('.diploma_degree').val()=="4"){
            formdata.append("degree_diploma", $('.degree_diploma').val());}else{
                formdata.append("degree_diploma", $('.diploma_degree').val());}
        if($('.diploma_sdegree').val()=="7"){
        formdata.append("subject_stream_diploma", $('.subject_stream_diploma').val());}else{
            formdata.append("subject_stream_diploma", $('.diploma_sdegree').val());}
        
            if($('.post_graduadtion').val()=="-1"){
                formdata.append("post_graduadtion", $('.txt_post_graduadtion').val());}else{
                    formdata.append("post_graduadtion", $('.post_graduadtion').val());}
                
            formdata.append("board_pg", $('.board_pg').val());
            formdata.append("board_diploma", $('.board_diploma').val());
        formdata.append("percentage_diploma", $('.percentage_diploma').val());
        formdata.append("percentage_pg", $('.percentage_pg').val());
     
        formdata.append("qlfctn_other", $('.qlfctn_other').val());
        formdata.append("degree_other", $('.degree_other').val());
        formdata.append("subject_stream_other", $('.subject_stream_other').val());
        formdata.append("board_other", $('.board_other').val());
        formdata.append("percentage_other", $('.percentage_other').val());
         
        formdata.append("employer_name", $('.employer_name').val());
        formdata.append("designation", $('.designation').val());
        formdata.append("nature_duties", $('.nature_duties').val());
        formdata.append("ofleaving_reason", $('.ofleaving_reason').val());

        
        formdata.append("employer_name2", $('.employer_name2').val());
        formdata.append("designation2", $('.designation2').val());
        formdata.append("nature_duties2", $('.nature_duties2').val());
        formdata.append("ofleaving_reason2", $('.ofleaving_reason2').val());
        
        
        formdata.append("employer_name3", $('.employer_name3').val());
        formdata.append("designation3", $('.designation3').val());
        formdata.append("nature_duties3", $('.nature_duties3').val());
        formdata.append("ofleaving_reason3", $('.ofleaving_reason3').val());
        
        
        formdata.append("employer_name4", $('.employer_name4').val());
        formdata.append("designation4", $('.designation4').val());
        formdata.append("nature_duties4", $('.nature_duties4').val());
        formdata.append("ofleaving_reason4", $('.ofleaving_reason4').val());
 
        formdata.append("language", $('#my_lang').val());
        formdata.append("language2", $('#my_lang2').val());
        if ($('input[name=lng_read]').prop("checked")) {
            formdata.append("language_read", 1);
        }
        else {
            formdata.append("language_read", 0);
        }
        if ($('input[name=lng_write]').prop("checked")) {
            formdata.append("language_write", 1);
        }
        else {
            formdata.append("language_write", 0);
        }
        if ($('input[name=lng_speak]').prop("checked")) {
            formdata.append("language_speak", 1);
        }
        else {
            formdata.append("language_speak", 0);
        }
        if ($('input[name=lng2_read]').prop("checked")) {
            formdata.append("language2_read", 1);
        }
        else {
            formdata.append("language2_read", 0);
        }
        if ($('input[name=lng2_write]').prop("checked")) {
            formdata.append("language2_write", 1);
        }
        else {
            formdata.append("language2_write", 0);
        }
        if ($('input[name=lng2_speak]').prop("checked")) {
            formdata.append("language2_speak", 1);
        }
        else {
            formdata.append("language2_speak", 0);
        }
        if($('.ddlexperience4').val()!='-1'){
            formdata.append("nature_duties4", $('.ddlexperience4').val());
        }
        if($('.ddlexperience3').val()!='-1'){
            formdata.append("nature_duties3", $('.ddlexperience3').val());
        }
        if($('.ddlexperience2').val()!='-1'){
            formdata.append("nature_duties2", $('.ddlexperience2').val());
        }
        if($('.ddlexperience1').val()!='-1'){
            formdata.append("nature_duties", $('.ddlexperience1').val());
        }
        //formdata.append("10th", file);
        getDOB();
        function getDOB(){
            let  date_xprnc=$('.date_xprnc').val();
            let month_xprnc=$('.month_xprnc').val();
            let  year_xprnc=$('.year_xprnc').val();
            let  date_xprnc2=$('.date_xprnc2').val();
            let month_xprnc2=$('.month_xprnc2').val();
            let  year_xprnc2=$('.year_xprnc2').val(); 
            if(date_xprnc!="0"&&month_xprnc!="0"&&year_xprnc!="0"&&date_xprnc2!="0"&&month_xprnc2!="0"&&year_xprnc2!="0"){ 
                    var xprnc_date=date_xprnc+'-'+month_xprnc+'-'+year_xprnc+'to'+date_xprnc2+'-'+month_xprnc2+'-'+year_xprnc2;             
                    var xprnc_dat=date_xprnc+'-'+month_xprnc+'-'+year_xprnc+' to '+date_xprnc2+'-'+month_xprnc2+'-'+year_xprnc2;             
                    formdata.append("service_period", xprnc_dat);
                    getAge(xprnc_date);
            }
            let  date2_xprnc=$('.date2_xprnc').val();
            let month2_xprnc=$('.month2_xprnc').val();
            let  year2_xprnc=$('.year2_xprnc').val();
            let  date2_xprnc2=$('.date2_xprnc2').val();
            let month2_xprnc2=$('.month2_xprnc2').val();
            let  year2_xprnc2=$('.year2_xprnc2').val(); 
            let  date3_xprnc= $('.date3_xprnc').val();
            let month3_xprnc=$('.month3_xprnc').val();
            let  year3_xprnc= $('.year3_xprnc').val();
            let  date3_xprnc2=$('.date3_xprnc2').val();
            let month3_xprnc2=$('.month3_xprnc2').val();
            let  year3_xprnc2= $('.year3_xprnc2').val();
            let  date4_xprnc=  $('.date4_xprnc').val();
            let month4_xprnc= $('.month4_xprnc').val();
            let  year4_xprnc=  $('.year4_xprnc').val();
            let  date4_xprnc2= $('.date4_xprnc2').val();
            let month4_xprnc2=$('.month4_xprnc2').val();
            let  year4_xprnc2= $('.year4_xprnc2').val();
            if(date2_xprnc!="0"&&month2_xprnc!="0"&&year2_xprnc!="0"&&date2_xprnc2!="0"&&month2_xprnc2!="0"&&year2_xprnc2!="0"){
                var xprnc_date2=date2_xprnc+'-'+month2_xprnc+'-'+year2_xprnc+'to'+date2_xprnc2+'-'+month2_xprnc2+'-'+year2_xprnc2;             
                var xprnc_date=date2_xprnc+'-'+month2_xprnc+'-'+year2_xprnc+' to '+date2_xprnc2+'-'+month2_xprnc2+'-'+year2_xprnc2;             
                formdata.append("service_period2", xprnc_date);
                getAge2(xprnc_date2,2);
            }
            if(date3_xprnc!="0"&&month3_xprnc!="0"&&year3_xprnc!="0"&&date3_xprnc2!="0"&&month3_xprnc2!="0"&&year3_xprnc2!="0"){
                var xprnc_date2=date3_xprnc+'-'+month3_xprnc+'-'+year3_xprnc+'to'+date3_xprnc2+'-'+month3_xprnc2+'-'+year3_xprnc2;             
                var xprnc_date=date3_xprnc+'-'+month3_xprnc+'-'+year3_xprnc+' to '+date3_xprnc2+'-'+month3_xprnc2+'-'+year3_xprnc2;             
                formdata.append("service_period3", xprnc_date);
                getAge2(xprnc_date2,3);
            }
            if(date4_xprnc!="0"&&month4_xprnc!="0"&&year4_xprnc!="0"&&date4_xprnc2!="0"&&month4_xprnc2!="0"&&year4_xprnc2!="0"){
                var xprnc_date2=date4_xprnc+'-'+month4_xprnc+'-'+year4_xprnc+'to'+date4_xprnc2+'-'+month4_xprnc2+'-'+year4_xprnc2;             
                var xprnc_date=date4_xprnc+'-'+month4_xprnc+'-'+year4_xprnc+' to '+date4_xprnc2+'-'+month4_xprnc2+'-'+year4_xprnc2;             
                formdata.append("service_period4", xprnc_date);
                getAge2(xprnc_date2,4);
            }
            if(date_xprnc!="0"&&month_xprnc!="0"&&year_xprnc!="0"&&date_xprnc2!="0"&&month_xprnc2!="0"&&year_xprnc2!="0"&& date2_xprnc!="0"&&month2_xprnc!="0"&&year2_xprnc!="0"&&date2_xprnc2!="0"&&month2_xprnc2!="0"&&year2_xprnc2!="0"){
                var xprnc_date=date_xprnc+'-'+month_xprnc+'-'+year_xprnc+'to'+date_xprnc2+'-'+month_xprnc2+'-'+year_xprnc2;   
                var xprnc_date2=date2_xprnc+'-'+month2_xprnc+'-'+year2_xprnc+'to'+date2_xprnc2+'-'+month2_xprnc2+'-'+year2_xprnc2;          
                getTtlXPrnc(xprnc_date,xprnc_date2);
            } 
          }
          function getAge(dateString1) { 
              var sdate=dateString1.split('to')[0];
              var edate=dateString1.split('to')[1];
              var mdate = sdate.split('-');
              var mdate2 = edate.split('-');
              var yearThen = parseInt(mdate[2]);
              var monthThen = parseInt(mdate[1]);
              var dayThen = parseInt(mdate[0]);
              var yearThen1 = parseInt(mdate2[2]);
              var monthThen1 = parseInt(mdate2[1]);
              var dayThen1 = parseInt(mdate2[0]);
              var today = new window.Date(yearThen1, monthThen1-1, dayThen1);
              var birthday = new window.Date(yearThen, monthThen-1, dayThen);
              var differenceInMilisecond = today.valueOf() - birthday.valueOf();
              var year_age = Math.floor(differenceInMilisecond / 31536000000);
              var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
              var month_age = Math.floor(day_age/30);
              day_age = day_age % 30;
              if (isNaN(year_age) || isNaN(month_age) || isNaN(day_age)|| differenceInMilisecond<=0) {
                  alert("Experience Days Must be Grater then Zero");
              }
              else {
                  $('.lbl_age').html( year_age + " years " + month_age + " months " + day_age + " days");
                  var age_dob= year_age + " years " + month_age + " months " + day_age + " days";
                   
                  formdata.append("experience", age_dob);
                  var xprnc_mnth=month_age+(year_age*12);
                  $('.lbl_xprnc').html(xprnc_mnth+ " months ");
              }
          }  
          function getAge2(dateString1,nmbr) {
              var sdate=dateString1.split('to')[0];
              var edate=dateString1.split('to')[1];
              var mdate = sdate.split('-');
              var mdate2 = edate.split('-');
              var yearThen = parseInt(mdate[2]);
              var monthThen = parseInt(mdate[1]);
              var dayThen = parseInt(mdate[0]);
              var yearThen1 = parseInt(mdate2[2]);
              var monthThen1 = parseInt(mdate2[1]);
              var dayThen1 = parseInt(mdate2[0]);
              var today = new window.Date(yearThen1, monthThen1-1, dayThen1);
              var birthday = new window.Date(yearThen, monthThen-1, dayThen);
              var differenceInMilisecond = today.valueOf() - birthday.valueOf();
              var year_age = Math.floor(differenceInMilisecond / 31536000000);
              var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
              var month_age = Math.floor(day_age/30);
              day_age = day_age % 30;
              if (isNaN(year_age) || isNaN(month_age) || isNaN(day_age)|| differenceInMilisecond<=0) {
                  alert("Experience Days Must be Grater then Zero");
              }
              else {
                  $('.lbl_age').html( year_age + " years " + month_age + " months " + day_age + " days");
                  var age_dob= year_age + " years " + month_age + " months " + day_age + " days";
                  formdata.append("experience"+nmbr, age_dob);
              }
          }  
          function getTtlXPrnc(dateString1,dateString2) {
              var sdate=dateString1.split('to')[0];
              var edate=dateString1.split('to')[1];
              var sdate2=dateString2.split('to')[0];
              var edate2=dateString2.split('to')[1];
              var mdate = sdate.split('-');
              var mdate2 = edate.split('-');
              var mdate3 = sdate2.split('-');
              var mdate4 = edate2.split('-');
              var yearThen = parseInt(mdate[2]);
              var monthThen = parseInt(mdate[1]);
              var dayThen = parseInt(mdate[0]);
              var yearThen1 = parseInt(mdate2[2]);
              var monthThen1 = parseInt(mdate2[1]);
              var dayThen1 = parseInt(mdate2[0]);
              var yearThen3 = parseInt(mdate3[2]);
              var monthThen3 = parseInt(mdate3[1]);
              var dayThen3 = parseInt(mdate3[0]);
              var yearThen4 = parseInt(mdate4[2]);
              var monthThen4 = parseInt(mdate4[1]);
              var dayThen4 = parseInt(mdate4[0]);
              var today = new window.Date(yearThen1, monthThen1-1, dayThen1);
              var birthday = new window.Date(yearThen, monthThen-1, dayThen);
              var today1 = new window.Date(yearThen4, monthThen4-1, dayThen4);
              var birthday1 = new window.Date(yearThen3, monthThen3-1, dayThen3);
              var differenceInMilisecond1= today.valueOf() - birthday.valueOf();
              var differenceInMilisecond2 = today1.valueOf() - birthday1.valueOf();
              var differenceInMilisecond=differenceInMilisecond1+differenceInMilisecond2;
              var year_age = Math.floor(differenceInMilisecond / 31536000000);
              var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
              var month_age = Math.floor(day_age/30);
              day_age = day_age % 30;
              if (isNaN(year_age) || isNaN(month_age) || isNaN(day_age)|| differenceInMilisecond<=0) {
                  alert("Experience Days Must be Grater then Zero");
              }
              else {
                  var xprnc_mnth=month_age+(year_age*12);
                  $('.lbl_xprnc').html(xprnc_mnth+ " months ");
                 // var age_dob= year_age + " years " + month_age + " months " + day_age + " days";
                  //alert(age_dob);
              }
          } 
        console.log('errors',errors);
        return errors;
    }



    SaveData = () => {
        console.log("Upload", formdata)
        const errors = this.validate(this.state.FormData)
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
           // alert("check data");
            this.setState({ loading: true })
            var PostData = this.state.FormData;
            PostData.lRegId = this.props.ApplicationNo;
            PostData.lId = 0; 
            debugger;
            var obj = $(document);
            var data = new FormData();
            var images = $(obj).find("input[type='file']");
            for (var i = 0; i < images.length; i++) {
                if($('#'+images[i].id).val()!='')
                formdata.append(images[i].id, images[i].files[0]);
            }
            formdata.append("computer_knowledge", PostData.iComputerGroup);
            formdata.append("present_working", PostData.iWorkGroup);
            formdata.append("iQualNote", PostData.iQualNote);
            formdata.append("iEligibleGroup", PostData.iEligibleGroup);
            formdata.append("in_candidate_id", this.props.ApplicationNo);
            var ExamData = PostFormData('QualificationAI', formdata)
            ExamData.then(
                response => this.setState({
                    loading: false,
                    status: response.data.status,
                    message: response.data.message,
                    data: response.data.data
                }, () => {
                    debugger;
                    var abc=this.state;
                    if (this.props.ApplicationNo !== '0') {
                        this.props.submit(5);
                    }
                })
            ).catch(error => {
                const errors = {};
                errors.FormError = error;
                this.setState({ errors, loading: false });
            });
        }else{ 
            alert('Please Fill All Required Information From The Page Before Procedding!')
            $("html, body").animate({ scrollTop: 0 }, "slow");
           }
        return false;
    }


    componentDidMount() {
        const script = document.createElement("script");
        script.src = "../src/Css/lightboxmin.js";
        this.setState({ ApplicationNo: this.props.ApplicationNo })
        this.setState({ loading: true })
        var CanData = Get('CandidatePersonalDetail/' + this.props.ApplicationNo)
        CanData.then(
            response => this.setState({
              loading: false,
              message: response.data.message,
              FormData: response.data.data[0],
              data: response.data.data[0],
              dataq:response.data.dataq,
              data_xprnc:response.data.data_xprnc
          })
        ).catch(error => this.setState({ error, loading: false }));
       
        let ExamLoadData = JSON.parse(sessionStorage.getItem('ExamData'));
        var PostData =
        {
            "id": ExamLoadData.ExamID
        }
        var QualificationData = Post('DegreeApi', PostData)
        QualificationData.then(
            response => this.setState({
                loading: false,
                QualificationList: response.data.data.degree
            }, console.log(this.state))
        ).catch(error => this.setState({ error, loading: false }));

        $('input[type=radio]').removeClass('hidden');
        $('input[type=checkbox]').removeClass('hidden');
//  console.log('abc',this.state);
//         $(".iEligibleGroup123").change(function(){
//             debugger;
//             alert($('input[name=iEligibleGroup123]').val());
//             if($(this).val()=='1'){
//                 $("#elg_yes").removeClass("display_none");
//                 $("#elg_no").addClass("display_none");
//                 $('.g_gegree').removeClass("display_none");
//             } else if($(this).val()=='0'){
//                 $("#elg_yes").addClass("display_none");
//                 $("#elg_no").removeClass("display_none");
//                 $('.g_gegree').addClass("display_none");
//             }else{
//                 $("#elg_yes").addClass("display_none");
//                 $("#elg_no").addClass("display_none");
//                 $('.g_gegree').removeClass("display_none");
//             }
//         }); 
//         $(".iQualNote").change(function(){
//             debugger;
//             alert($(this).val());
//             if($(this).val()=='1'){ 
//                 $('.post_qulf').removeClass("display_none");
//             }  else{ 
//                 $('.post_qulf').addClass("display_none");
//             }
//         });
        $('select[name=Date]').change(function(){ 
            getDOB();
          });  
        
          $('select[name=Month]').change(function(){ 
            getDOB();
            });  
        
        $('select[name=Year]').change(function(){ 
            getDOB();
        });  
        function getAge(dateString1) {
            debugger;
            var sdate=dateString1.split('to')[0];
            var edate=dateString1.split('to')[1];
            var mdate = sdate.split('-');
            var mdate2 = edate.split('-');
            var yearThen = parseInt(mdate[2]);
            var monthThen = parseInt(mdate[1]);
            var dayThen = parseInt(mdate[0]);
            var yearThen1 = parseInt(mdate2[2]);
            var monthThen1 = parseInt(mdate2[1]);
            var dayThen1 = parseInt(mdate2[0]);
            var today = new window.Date(yearThen1, monthThen1-1, dayThen1);
            var birthday = new window.Date(yearThen, monthThen-1, dayThen);
            var differenceInMilisecond = today.valueOf() - birthday.valueOf();
            var year_age = Math.floor(differenceInMilisecond / 31536000000);
            var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
            var month_age = Math.floor(day_age/30);
            day_age = day_age % 30;
            if (isNaN(year_age) || isNaN(month_age) || isNaN(day_age)|| differenceInMilisecond<=0) {
                alert("Experience Days Must be Grater then Zero");
            }
            else {
                $('.lbl_age').html( year_age + " years " + month_age + " months " + day_age + " days");
                var age_dob= year_age + " years " + month_age + " months " + day_age + " days";
                 
                formdata.append("experience", age_dob);
                var xprnc_mnth=month_age+(year_age*12);
                $('.lbl_xprnc').html(xprnc_mnth+ " months ");
            }
        }  
        function getAge2(dateString1,nmbr) {
            var sdate=dateString1.split('to')[0];
            var edate=dateString1.split('to')[1];
            var mdate = sdate.split('-');
            var mdate2 = edate.split('-');
            var yearThen = parseInt(mdate[2]);
            var monthThen = parseInt(mdate[1]);
            var dayThen = parseInt(mdate[0]);
            var yearThen1 = parseInt(mdate2[2]);
            var monthThen1 = parseInt(mdate2[1]);
            var dayThen1 = parseInt(mdate2[0]);
            var today = new window.Date(yearThen1, monthThen1-1, dayThen1);
            var birthday = new window.Date(yearThen, monthThen-1, dayThen);
            var differenceInMilisecond = today.valueOf() - birthday.valueOf();
            var year_age = Math.floor(differenceInMilisecond / 31536000000);
            var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
            var month_age = Math.floor(day_age/30);
            day_age = day_age % 30;
            if (isNaN(year_age) || isNaN(month_age) || isNaN(day_age)|| differenceInMilisecond<=0) {
                alert("Experience Days Must be Grater then Zero");
            }
            else {
                $('.lbl_age').html( year_age + " years " + month_age + " months " + day_age + " days");
                var age_dob= year_age + " years " + month_age + " months " + day_age + " days";
                formdata.append("experience"+nmbr, age_dob);
            }
        }  
        $('.cls_marks').change(function(){
            if($(this).val()>100 || $(this).val()<0){
                alert('Marks % must be less then or equal to 100');
                $(this).val('');
            }
        });
        function getTtlXPrnc(dateString1,dateString2) {
            var sdate=dateString1.split('to')[0];
            var edate=dateString1.split('to')[1];
            var sdate2=dateString2.split('to')[0];
            var edate2=dateString2.split('to')[1];
            var mdate = sdate.split('-');
            var mdate2 = edate.split('-');
            var mdate3 = sdate2.split('-');
            var mdate4 = edate2.split('-');
            var yearThen = parseInt(mdate[2]);
            var monthThen = parseInt(mdate[1]);
            var dayThen = parseInt(mdate[0]);
            var yearThen1 = parseInt(mdate2[2]);
            var monthThen1 = parseInt(mdate2[1]);
            var dayThen1 = parseInt(mdate2[0]);
            var yearThen3 = parseInt(mdate3[2]);
            var monthThen3 = parseInt(mdate3[1]);
            var dayThen3 = parseInt(mdate3[0]);
            var yearThen4 = parseInt(mdate4[2]);
            var monthThen4 = parseInt(mdate4[1]);
            var dayThen4 = parseInt(mdate4[0]);
            var today = new window.Date(yearThen1, monthThen1-1, dayThen1);
            var birthday = new window.Date(yearThen, monthThen-1, dayThen);
            var today1 = new window.Date(yearThen4, monthThen4-1, dayThen4);
            var birthday1 = new window.Date(yearThen3, monthThen3-1, dayThen3);
            var differenceInMilisecond1= today.valueOf() - birthday.valueOf();
            var differenceInMilisecond2 = today1.valueOf() - birthday1.valueOf();
            var differenceInMilisecond=differenceInMilisecond1+differenceInMilisecond2;
            var year_age = Math.floor(differenceInMilisecond / 31536000000);
            var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
            var month_age = Math.floor(day_age/30);
            day_age = day_age % 30;
            if (isNaN(year_age) || isNaN(month_age) || isNaN(day_age)|| differenceInMilisecond<=0) {
                alert("Experience Days Must be Grater then Zero");
            }
            else {
                var xprnc_mnth=month_age+(year_age*12);
                $('.lbl_xprnc').html(xprnc_mnth+ " months ");
               // var age_dob= year_age + " years " + month_age + " months " + day_age + " days";
                //alert(age_dob);
            }
        } 
        function checkdate(dt,mn,yy){ 
            var c_date = new window.Date(); 
            if(dt!="0"&&mn!="0"&&yy!="0"){
                var f_date = new window.Date(yy, mn-1, dt);
                if(c_date<f_date){
                    alert('Selected date must be less then or equal to current date!'); 
                    return true;
                }
            }
            return false;
        }
        function getDOB(){
            let  date_xprnc=$('.date_xprnc').val();
            let month_xprnc=$('.month_xprnc').val();
            let  year_xprnc=$('.year_xprnc').val();
            let  date_xprnc2=$('.date_xprnc2').val();
            let month_xprnc2=$('.month_xprnc2').val();
            let  year_xprnc2=$('.year_xprnc2').val();
            if(checkdate(date_xprnc2,month_xprnc2,year_xprnc2)){
                    $('.date_xprnc2').val('0');
                    $('.month_xprnc2').val('0');
                    $('.year_xprnc2').val('0');
            }
            if(checkdate(date_xprnc,month_xprnc,year_xprnc)){ 
                    $('.date_xprnc').val('0');
                    $('.month_xprnc').val('0');
                    $('.year_xprnc').val('0');
            }
            
            if(date_xprnc!="0"&&month_xprnc!="0"&&year_xprnc!="0"&&date_xprnc2!="0"&&month_xprnc2!="0"&&year_xprnc2!="0"){ 
                    var xprnc_date=date_xprnc+'-'+month_xprnc+'-'+year_xprnc+'to'+date_xprnc2+'-'+month_xprnc2+'-'+year_xprnc2;             
                    var xprnc_dat=date_xprnc+'-'+month_xprnc+'-'+year_xprnc+' to '+date_xprnc2+'-'+month_xprnc2+'-'+year_xprnc2;             
                    formdata.append("service_period", xprnc_dat);
                    getAge(xprnc_date);
            }
           
            let  date2_xprnc=$('.date2_xprnc').val();
            let month2_xprnc=$('.month2_xprnc').val();
            let  year2_xprnc=$('.year2_xprnc').val();
            let  date2_xprnc2=$('.date2_xprnc2').val();
            let month2_xprnc2=$('.month2_xprnc2').val();
            let  year2_xprnc2=$('.year2_xprnc2').val();
            if(checkdate(date2_xprnc2,month2_xprnc2,year2_xprnc2)){
                    $('.date2_xprnc2').val('0');
                    $('.month2_xprnc2').val('0');
                    $('.year2_xprnc2').val('0');
            }
            if(checkdate(date2_xprnc,month2_xprnc,year2_xprnc)){ 
                    $('.date2_xprnc').val('0');
                    $('.month2_xprnc').val('0');
                    $('.year2_xprnc').val('0');
            }
            let  date3_xprnc= $('.date3_xprnc').val();
            let month3_xprnc=$('.month3_xprnc').val();
            let  year3_xprnc= $('.year3_xprnc').val();
            let  date3_xprnc2=$('.date3_xprnc2').val();
            let month3_xprnc2=$('.month3_xprnc2').val();
            let  year3_xprnc2= $('.year3_xprnc2').val();
            
            let  date4_xprnc=  $('.date4_xprnc').val();
            let month4_xprnc= $('.month4_xprnc').val();
            let  year4_xprnc=  $('.year4_xprnc').val();
            let  date4_xprnc2= $('.date4_xprnc2').val();
            let month4_xprnc2=$('.month4_xprnc2').val();
            let  year4_xprnc2= $('.year4_xprnc2').val();
            if(date2_xprnc!="0"&&month2_xprnc!="0"&&year2_xprnc!="0"&&date2_xprnc2!="0"&&month2_xprnc2!="0"&&year2_xprnc2!="0"){
                var xprnc_date2=date2_xprnc+'-'+month2_xprnc+'-'+year2_xprnc+'to'+date2_xprnc2+'-'+month2_xprnc2+'-'+year2_xprnc2;             
                var xprnc_date=date2_xprnc+'-'+month2_xprnc+'-'+year2_xprnc+' to '+date2_xprnc2+'-'+month2_xprnc2+'-'+year2_xprnc2;             
                formdata.append("service_period2", xprnc_date);
                getAge2(xprnc_date2,2);
            }
            if(date3_xprnc!="0"&&month3_xprnc!="0"&&year3_xprnc!="0"&&date3_xprnc2!="0"&&month3_xprnc2!="0"&&year3_xprnc2!="0"){
                var xprnc_date2=date3_xprnc+'-'+month3_xprnc+'-'+year3_xprnc+'to'+date3_xprnc2+'-'+month3_xprnc2+'-'+year3_xprnc2;             
                var xprnc_date=date3_xprnc+'-'+month3_xprnc+'-'+year3_xprnc+' to '+date3_xprnc2+'-'+month3_xprnc2+'-'+year3_xprnc2;             
                formdata.append("service_period3", xprnc_date);
                getAge2(xprnc_date2,3);
            }
            if(date4_xprnc!="0"&&month4_xprnc!="0"&&year4_xprnc!="0"&&date4_xprnc2!="0"&&month4_xprnc2!="0"&&year4_xprnc2!="0"){
                var xprnc_date2=date4_xprnc+'-'+month4_xprnc+'-'+year4_xprnc+'to'+date4_xprnc2+'-'+month4_xprnc2+'-'+year4_xprnc2;             
                var xprnc_date=date4_xprnc+'-'+month4_xprnc+'-'+year4_xprnc+' to '+date4_xprnc2+'-'+month4_xprnc2+'-'+year4_xprnc2;             
                formdata.append("service_period4", xprnc_date);
                getAge2(xprnc_date2,4);
            }
            if(date_xprnc!="0"&&month_xprnc!="0"&&year_xprnc!="0"&&date_xprnc2!="0"&&month_xprnc2!="0"&&year_xprnc2!="0"&& date2_xprnc!="0"&&month2_xprnc!="0"&&year2_xprnc!="0"&&date2_xprnc2!="0"&&month2_xprnc2!="0"&&year2_xprnc2!="0"){
                var xprnc_date=date_xprnc+'-'+month_xprnc+'-'+year_xprnc+'to'+date_xprnc2+'-'+month_xprnc2+'-'+year_xprnc2;   
                var xprnc_date2=date2_xprnc+'-'+month2_xprnc+'-'+year2_xprnc+'to'+date2_xprnc2+'-'+month2_xprnc2+'-'+year2_xprnc2;          
                getTtlXPrnc(xprnc_date,xprnc_date2);
            }
            let  date_10=$('.date_10').val();
            let month_10=$('.month_10').val();
            let  year_10=$('.year_10').val();
            if(checkdate(date_10,month_10,year_10)){
                    $('.date_10').val('0');
                    $('.month_10').val('0');
                    $('.year_10').val('0');
            } 
            // if(date_10!="0"&&month_10!="0"&&year_10!="0"){
            //     var dob123=date_10+'-'+month_10+'-'+year_10;             
            //     formdata.append("passing_tenth", dob123);
            // }
            let date_graduation=$('.date_graduation').val();
            let month_graduation=$('.month_graduation').val();
            let year_graduation=$('.year_graduation').val();
            let date_diploma=$('.date_diploma').val();
            let month_diploma=$('.month_diploma').val();
            let year_diploma=$('.year_diploma').val();
            let date_pg=$('.date_pg').val();
            let month_pg=$('.month_pg').val();
            let year_pg=$('.year_pg').val();
            let date_other=$('.date_other').val();
            let month_other=$('.month_other').val();
            let year_other=$('.year_other').val();
            if(checkdate(date_graduation,month_graduation,year_graduation)){
                    $('.date_graduation').val('0');
                    $('.month_graduation').val('0');
                    $('.year_graduation').val('0');
            } 
            if(checkdate(date_diploma,month_diploma,year_diploma)){
                    $('.date_diploma').val('0');
                    $('.month_diploma').val('0');
                    $('.year_diploma').val('0');
            } 
            if(checkdate(date_other,month_other,year_other)){
                    $('.date_other').val('0');
                    $('.month_other').val('0');
                    $('.year_other').val('0');
            } 
            // if(date_graduation!="0"&&month_graduation!="0"&&year_graduation!="0"){
            //     var dob123=date_graduation+'-'+month_graduation+'-'+year_graduation;             
            //     formdata.append("passing_graduation", dob123);
            // }
            // if(date_diploma!="0"&&month_diploma!="0"&&year_diploma!="0"){
            //     var dob123=date_diploma+'-'+month_diploma+'-'+year_diploma;             
            //     formdata.append("passing_diploma", dob123);
            // }
            // if(date_pg!="0"&&month_pg!="0"&&year_pg!="0"){
            //     var dob123=date_pg+'-'+month_pg+'-'+year_pg;             
            //     formdata.append("passing_pg", dob123);
            // }
            // if(date_other!="0"&&month_other!="0"&&year_other!="0"){
            //     var dob123=date_other+'-'+month_other+'-'+year_other;             
            //     formdata.append("passing_other", dob123);
            // }
          }
          $('#10th').change(function(){ 
            var img_size=(this.files[0].size)/1024;
            if(img_size>=20 && img_size<=50){
              //_this.CanImageChange(this);
              let file = this.files[0]
             
            }else{
              alert('Please Select Image With Size Between 20kb-50kb!');
              $('#tenth').val('');
            } 
          }); 
          $('#graduation').change(function(){ 
            var img_size=(this.files[0].size)/1024;
            if(img_size>=20 && img_size<=50){
              let file = this.files[0]
              //formdata.append("graduation", file);
            }else{
              alert('Please Select Image With Size Between 20kb-50kb!');
              $('#graduation').val('');
            } 
          }); 
          $('#diploma').change(function(){ 
            var img_size=(this.files[0].size)/1024;
            if(img_size>=20 && img_size<=50){
              let file = this.files[0]
              //formdata.append("diploma", file);
            }else{
              alert('Please Select Image With Size Between 20kb-50kb!');
              $('#diploma').val('');
            } 
          }); 
          $('#pg').change(function(){ 
            var img_size=(this.files[0].size)/1024;
            if(img_size>=20 && img_size<=50){
              let file = this.files[0]
            //  formdata.append("pg", file);
            }else{
              alert('Please Select Image With Size Between 20kb-50kb!');
              $('#pg').val('');
            } 
          }); 
          $('#other').change(function(){ 
            var img_size=(this.files[0].size)/1024;
            if(img_size>=20 && img_size<=50){
              let file = this.files[0]
              //formdata.append("other", file);
            }else{
              alert('Please Select Image With Size Between 20kb-50kb!');
              $('#other').val('');
            } 
          }); 
          $('#xprnc2').change(function(){ 
            var img_size=(this.files[0].size)/1024;
            if(img_size>=20 && img_size<=50){
              let file = this.files[0]
              //formdata.append("xprnc2", file);
            }else{
              alert('Please Select Image With Size Between 20kb-50kb!');
              $('#xprnc2').val('');
            } 
          }); 
          $('#xprnc3').change(function(){ 
            var img_size=(this.files[0].size)/1024;
            if(img_size>=20 && img_size<=50){
              let file = this.files[0]
              //formdata.append("xprnc3", file);
            }else{
              alert('Please Select Image With Size Between 20kb-50kb!');
              $('#xprnc3').val('');
            } 
          }); 
          $('#xprnc4').change(function(){ 
            var img_size=(this.files[0].size)/1024;
            if(img_size>=20 && img_size<=50){
              let file = this.files[0]
             // formdata.append("xprnc4", file);
            }else{
              alert('Please Select Image With Size Between 20kb-50kb!');
              $('#xprnc4').val('');
            } 
          }); 
          $('#xprnc').change(function(){ 
            var img_size=(this.files[0].size)/1024;
            if(img_size>=20 && img_size<=50){
              let file = this.files[0]
             // formdata.append("xprnc", file);
            }else{
              alert('Please Select Image With Size Between 20kb-50kb!');
              $('#xprnc').val('');
            } 
          }); 
        $('.ddlexperience1').change(function(){
            if($(this).val()=='-1'){
                $('#nature_duties1').removeClass('display_none');
            }else{
                $('#nature_duties1').addClass('display_none');
               // formdata.append("nature_duties", $(this).val());
        }
        });
        $('.ddlexperience2').change(function(){
            if($(this).val()=='-1'){
                $('#nature_duties2').removeClass('display_none');
            }else{
                $('#nature_duties2').addClass('display_none');
               // formdata.append("nature_duties2", $(this).val());
        }
        });
        $('.ddlexperience3').change(function(){
            if($(this).val()=='-1'){
                $('#nature_duties3').removeClass('display_none');
            }else{
                $('#nature_duties3').addClass('display_none');
                //formdata.append("nature_duties3", $(this).val());
        }
        });
        $('.ddlexperience4').change(function(){
            if($(this).val()=='-1'){
                $('#nature_duties4').removeClass('display_none');
            }else{
                $('#nature_duties4').addClass('display_none');
                //formdata.append("nature_duties4", $(this).val());
        }
        });
        
        $('select[name=diploma_degree]').change(function () {
            diploma_select($(this).val());
        });
        function diploma_select(val) {
            if (val === "4")
                $('#ddegree').removeClass('display_none');
            else
                $('#ddegree').addClass('display_none');
        }
        $('select[name=diploma_sdegree]').change(function () {
            diploma_s_select($(this).val());
        });
        function diploma_s_select(val) {
            if (val === "7")
                $('#dsdegree').removeClass('display_none');
            else
                $('#dsdegree').addClass('display_none');
        }

        $('select[name=GDegree]').change(function () {
            GDegree_data($(this).val());
        });
        function GDegree_data(val) {
            if (val === "9"){
                $('#gdegree').removeClass('display_none');

              }
              else{
                $('.g_crtie').removeClass('display_none');
                $('#gdegree').addClass('display_none');
            }
            }

        $('select[name=GDegree]').change(function () {
            var deg=$('.GDegree').val();
            var sub=$('.Gsubject').val(); 
            fdsfsdf(deg,sub);
        });
         $('.Gsubject').change(function () { 
            var deg=$('.GDegree').val();
            var sub=$('.Gsubject').val(); 
            fdsfsdf(deg,sub);
        });
        function fdsfsdf(deg,sub) { 
            debugger;
            if( deg ==="3" &&  sub ==="3"){
                $('.g_crtie').addClass('display_none');
            }
            else if( deg ==="1" &&  sub ==="3"){
                $('.g_crtie').addClass('display_none');
            }
            else if( deg ==="2" &&  sub ==="3"){
                $('.g_crtie').addClass('display_none');
            }
            else if( deg ==="5" &&  sub ==="3"){
                $('.g_crtie').addClass('display_none');
            }
            else
            {
                $('.g_crtie').removeClass('display_none');
                $('#Gsubject').addClass('display_none');
            }
        }

        $('select[name=Gsubject]').change(function () {
            Gsubject_data($(this).val());
        });
        function Gsubject_data(val) {
            if (val === "8")
                $('#gsubject').removeClass('display_none');
            else
                $('#gsubject').addClass('display_none');
        }

        $('select[name=PSubject]').change(function () {
            PSubject_data($(this).val());
        });
        function PSubject_data(val) {
            if (val === "7")
                $('#PSubject_id').removeClass('display_none');
            else
                $('#PSubject_id').addClass('display_none');
        }
        $('select[name=PDegree]').change(function () {
            PDegree_data($(this).val());
        });
        function PDegree_data(val) {
            if (val === "6")
                $('#PDegree_id').removeClass('display_none');
            else
                $('#PDegree_id').addClass('display_none');
        }

        $('input[type=radio][name=bDisablility]').change(function () {
            if ($(this).val() == '1') {
                $('.dsblty').show();
                $('#dsblty').show();
            } else {
                $('.dsblty').hide();
                $('#dsblty').hide();
                $('#dsblty').val('5');
            }
        });

        $('input[type=radio][name=iComputerGroup]').change(function () {
            if ($(this).val() == '1') {
               
                $('#d_cmpt_knwlg').removeClass('display_none');
              
            } else {
                $('#d_cmpt_knwlg').addClass('display_none');
            }
        });
         
    }

    submit = (CurStep) => {
        this.props.submit(CurStep);
      }
      back = (CurStep) => {
        this.props.back(CurStep);
      }


    handleChange = (e, { value }) => this.setState({ value })

    render() {
        const { QualificationList, data, FormData, errors,loading,dataq,data_xprnc } = this.state
             if(data.iComputerGroup==false){data.iComputerGroup=0;
             }else{data.iComputerGroup=1;}
             if(data.iQualNote==false){data.iQualNote=0;
             }else{data.iQualNote=1;}
             if(data.iEligibleGroup==false){data.iEligibleGroup=0;
             }else{data.iEligibleGroup=1;}
             if(data.iWorkGroup==false){data.iWorkGroup=0;
             }else{data.iWorkGroup=1;}
             $('#my_lang').val(data.nvc_language);
             $('#my_lang2').val(data.nvc_language2);
            // alert(data.bt_language_write);
             $("input[name=lng_read][value='" + data.bt_language_read + "']").prop('checked', true).trigger('change');
             $("input[name=lng_write][value='" + data.bt_language_write + "']").prop('checked', true).trigger('change');
             $("input[name=lng_speak][value='" + data.bt_language_speak + "']").prop('checked', true).trigger('change');
             $("input[name=lng2_read][value='" + data.bt_language2_read + "']").prop('checked', true).trigger('change');
             $("input[name=lng2_write][value='" + data.bt_language2_write + "']").prop('checked', true).trigger('change');
             $("input[name=lng2_speak][value='" + data.bt_language2_speak + "']").prop('checked', true).trigger('change');
        $('input[type=radio][name=iComputerGroup]').val(data.iComputerGroup).trigger('change');
        $('input[type=radio][name=iQualNote]').val(data.iQualNote).click();
        $('input[type=radio][name=iEligibleGroup123]').val(data.iEligibleGroup).click();
        $('input[type=radio][name=iWorkGroup]').val(data.iWorkGroup).trigger('change');
        // if(data.iEligibleGroup=='1'){
        //     $("#elg_yes").removeClass("display_none");
        //     $("#elg_no").addClass("display_none");
        //     $('.g_gegree').removeClass("display_none");
        // } else if(data.iEligibleGroup=='0'){
        //     $("#elg_yes").addClass("display_none");
        //     $("#elg_no").removeClass("display_none");
        //     $('.g_gegree').addClass("display_none");
        // }else{
        //     $("#elg_yes").addClass("display_none");
        //     $("#elg_no").addClass("display_none");
        //     $('.g_gegree').removeClass("display_none");
        // }
        // if(data.iQualNote=='1'){ 
        //     $('.post_qulf').removeClass("display_none");
        // }  else{ 
        //     $('.post_qulf').addClass("display_none");
        // }
        var k=0;
        for(var i in data_xprnc){ 
           // debugger;
            k++;
            var vc_date_passing=data_xprnc[i].nvc_service_period.replace(' ','').split('to');
            var date1=vc_date_passing[0].split('-');
            var date2=vc_date_passing[1].split('-');
            if(i==0){
                $('.employer_name').val(data_xprnc[i].nvc_employer_name);
                $('.designation').val(data_xprnc[i].nvc_designation);
                if(data_xprnc[i].nvc_nature_duties=='AMOS'||data_xprnc[i].nvc_nature_duties=='RAMCO' || data_xprnc[i].nvc_nature_duties=='SAP' || data_xprnc[i].nvc_nature_duties=='TRAX' ){
                    $('.ddlexperience'+k).val(data_xprnc[i].nvc_nature_duties);
                }else{
                    $('.ddlexperience'+k).val('-1');
                    $('.nature_duties'+k).val(data_xprnc[i].nvc_nature_duties);
                    $('.nature_duties'+k).removeClass('display_none');
                } 
                $('#year1'+i).val(date1[2]);
                $('#month1'+i).val(date1[1]);
                $('#date1'+i).val(date1[0]); 
                $('#year2'+i).val(date2[2]);
                $('#month2'+i).val(date2[1]);
                $('#date2'+i).val(parseInt(date2[0])); 
                $('#spn_xprnc'+i).html("<a class='example-image-link' href='"+ServerUrl +""+ data_xprnc[i].nvc_document+"' data-lightbox='qlfctn'><img height='50px' src='"+ServerUrl +""+ data_xprnc[i].nvc_document+"' ></img></a>");
            }else{
                $('.employer_name'+k).val(data_xprnc[i].nvc_employer_name);
                $('.designation'+k).val(data_xprnc[i].nvc_designation);
                if(data_xprnc[i].nvc_nature_duties=='AMOS'||data_xprnc[i].nvc_nature_duties=='RAMCO' || data_xprnc[i].nvc_nature_duties=='SAP' || data_xprnc[i].nvc_nature_duties=='TRAX' ){
                    $('.ddlexperience'+k).val(data_xprnc[i].nvc_nature_duties);
                }else{
                    $('.ddlexperience'+k).val('-1');
                    $('.nature_duties'+k).val(data_xprnc[i].nvc_nature_duties);
                    $('.nature_duties'+k).removeClass('display_none');
                } 
                $('.date'+k+'_xprnc').val(date1[0]);
                $('.month'+k+'_xprnc').val(date1[1]);
                $('.year'+k+'_xprnc').val(date1[2]); 
                $('.year'+k+'_xprnc2').val(date2[2]);
                $('.month'+k+'_xprnc2').val(date2[1]);
                $('.date'+k+'_xprnc2').val(parseInt(date2[0])); 
                $('#spn_xprnc'+k).html("<img height='50px' src='"+ServerUrl +""+ data_xprnc[i].nvc_document+"' ></img>");
            }
        }
        for(var i in dataq){ 
            if(dataq[i].vc_qualification_id=='10th'){
                $('#subject_stream_tenth').val(dataq[i].vc_subject_stream);
                $('.board_tenth').val(dataq[i].vc_university_college_board);
                var vc_date_passing=dataq[i].vc_date_passing.split('-');
                $('.year_10').val(vc_date_passing[2]);
                $('.month_10').val(vc_date_passing[1]);
                $('.date_10').val(vc_date_passing[0]); 
                $('.percentage_tenth').val(dataq[i].dc_percentage);
                $('#spn_tenth').html("<img height='50px' src='"+ServerUrl +""+ dataq[i].vc_document+"' ></img>");
            } else
            if(dataq[i].vc_qualification_id=='Graduation'){
                if(dataq[i].vc_degree_id=="9"){
                    $('#gdegree').removeClass('display_none');
                    $('#gdegree').val(dataq[i].vc_degree_id);
                }else{
                    $('.GDegree').val(dataq[i].vc_degree_id);
                }
                if(dataq[i].vc_subject_stream=="8"){
                    $('#gsubject').removeClass('display_none');
                    $('#gsubject').val(dataq[i].vc_subject_stream);
                }else{
                    $('.Gsubject').val(dataq[i].vc_subject_stream);
                } 
                var vc_date_passing=dataq[i].vc_date_passing.split('-');
                $('.board_graduation').val(dataq[i].vc_university_college_board);
                $('.percentage_graduation').val(dataq[i].dc_percentage);
                $('.year_graduation').val(vc_date_passing[2]);
                $('.month_graduation').val(vc_date_passing[1]);
                $('.date_graduation').val(vc_date_passing[0]); 
                $('#spn_graduation').html("<img height='50px' src='"+ServerUrl +""+ dataq[i].vc_document+"' ></img>");
            } else
            if(dataq[i].vc_qualification_id=='Diploma'){
                if(dataq[i].vc_subject_stream=="8"){
                    $('#dsdegree').removeClass('display_none');
                    $('#dsdegree').val(dataq[i].vc_subject_stream);
                }else{
                    $('.diploma_sdegree').val(dataq[i].vc_subject_stream);
                } 
                var vc_date_passing=dataq[i].vc_date_passing.split('-');
                $('.board_diploma').val(dataq[i].vc_university_college_board);
                $('.percentage_diploma').val(dataq[i].dc_percentage);
                $('.year_diploma').val(vc_date_passing[2]);
                $('.month_diploma').val(vc_date_passing[1]);
                $('.date_diploma').val(vc_date_passing[0]); 
                $('#spn_diploma').html("<img height='50px' src='"+ServerUrl +""+ dataq[i].vc_document+"' ></img>");
           
            } else
            if(dataq[i].vc_qualification_id=='Post Graduation'){
                if(dataq[i].vc_subject_stream=="8"){
                    $('#dsdegree').removeClass('display_none');
                    $('#dsdegree').val(dataq[i].vc_subject_stream);
                }else{
                    $('.post_graduadtion').val(dataq[i].vc_subject_stream);
                } 
                var vc_date_passing=dataq[i].vc_date_passing.split('-');
                $('.board_pg').val(dataq[i].vc_university_college_board);
                $('.percentage_pg').val(dataq[i].dc_percentage);
                $('.year_pg').val(vc_date_passing[2]);
                $('.month_pg').val(vc_date_passing[1]);
                $('.date_pg').val(vc_date_passing[0]); 
                $('#spn_pg').html("<img height='50px' src='"+ServerUrl +""+ dataq[i].vc_document+"' ></img>");
            } else{ 
                $('.qlfctn_other').val(dataq[i].vc_qualification_id);
                $('.degree_other').val(dataq[i].vc_degree_id);
                $('.subject_stream_other').val(dataq[i].vc_subject_stream);
                $('.board_other').val(dataq[i].vc_university_college_board);
                $('.percentage_other').val(dataq[i].dc_percentage);
                var vc_date_passing=dataq[i].vc_date_passing.split('-');
                $('.year_other').val(vc_date_passing[2]);
                $('.month_other').val(vc_date_passing[1]);
                $('.date_other').val(vc_date_passing[0]); 
                $('#spn_other').html("<img height='50px' src='"+ServerUrl +""+ dataq[i].vc_document+"' ></img>");
            } 
        }
        return (
            <div>

                <Form className="container"  ref="form"
                   onSubmit={this.SaveData}>
                    <Grid >
                        <Grid.Column floated='left' width={16}>
                            <Segment inverted color='blue' >
                                <Header as='h5' >
                                    <Header.Content >Education Qualification</Header.Content>
                                </Header>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column floated='left' width={16}>
                            <p>Note: The date of passing the eligibility exam will be the date appearing on the marksheet or provisional certificate issued by University/Institute. In case the result of a particular examination is posted on the website of the University/Institute then a certificate issued by the appropriate authority of the University/Institute indicating the date on which the result was posted on the website will be taken as proof of date of passing. </p>
                        </Grid.Column>

                        <Grid.Column floated='left' mobile={16} tablet={8} computer={8}>
                            <label>Eligibility Criteria :</label>
                            <span class="colorblr"> * </span>
                        </Grid.Column>
                        <Grid.Column floated='right' mobile={16} tablet={8} computer={8}>
                            <Grid divided='vertically'>
                                <Grid columns={4}>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={3}>
                                        <Form.Field>
                                            <Radio
                                                label='A'
                                                name='iEligibleGroup123' 
                                                className='iEligibleGroup123'
                                                onClick={this.EligibleGroupjs}
                                                value='1'
                                                checked={this.state.FormData.iEligibleGroup == '1'}
                                                onChange={this.EligibleGroup}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={2}>
                                        <Form.Field>
                                            <Radio
                                                label='B'
                                                name='iEligibleGroup123' 
                                                className='iEligibleGroup123'
                                                onClick={this.EligibleGroupjs}
                                                value='0'
                                                checked={this.state.FormData.iEligibleGroup == '0'}
                                                onChange={this.EligibleGroup} 
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    {errors.iEligibleGroup123 && <Label basic color='red' pointing>{errors.iEligibleGroup123}</Label>}
                                </Grid>
                            </Grid>
                        </Grid.Column>

                        <Grid.Column floated='left' mobile={16} tablet={8} computer={8}>
                            <label>Do you have post-graduation Degree/ Diploma (not less than 01-year duration) in HR/ Finance/MMD /Library Science/ Stenography :</label>
                            <span class="colorblr"> * </span>
                        </Grid.Column>
                        <Grid.Column floated='right' mobile={16} tablet={8} computer={8}>
                            <Grid divided='vertically'>
                                <Grid columns={4}>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={3}>
                                        <Form.Field>
                                            <Radio
                                                label='Yes'
                                                name='iQualNote'
                                                className='iQualNote'
                                                onClick={this.QualNotejs}
                                                value='1' 
                                                checked={this.state.FormData.iQualNote == '1'}
                                                onChange={this.QualNote}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={6}>
                                        <Form.Field>
                                            <Radio
                                                label='No'
                                                name='iQualNote'
                                                className='iQualNote'
                                                onClick={this.QualNotejs}
                                                value='0'
                                                checked={this.state.FormData.iQualNote == '0'}
                                                onChange={this.QualNote} 
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    {errors.iQualNote && <Label basic color='red' pointing>{errors.iQualNote}</Label>}
                                </Grid>
                            </Grid>
                        </Grid.Column>

                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Exam Passed</Table.HeaderCell>
                                    <Table.HeaderCell>Degree</Table.HeaderCell>
                                    <Table.HeaderCell>Subject/Stream</Table.HeaderCell>
                                    <Table.HeaderCell>University / College / Board</Table.HeaderCell>
                                    <Table.HeaderCell>Year of Passing</Table.HeaderCell>
                                    <Table.HeaderCell>% of Marks</Table.HeaderCell>
                                    <Table.HeaderCell>Upload Proof of Qualification</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row >
                                    <Table.Cell>10th<span class="colorblr"> * </span></Table.Cell>
                                    <Table.Cell> </Table.Cell>
                                    <Table.Cell> 
                                            <input type="text" name="tenth_sub" id='subject_stream_tenth' class="subject_stream_tenth"/>
                                        {errors.tenth_sub && <Label basic color='red' pointing>{errors.tenth_sub}</Label>}
                                    </Table.Cell>
                                    <Table.Cell>  <input type="text" name="clg_name"  class="board_tenth" />{errors.clg_name && <Label basic color='red' pointing>{errors.clg_name}</Label>}</Table.Cell>
                                    <Table.Cell> <Menu size='compact' className="w_250">
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date_10'  control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.date_valid && <Label basic color='red' pointing>{errors.date_valid}</Label>}   
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'    class='month_10'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.date_valid && <Label basic color='red' pointing>{errors.date_valid}</Label>}   
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year_10'    control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select> 
                                {errors.date_valid && <Label basic color='red' pointing>{errors.date_valid}</Label>}        
                                </Grid.Column>      
                                    </Menu>
                                    </Table.Cell>
                                    <Table.Cell> <input type="number" name="prcntg_st" size='massive'  class='cls_marks percentage_tenth'/>{errors.prcntg_st && <Label basic color='red' pointing>{errors.prcntg_st}</Label>}</Table.Cell>
                                    <Table.Cell><div class='row'><span id='spn_tenth'></span><input type="file" name="stdocumnt" class="inputfile" id="10th"/>{errors.stdocumnt && <Label basic color='red' pointing>{errors.stdocumnt}</Label>} </div></Table.Cell>
                                </Table.Row>
                                <Table.Row className="g_gegree">
                                    <Table.Cell>Graduation<span class="colorblr"> * </span></Table.Cell>
                                    <Table.Cell>
                                        <select class='GDegree' name="GDegree">
                                            <option value="0">Select</option>
                                            <option value="B.E.">B.E.</option>
                                            <option value="B.Tech.">B.Tech.</option>
                                            <option value="BCA">BCA</option>
                                            <option value="BBA">BBA</option>
                                            <option value="B.Sc">B.Sc</option>
                                            <option value="B.Com">B.Com</option>
                                            <option value="B.A.">B.A.</option>
                                            <option value="9">Other</option>
                                        </select>
                                        <input type="text" name="gdegree" id="gdegree" class="display_none degree_graduation" />
                                        {errors.gdegree && <Label basic color='red' pointing>{errors.gdegree}</Label>}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <select class='Gsubject' name="Gsubject">
                                            <option value="0">Select</option>
                                            <option value="Arts">Arts</option>
                                            <option value="Science">Science</option>
                                            <option value="IT">IT</option>
                                            <option value="Commerce">Commerce</option>
                                            <option value="Law">Law</option>
                                            <option value="Management">Management</option>
                                            <option value="Engineering">Engineering</option>
                                            <option value="8">Other</option>
                                        </select>
                                        <input type="text" name="gsubject" id="gsubject" class="display_none subject_stream_graduation" />
                                        {errors.ssubjct && <Label basic color='red' pointing>{errors.ssubjct}</Label>}
                                    </Table.Cell>
                                    <Table.Cell>  <input type="text" 
                                    name="gunivrsy" class="board_graduation" />{errors.gunivrsy && <Label basic color='red' pointing>{errors.gunivrsy}</Label>}</Table.Cell>
                                    <Table.Cell> <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date_graduation'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.date1_valid && <Label basic color='red' pointing>{errors.date1_valid}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month_graduation'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.date1_valid && <Label basic color='red' pointing>{errors.date1_valid}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year_graduation'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.date1_valid && <Label basic color='red' pointing>{errors.date1_valid}</Label>}
                                </Grid.Column>
                                    </Menu>
                                    </Table.Cell>
                                    <Table.Cell> <input type="number" 
                                    name="gprcnt_mark"
                                    size='massive' class='cls_marks percentage_graduation'/>{errors.gprcnt_mark && <Label basic color='red' pointing>{errors.gprcnt_mark}</Label>}</Table.Cell>
                                    <Table.Cell><span id='spn_graduation'></span><input type="file" class="inputfile" id="graduation" />{errors.gfile_upload && <Label basic color='red' pointing>{errors.gfile_upload}</Label>} </Table.Cell>
                                  
                                </Table.Row>
                                
                                <Table.Row className="g_crtie">
                                    <Table.Cell>Diploma/Certificate <span class="colorblr"> * </span></Table.Cell>
                                    <Table.Cell>
                                        </Table.Cell>
                                    <Table.Cell> 
                                <Form.Select fluid placeholder='State' name='Month'  class='diploma_sdegree'   control='select'  > 
                                            <option value="0">Select</option>
                                             
                                    {_.map(SCmptknwg, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}</Form.Select> 
                                        <input type="text" name="dsdegree" id="dsdegree" class="display_none subject_stream_diploma" />
                                        {errors.subject_stream_diploma && <Label basic color='red' pointing>{errors.subject_stream_diploma}</Label>}
                                    </Table.Cell>
                                    <Table.Cell>  <input type="text"
                                    name="dunivrsy" class="board_diploma"/>  {errors.dunivrsy && <Label basic color='red' pointing>{errors.dunivrsy}</Label>}</Table.Cell>
                                    <Table.Cell> <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date_diploma'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>     {errors.date_diplo && <Label basic color='red' pointing>{errors.date_diplo}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month_diploma'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>{errors.date_diplo && <Label basic color='red' pointing>{errors.date_diplo}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year_diploma'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>{errors.date_diplo && <Label basic color='red' pointing>{errors.date_diplo}</Label>}
                                </Grid.Column>
                                    </Menu>
                                    </Table.Cell>
                                    <Table.Cell> <input type="number" 
                                    name="dprcnt_mark"
                                    size='massive'  class="cls_marks percentage_diploma" />  {errors.dprcnt_mark && <Label basic color='red' pointing>{errors.dprcnt_mark}</Label>}</Table.Cell>
                                    <Table.Cell><span id='spn_diploma'></span><input type="file" class="inputfile" id="diploma" />  {errors.diploma && <Label basic color='red' pointing>{errors.diploma}</Label>} </Table.Cell>
                                </Table.Row>
                                
                                <Table.Row className="post_qulf">
                                    <Table.Cell>Post Graduation <span class="colorblr"> * </span></Table.Cell>
                                    <Table.Cell>
                                        </Table.Cell>
                                    <Table.Cell> 
                                <Form.Select fluid placeholder='State' name='post_graduadtion'  class='post_graduadtion'   control='select'  > 
                                            <option value="0">Select</option>
                                             
                                    {_.map(PostGraduation, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}</Form.Select> 
                                        <input type="text" name="dsdegree" id="dsdegree" class="display_none txt_post_graduadtion" />
                                        {errors.post_graduadtion && <Label basic color='red' pointing>{errors.post_graduadtion}</Label>}
                                    </Table.Cell>
                                    <Table.Cell>  <input type="text"
                                    name="board_pg" class="board_pg"/>  {errors.board_pg && <Label basic color='red' pointing>{errors.board_pg}</Label>}</Table.Cell>
                                    <Table.Cell> <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date_pg'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>     {errors.date_pg && <Label basic color='red' pointing>{errors.date_pg}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month_pg'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>{errors.month_pg && <Label basic color='red' pointing>{errors.month_pg}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year_pg'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>{errors.year_pg && <Label basic color='red' pointing>{errors.year_pg}</Label>}
                                </Grid.Column>
                                    </Menu>
                                    </Table.Cell>
                                    <Table.Cell> <input type="number" 
                                    name="percentage_pg"
                                    size='massive'  class="cls_marks percentage_pg" />  {errors.percentage_pg && <Label basic color='red' pointing>{errors.percentage_pg}</Label>}</Table.Cell>
                                    <Table.Cell><span id='spn_pg'></span><input type="file" class="inputfile" id="pg" />  {errors.pg && <Label basic color='red' pointing>{errors.pg}</Label>} </Table.Cell>
                                </Table.Row>
                                
                                <Table.Row>
                                    <Table.Cell> <input type="text" placeholder="Other" size='massive' class="qlfctn_other"/></Table.Cell>
                                    <Table.Cell> <input type="text" size='massive' class="degree_other"/>{errors.degree_other && <Label basic color='red' pointing>{errors.degree_other}</Label>}</Table.Cell>
                                    <Table.Cell>  <input type="text" class="subject_stream_other"/>{errors.subject_stream_other && <Label basic color='red' pointing>{errors.subject_stream_other}</Label>}</Table.Cell>
                                    <Table.Cell><input type="text" class="board_other"/> </Table.Cell>
                                    <Table.Cell> <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date_other'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month_other'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year_other'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                    </Menu>
                                    </Table.Cell>
                                    <Table.Cell> <input type="number" size='massive' class="cls_marks percentage_other"/></Table.Cell>
                                    <Table.Cell><span id='spn_other'></span><input type="file" class="inputfile" id="other" />
                                    {errors.other && <Label basic color='red' pointing>{errors.other}</Label>}  </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                        <Grid.Column floated='left' mobile={16} tablet={8} computer={4}>
                            <label>Do you have knowledge of computer operations as per notification</label>
                            <span class="colorblr"> * </span>
                        </Grid.Column>
                        <Grid.Column floated='right' mobile={16} tablet={8} computer={11}>
                            <Grid divided='vertically'>
                                <Grid columns={12}>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={2}>
                                        <Form.Field>
                                            <Radio
                                                label='Yes'
                                                name='iComputerGroup'
                                                value='1'
                                                checked={this.state.FormData.iComputerGroup == '1'}
                                                onChange={this.ComputerGroup}

                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={6}>
                                        <Form.Field>
                                            <Radio
                                                label='No'
                                                name='iComputerGroup'
                                                value='0'
                                                checked={this.state.FormData.iComputerGroup == '0'}
                                                onChange={this.ComputerGroup}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    {errors.iComputerGroup && <Label basic color='red' pointing>{errors.iComputerGroup}</Label>}
                                </Grid>
                            </Grid>
                        </Grid.Column>
                         
                        <Grid.Column floated='left' width={16}>
                            <Segment inverted color='violet' >
                                <Header as='h5' >
                                    <Header.Content >Work Experience Details</Header.Content>
                                </Header>
                            </Segment>
                        </Grid.Column>

                        <Grid.Column floated='left' width={16}>
                        <div class="display_none" name="elg_yes" id="elg_yes"><p class="colorblr" >Minimum 01 year of post-qualification (after Graduation) work experience in data entry / computer applications / relevant trade in the Industry field in any organization of repute.</p></div>
                       <div class="display_none" name="elg_no" id="elg_no"><p class="colorblr">One-year post qualification working experience of Aviation related software (e.g. AMOS/RAMCO.TRAX etc.)</p></div>
                       </Grid.Column>
                       <Grid.Column floated='left' mobile={16} tablet={8} computer={12}>
                            <label>Do you have minimum 24 months of working experience in any Scheduled Airlines on Aviation related software (e.g. AMOS/RAMCO/TRAX etc.) or in SAP </label>
                            <span class="colorblr"> * </span>
                        </Grid.Column>
                        <Grid.Column floated='right' mobile={16} tablet={8} computer={4}>
                            <Grid divided='vertically'>
                                <Grid columns={4}>
                                    <Grid.Column>
                                        <Radio
                                            label='Yes'
                                            name='iWorkGroup'
                                            value='1'
                                            checked={this.state.FormData.iWorkGroup == '1'}
                                            onChange={this.WorkGroup}

                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Radio
                                            label='No'
                                            name='iWorkGroup'
                                            value='0'
                                            checked={this.state.FormData.iWorkGroup == '0'}
                                            onChange={this.WorkGroup}

                                        />
                                    </Grid.Column>
                                    {errors.iWorkGroup && <Label basic color='red' pointing>{errors.iWorkGroup}</Label>}
                                </Grid>
                            </Grid>
                        </Grid.Column>

                        <Table celled padded>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name of Employer</Table.HeaderCell>
                                    <Table.HeaderCell>Designation</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='2' textAlign='center' >Total Period of Service</Table.HeaderCell>
                                    <Table.HeaderCell className='w_125'>Nature of Duties</Table.HeaderCell>
                                    {/* <Table.HeaderCell>Reason of leaving</Table.HeaderCell> */}
                                    <Table.HeaderCell className='w_150'>Upload Proof of Employement</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='2'><Label basic color='red' pointing>Please enter information in reverse chronological order (starting from current)</Label></Table.HeaderCell>
                                    <Table.HeaderCell  textAlign='center' >From</Table.HeaderCell>
                                    <Table.HeaderCell  textAlign='center' >To</Table.HeaderCell>
                                    <Table.HeaderCell colSpan='2'></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell><input type='text' class='employer_name'/>
                                    {errors.employer_name && <Label basic color='red' pointing>{errors.employer_name}</Label>}</Table.Cell>
                                    <Table.Cell ><input type='text' class='designation' />
                                    {errors.designation && <Label basic color='red' pointing>{errors.designation}</Label>}</Table.Cell>
                                    <Table.Cell> 
                                        <Menu size='compact' className="w_250">
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' id='date10' name='Date' class='date_xprnc'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                     {errors.date_xprnc && <Label basic color='red' pointing>{errors.date_xprnc}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' id='month10' name='Month'  class='month_xprnc'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.month_xprnc && <Label basic color='red' pointing>{errors.month_xprnc}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' id='year10' name='Year' class='year_xprnc'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.year_xprnc && <Label basic color='red' pointing>{errors.year_xprnc}</Label>}
                                </Grid.Column>
                                    </Menu>
                                    </Table.Cell>
                                    
                                    <Table.Cell textAlign='right'>
                                    <Menu size='compact' className="w_250">
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' id='date20' name='Date' class='date_xprnc2'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.date_xprnc2 && <Label basic color='red' pointing>{errors.date_xprnc2}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' id='month20' name='Month'  class='month_xprnc2'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.month_xprnc2 && <Label basic color='red' pointing>{errors.month_xprnc2}</Label>}
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' id='year20' name='Year' class='year_xprnc2'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                {errors.year_xprnc2 && <Label basic color='red' pointing>{errors.year_xprnc2}</Label>}
                                </Grid.Column>
                                    </Menu>
                                    
                                    </Table.Cell>
                                    <Table.Cell>
                                        {/* <input type='text' class='nature_duties'  /> */}
                                        <select class='ddlexperience1' name="ddlexperience1">
                                            <option value="0">Select</option>
                                            <option value="AMOS">AMOS</option>
                                            <option value="RAMCO">RAMCO</option>
                                            <option value="TRAX">TRAX</option>
                                            <option value="SAP">SAP</option> 
                                            <option value="-1">Other</option>
                                        </select>
                                        <input type="text" name="nature_duties" id="nature_duties1" class="display_none nature_duties" />
                                        {errors.nature_duties && <Label basic color='red' pointing>{errors.nature_duties}</Label>}
                                        </Table.Cell>
                                    {/* <Table.Cell><input type='text' class='ofleaving_reason'  /></Table.Cell> */}
                                    <Table.Cell>
                                        <span id='spn_xprnc0'></span><input type='file' id='xprnc' />
                                    {errors.xprnc && <Label basic color='red' pointing>{errors.xprnc}</Label>}
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><input type='text' class='employer_name2'/></Table.Cell>
                                    <Table.Cell ><input type='text' class='designation2' /></Table.Cell>
                                    <Table.Cell> 
                                        <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date2_xprnc'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month2_xprnc'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year2_xprnc'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                    </Menu>
                                    </Table.Cell>
                                    
                                    <Table.Cell textAlign='right'>
                                    <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date2_xprnc2'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month2_xprnc2'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year2_xprnc2'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                    </Menu>
                                    
                                    </Table.Cell>
                                    <Table.Cell>
                                    <select class='ddlexperience2' name="ddlexperience2">
                                            <option value="0">Select</option>
                                            <option value="AMOS">AMOS</option>
                                            <option value="RAMCO">RAMCO</option>
                                            <option value="TRAX">TRAX</option>
                                            <option value="SAP">SAP</option> 
                                            <option value="-1">Other</option>
                                        </select>
                                        <input type="text" name="nature_duties2" id="nature_duties2" class="display_none nature_duties2" />
                                        {/* <input type='text' class='nature_duties2'  /> */}
                                        </Table.Cell>
                                    {/* <Table.Cell><input type='text' class='ofleaving_reason2'  /></Table.Cell> */}
                                    <Table.Cell><span id='spn_xprnc2'></span><input type='file' id='xprnc2' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><input type='text' class='employer_name3'/></Table.Cell>
                                    <Table.Cell ><input type='text' class='designation3' /></Table.Cell>
                                    <Table.Cell> 
                                        <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date3_xprnc'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month3_xprnc'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year3_xprnc'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                    </Menu>
                                    </Table.Cell>
                                    
                                    <Table.Cell textAlign='right'>
                                    <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date3_xprnc2'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month3_xprnc2'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year3_xprnc2'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                    </Menu>
                                    
                                    </Table.Cell>
                                    <Table.Cell>
                                    <select class='ddlexperience3' name="ddlexperience3">
                                            <option value="0">Select</option>
                                            <option value="AMOS">AMOS</option>
                                            <option value="RAMCO">RAMCO</option>
                                            <option value="TRAX">TRAX</option>
                                            <option value="SAP">SAP</option> 
                                            <option value="-1">Other</option>
                                        </select>
                                        <input type="text" name="nature_duties3" id="nature_duties3" class="display_none nature_duties3" />
                                        {/* <input type='text' class='nature_duties3'  /> */}
                                    </Table.Cell>
                                    {/* <Table.Cell><input type='text' class='ofleaving_reason3'  /></Table.Cell> */}
                                    <Table.Cell><span id='spn_xprnc3'></span><input type='file' id='xprnc3' /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><input type='text' class='employer_name4'/></Table.Cell>
                                    <Table.Cell ><input type='text' class='designation4' /></Table.Cell>
                                    <Table.Cell> 
                                        <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date4_xprnc'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month4_xprnc'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year4_xprnc'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                    </Menu>
                                    </Table.Cell>
                                    
                                    <Table.Cell textAlign='right'>
                                    <Menu size='compact'>
                                    <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                    <Form.Select fluid placeholder='State' name='Date' class='date4_xprnc2'   control='select' >
                                        <option value={0}>Date</option>
                                    {_.map(Date, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='State' name='Month'  class='month4_xprnc2'   control='select'  >
                                        <option value={0}>Month</option>
                                    {_.map(Month, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                <Grid.Column floated='right' mobile={16} tablet={4} computer={2}>
                                <Form.Select fluid placeholder='Year' name='Year' class='year4_xprnc2'  control='select' >
                                        <option value={0}>Year</option>
                                    {_.map(Year, Date1 => (
                                        <option value={Date1.value} key={Date1.key} >{Date1.text}</option>
                                    ))}
                                </Form.Select>
                                </Grid.Column>
                                    </Menu>
                                    
                                    </Table.Cell>
                                    <Table.Cell>
                                    <select class='ddlexperience4' name="ddlexperience4">
                                            <option value="0">Select</option>
                                            <option value="AMOS">AMOS</option>
                                            <option value="RAMCO">RAMCO</option>
                                            <option value="TRAX">TRAX</option>
                                            <option value="SAP">SAP</option> 
                                            <option value="-1">Other</option>
                                        </select>
                                        <input type="text" name="nature_duties4" id="nature_duties4" class="display_none nature_duties4" />
                                {/* <input type='text' class='nature_duties4'  /> */}
                                </Table.Cell>
                                    {/* <Table.Cell><input type='text' class='ofleaving_reason4'  /></Table.Cell> */}
                                    <Table.Cell><span id='spn_xprnc4'></span><input type='file' id='xprnc4' /></Table.Cell>
                                </Table.Row>
                           
                            </Table.Body>
                        </Table>

                        <Grid.Column floated='left' mobile={7} tablet={8} computer={4}>
                            <label> Total Experience : </label>
                            <label className='lbl_xprnc bold'></label>
                        </Grid.Column>
                        <Grid.Column floated='left' mobile={9} tablet={8} computer={3}>
                        
                            {/* <input type="text" name='stotlexp' onChange={this.FormChange} value={FormData.stotlexp}/>
                            {errors.stotlexp && <Label basic color='red' pointing>{errors.stotlexp}</Label>} */}
                        </Grid.Column>
                        {/* <Grid.Column textAlign='left' only="computer" computer={9}>
                            <p>( Maximum 3 digits)</p>
                        </Grid.Column>
                        <Grid.Column textAlign='right' only="mobile" mobile={16} >
                            <p>( Maximum 3 digits)</p>
                        </Grid.Column> */}

                        <Table singleLine>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Language Known</Table.HeaderCell>
                                    <Table.HeaderCell>Read</Table.HeaderCell>
                                    <Table.HeaderCell>Write</Table.HeaderCell>
                                    <Table.HeaderCell>Speak</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>  <Input size='mini' type='text' name="lang_one" class='language' id='my_lang' /> <span class="colorblr"> * </span>   {errors.lang_one && <Label basic color='red' pointing>{errors.lang_one}</Label>}</Table.Cell>
                                    <Table.Cell>
                                        <input type='checkbox'
                                            value='true'
                                            name='lng_read'
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        < input type='checkbox'
                                            value='true'
                                            name='lng_write'
                                           // checked={data.bt_language_write == 'true'}

                                        />  </Table.Cell>
                                    <Table.Cell>
                                        <input type='checkbox'
                                            value='true'
                                            name='lng_speak'
                                        />  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>  <Input type='text' size='mini'  class='language2' id='my_lang2' /></Table.Cell>
                                    <Table.Cell>
                                        <input type='checkbox'
                                            value='true'
                                            name='lng2_read'

                                        />
                                    </Table.Cell>
                                    <Table.Cell> <input type='checkbox'

                                        value='true'
                                        name='lng2_write'

                                    />  </Table.Cell>
                                    <Table.Cell> <input type='checkbox'
                                            name='lng2_speak'
                                        value='true'
                                    />  </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>

                        <Grid.Column floated='left' only="computer,tablet" tablet={8} computer={10} className="padngbtm">
                            <Button primary onClick={(e) => this.submit(3)} >Back</Button>
                        </Grid.Column>
                        <Grid.Column floated='right' only="computer,tablet" tablet={8} computer={4} className="mg_20">
                            <Button primary loading={loading}>Save & Next </Button>
                        </Grid.Column>
                        <Grid.Column floated='left' only="mobile" mobile={16}>
                            <Button.Group>
                                <Button primary>Back</Button>
                                <Button.Or />
                                <Button primary>Validate details</Button>
                                <Button.Or />
                                <Button primary>Save & Next</Button>
                            </Button.Group>
                        </Grid.Column>

                    </Grid>
                </Form>

            </div>
        );
    }
}

Qualification.propTypes = {
    submit: PropTypes.func.isRequired
  }

export default Qualification;