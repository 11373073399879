import React, { Component } from 'react';
import { Step, Container, Label } from 'semantic-ui-react'
import '../Css/custom.css';


const colors = [
    'blue',
]
class StepProcess extends Component {

    state = {
        loading: true,
        status: -1,
        message: "",
        data: {},
        error: null,
        StepNo: 1
    }

    componentDidMount() {
        this.setState({ loading: true, StepNo: this.props.StepNo })
    }

    render() {
        const { StepNo, CurStepNo } = this.props
        return (
            <div>
                <Container className="RegSteps">
                    <Step.Group size='tiny'>
                        <Step active={(CurStepNo === 1) ? true : false }   >
                            {colors.map(color => (
                                <Label circular color={color} key={color}>
                                    1
                             </Label>
                            ))}
                            <Step.Content>
                                <Step.Title> Basic Info</Step.Title>
                            </Step.Content>
                        </Step>
                        <Step active={(CurStepNo === 2) ? true : false } disabled={(StepNo >= 2) ? false : true}>
                            {colors.map(color => (
                                <Label circular color={color} key={color}>
                                    2
                             </Label>
                            ))}
                            <Step.Content >
                                <Step.Title>Photo & Signature</Step.Title>
                            </Step.Content>
                        </Step>
                        <Step active={(CurStepNo === 3) ? true : false } disabled={(StepNo >= 3) ? false : true}>
                            {colors.map(color => (
                                <Label circular color={color} key={color}>
                                    3
                             </Label>
                            ))}
                            <Step.Content >
                                <Step.Title>Details</Step.Title>
                            </Step.Content>
                        </Step>
                        <Step active={(CurStepNo === 4) ? true : false } disabled={(StepNo >= 4) ? false : true}>
                            {colors.map(color => (
                                <Label circular color={color} key={color}>
                                    4
                             </Label>
                            ))}
                            <Step.Content >
                                <Step.Title>Qualification & Experience</Step.Title>
                            </Step.Content>
                        </Step>
                        <Step active={(CurStepNo === 5) ? true : false } disabled={(StepNo >= 5) ? false : true}>
                            {colors.map(color => (
                                <Label circular color={color} key={color}>
                                    5
                             </Label>
                            ))}
                            <Step.Content >
                                <Step.Title>Preview</Step.Title>
                            </Step.Content>
                        </Step>
                        <Step active={(CurStepNo === 6) ? true : false } disabled={(StepNo >= 6) ? false : true}>
                            {colors.map(color => (
                                <Label circular color={color} key={color}>
                                    6
                             </Label>
                            ))}
                            <Step.Content >
                                <Step.Title>Payment</Step.Title>
                            </Step.Content>
                        </Step>
                        <Step active={(CurStepNo === 7) ? true : false } disabled={(StepNo >= 7) ? false : true}>
                            {colors.map(color => (
                                <Label circular color={color} key={color}>
                                    7
                             </Label>
                            ))}
                            <Step.Content >
                                <Step.Title>Payment Confirmation</Step.Title>
                            </Step.Content>
                        </Step>
                    </Step.Group>
                </Container>
            </div>
        );
    }
}

export default StepProcess;