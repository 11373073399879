import React, { Component } from 'react';
import { Button, Form, Grid, Header, Segment, Label, Divider } from 'semantic-ui-react'
import Validator from 'validator';
import PropTypes from 'prop-types';

class LoginForm extends Component {
    state = {
        data: {
            email: "",
            password: ""
        },
        loading: false,
        errors: {}
    }

    onChange = e =>
        this.setState({
            data: { ...this.state.data, [e.target.name]: e.target.value }
        })

    onsubmit = () => {
        this.setState({ loading: true });
        const errors = this.validate(this.state.data);
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            if (this.state.data.email === "123456789" && this.state.data.password === "1234") {
                this.props.submit(this.state.data);
            }
        }
        this.setState({ loading: false });
    }

    validate = (data) => {
        const errors = {};
        if (!Validator.isNumeric(data.email)) {
            errors.email = "Invalid Application No.";
            errors.emailicon = "close";
        }
        if (data.email === "") {
            errors.email = "Can't be blank";
            errors.emailicon = "close";
        }
        if (!data.password) {
            errors.password = "Can't be blank";
            errors.passwordicon = "close";
        }
        return errors;
    }

    render() {
        const { data, errors, loading } = this.state;
        return (
            <div className='login-form'>
                {/*
                Heads up! The styles below are necessary for the correct render of this example.
                You can do same with CSS, the main idea is that all the elements up to the `Grid`
                below must have a height of 100%.
                */}
                <style>{`
                body > div,
                body > div > div,
                body > div > div > div.login-form {
                    height: 100%;
                }
                `}</style>
                <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Header as='h2' color='teal' textAlign='center'>
                            Log-in to your account
                    </Header>
                        <Form size='large' onSubmit={this.onsubmit} loading={loading}>
                            <Segment>
                                <Form.Field error={!!errors.email}>
                                    <input icon='user' iconPosition='left' type="text" id="email"
                                        name="email" placeholder="Enter Application Number" value={data.email} onChange={this.onChange} />
                                    {errors.email && <Label basic color='red' pointing>{errors.email}</Label>}
                                </Form.Field>
                                <Form.Field error={!!errors.password}>
                                    <input icon='lock' iconPosition='left' placeholder='Password'
                                        type='password' id="password" name="password"
                                        value={data.password} onChange={this.onChange} />
                                    {errors.password && <Label basic color='red' pointing>{errors.password}</Label>}
                                </Form.Field>
                                <Divider />
                                <Button color='teal' size='large' fluid>Login</Button>
                            </Segment>
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

LoginForm.propTypes = {
    submit: PropTypes.func.isRequired
}

export default LoginForm;