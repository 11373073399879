import React, { Component } from 'react';
import ExamList from '../Components/ExamList';
import ClientHeader from '../Components/ClientHeader';
import { Divider } from 'semantic-ui-react';

class ExamPage extends Component {
    render() {
        return (
            <div>
                <ClientHeader />
                <Divider />
                <ExamList />
            </div>
        );
    }
}

export default ExamPage;