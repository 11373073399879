import React, { Component } from 'react';
import {  } from 'semantic-ui-react'
import MainHeader from '../Components/MainHeader';
import MainMenu from '../Components/MainMenu';
import Footer from '../Components/Footer';
import StepProcess from '../Components/StepProcess';
import PhotoSignatureUpload from '../Components/PhotoSignatureUpload';
import Uploading from '../Components/Uploading';





class PhotoSignaturePage extends Component {
    render() {
        return (
            <div>
                <MainHeader />
                
                <MainMenu />
                <StepProcess />
                <PhotoSignatureUpload />
                <Uploading />
                <Footer />
               
                
            </div>
        );
    }
}

export default PhotoSignaturePage;