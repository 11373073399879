import _ from 'lodash';
import React, { Component } from 'react';
import { Form, Segment, Header, Grid, Table, Radio,Label, Button, Container, Checkbox, Divider } from 'semantic-ui-react';
import '../Css/custom.css';
import { ServerUrl,Get,Post } from "../Classes/ApiHelper";
import $ from 'jquery';
// const options = [
//     { key: 1, text: 'Choice 1', value: 1 },
//     { key: 2, text: 'Choice 2', value: 2 },
//     { key: 3, text: 'Choice 3', value: 3 },
// ]


class Preview extends Component {

    state = {
        data: {},
        dataq: {},
        data_xprnc:{},
        loading: false,
        errors: {},
        status: -1,
        message: ""
    }

    validate = (data) => {
        const errors = {};
        if (!$('input[name=chk_tnc]').prop("checked")) {
            errors.chk_tnc = "Must Select";
        }
        if (!$('input[name=chk_img]').prop("checked")) {
            errors.chk_img = "Must Select";
        }
        if (!$('input[name=chk_sign]').prop("checked")) {
            errors.chk_sign = "Must Select";
        }
        return errors;
    }

    submit = (CurStep) => {
        this.props.submit(CurStep);
      }
      back = (CurStep) => {
        this.props.back(CurStep);
      }
    SaveData = () => {
        const errors = this.validate(this.state.FormData)
        this.setState({ errors });
        if (Object.keys(errors).length === 0) { 
                    if (this.props.ApplicationNo !== '0') {
                        this.props.submit(6);
                    }
                }
        return false;
    }

    componentDidMount() { 
        this.setState({ ApplicationNo: this.props.ApplicationNo })
        this.setState({ loading: true })
        var CanData = Get('CandidatePersonalDetail/' + this.props.ApplicationNo)
        CanData.then(
            response => this.setState({
              loading: false,
              message: response.data.message,
              data: response.data.data[0],
              dataq:response.data.dataq,
              data_xprnc:response.data.data_xprnc
          })
        ).catch(error => this.setState({ error, loading: false }));
 
        $('input[type=checkbox]').removeClass('hidden');

      }


    render() {
        const { data,dataq,data_xprnc ,errors} = this.state
        return (
            <div>
                <Container className="padingbtmnew">
                    <Form className="container  mb-from-all"  ref="form"
                   onSubmit={this.SaveData}>
                        
                        <Grid >
                            <Grid.Column floated='left' width={16}>
                                <Segment inverted color='blue' >
                                    <Header as='h5' >

                                        <Header.Content >Preview of the submitted Registration Details</Header.Content>
                                    </Header>

                                </Segment>
                            </Grid.Column>
                            <Table basic='very'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Registration No.</Table.HeaderCell>
                                        <Table.HeaderCell>{data.Reg_Number}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {/* <Table.Row>
                                        <Table.Cell>Paper</Table.Cell>
                                        <Table.Cell>{data.nvc_paper_name}</Table.Cell>
                                    </Table.Row> */}

                                    <Table.Row>
                                        <Table.Cell>Full name</Table.Cell>
                                        <Table.Cell>{data.vc_full_name}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Category</Table.Cell>
                                        <Table.Cell>{data.vc_category_name}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Are you a Person with Benchmark Disability of 40% and above ? </Table.Cell>
                                        <Table.Cell>{(data.bt_disablility) ? "Yes" : "No"}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Type of Disability</Table.Cell>
                                        <Table.Cell>
                                        {
                                                data.in_disability_type
                                            }
                                        </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Are you a Jammu and Kashmir domicile ?</Table.Cell>
                                        <Table.Cell>{(data.bt_jnk_domicile) ? "Yes" : "No"}</Table.Cell>
                                    </Table.Row>

                                    {/* <Table.Row>
                                        <Table.Cell>Are you an Ex-Serviceman : ?</Table.Cell>
                                        <Table.Cell>
                                        {(data.bt_exserviceman) ? "Yes" : "No"}</Table.Cell>
                                    </Table.Row> */}

                                    <Table.Row>
                                        <Table.Cell>Period of service as Ex-Servicemen :</Table.Cell>
                                        <Table.Cell>{data.in_service_period} Months</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Nationality</Table.Cell>
                                        <Table.Cell> 
                                            {data.vc_nationality == 1  && "Indian"}
                                            {data.vc_nationality == 2  && "Other"}
                                        </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Religion to which you belong  :</Table.Cell>
                                        <Table.Cell>
                                            {data.in_religion_id === 1  && "Buddhist"}
                                            {data.in_religion_id === 2  && "Christian"}
                                            {data.in_religion_id === 3  && "Hindu"}
                                            {data.in_religion_id === 4  && "Jain"}
                                            {data.in_religion_id === 5  && "Sikh"}
                                            {data.in_religion_id === 6  && "Zoroastrian"}
                                            {data.in_religion_id === 6  && "Others"}
                                        </Table.Cell>
                                    </Table.Row>

                                    {/* <Table.Row>
                                        <Table.Cell>Do you belong to Religious Minority Community ? :</Table.Cell>
                                        <Table.Cell>{(data.bt_religion_community) ? "Yes" : "No"}</Table.Cell>
                                    </Table.Row> */}


                                    <Table.Row>
                                        <Table.Cell>Region of Posting (it will be city of examination as well)</Table.Cell>
                                        <Table.Cell>{data.exam_center1} </Table.Cell>
                                    </Table.Row>

                                </Table.Body>
                            </Table>


                            <Grid.Column floated='left' width={16}>
                            <Segment inverted color='blue' >
                                <Header as='h5' >
                                    <Header.Content >Personal Details</Header.Content>
                                </Header>
                            </Segment>
                            </Grid.Column>
                            <Table basic='very'>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Date of Birth</Table.Cell>
                                        <Table.Cell>{new Date(Date.parse(data.dt_DOB)).toDateString()}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>Age completed as on 01.08.2019 </Table.Cell>
                                        <Table.Cell>{data.vc_age}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Gender</Table.Cell>
                                        <Table.Cell> 
                                            {data.in_gender_id === 1 && "Male" }
                                            {data.in_gender_id === 3 && "Female" }
                                            {data.in_gender_id === 4 && "Other" }
                                            </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Marital Status </Table.Cell>
                                        <Table.Cell>
                                            {data.in_marital_status_id === 1 && "Un-Married" }
                                            {data.in_marital_status_id === 2 && "Married" }
                                            {data.in_marital_status_id === 3 && "Widow" }
                                            {data.in_marital_status_id === 4 && "Widower" }
                                            {data.in_marital_status_id === 5 && "Divorced" }
                                            {data.in_marital_status_id === 6 && "Judicially Separated" }
                                        </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Father's Name</Table.Cell>
                                        <Table.Cell>{data.vc_father_name}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Mother's Name</Table.Cell>
                                        <Table.Cell>{data.vc_mother_name}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Spouse's Name</Table.Cell>
                                        <Table.Cell>{data.vc_spouse_name}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Address for Correspondence</Table.Cell>
                                        <Table.Cell>{data.vc_address}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Address 2</Table.Cell>
                                        <Table.Cell>{data.vc_address_2}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Address 3</Table.Cell>
                                        <Table.Cell>{data.vc_address_3}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>District</Table.Cell>
                                        <Table.Cell>{data.vc_district}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>State</Table.Cell>
                                        <Table.Cell>{data.state1}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Pin code</Table.Cell>
                                        <Table.Cell>{data.in_pincode}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Permanent address</Table.Cell>
                                        <Table.Cell>{data.vc_correspondence_address}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Address 2</Table.Cell>
                                        <Table.Cell>{data.vc_correspondence_address_2}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Address 3</Table.Cell>
                                        <Table.Cell>{data.vc_correspondence_address_3}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>District</Table.Cell>
                                        <Table.Cell>{data.vc_correspondence_district}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>State</Table.Cell>
                                        <Table.Cell>{data.state2}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Pincode</Table.Cell>
                                        <Table.Cell>{data.in_correspondence_pincode}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            
                            <Grid.Column floated='left' width={16}>
                            <Segment inverted color='blue'  >
                                <Header as='h5' >
                                    <Header.Content >Contact Details</Header.Content>
                                </Header>
                            </Segment>
                            </Grid.Column>

                            <Table basic='very' >
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>Mobile No.</Table.Cell>
                                        <Table.Cell>{data.vc_mobile_num1}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Alternative Number<br />(Mobile No./Landline No.)</Table.Cell>
                                        <Table.Cell>{data.vc_mobile_num2}</Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Email ID</Table.Cell>
                                        <Table.Cell>{data.vc_email}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>

                            <Grid.Column floated='left' width={16}>
                            <Segment inverted color='blue'  >
                                <Header as='h5' >
                                    <Header.Content >Education Qualification</Header.Content>
                                </Header>
                            </Segment>
     
                            <Grid.Column floated='left' mobile={16} tablet={8} computer={8}>
                            <label>Eligibility Criteria :</label>
                            <span class="colorblr"> * </span>
                        </Grid.Column>
                        <Grid.Column floated='right' mobile={16} tablet={8} computer={8}>
                            <Grid divided='vertically'>
                                <Grid columns={4}>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={3}>
                                        <Form.Field>
                                            <Radio
                                                label='A'
                                                name='iEligibleGroup123'
                                                value='1'
                                                checked={data.iEligibleGroup == '1'}
                                                disabled
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={2}>
                                        <Form.Field>
                                            <Radio
                                                label='B'
                                                name='iEligibleGroup123'
                                                value='0'
                                                checked={data.iEligibleGroup == '0'}
                                                disabled 
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    {errors.iEligibleGroup123 && <Label basic color='red' pointing>{errors.iEligibleGroup123}</Label>}
                                </Grid>
                            </Grid>
                        </Grid.Column>

                        <Grid.Column floated='left' mobile={16} tablet={8} computer={8}>
                            <label>Do you have post-graduation Degree/ Diploma (not less than 01-year duration) in HR/ Finance/MMD /Library Science/ Stenography :</label>
                            <span class="colorblr"> * </span>
                        </Grid.Column>
                        <Grid.Column floated='right' mobile={16} tablet={8} computer={8}>
                            <Grid divided='vertically'>
                                <Grid columns={4}>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={3}>
                                        <Form.Field>
                                            <Radio
                                                label='Yes'
                                                name='iQualNote'
                                                value='1'
                                                checked={data.iQualNote == '1'}
                                                disabled
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column floated='right' mobile={16} tablet={8} computer={6}>
                                        <Form.Field>
                                            <Radio
                                                label='No'
                                                name='iQualNote'
                                                value='0'
                                                checked={data.iQualNote == '0'}
                                                disabled
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                            </Grid>
                        </Grid.Column>


                            <Table singleLine>
                                <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Exam Passed</Table.HeaderCell>
                                    <Table.HeaderCell>Degree</Table.HeaderCell>
                                    <Table.HeaderCell>Subject/Stream</Table.HeaderCell>
                                    <Table.HeaderCell>University / College / Board</Table.HeaderCell>
                                    <Table.HeaderCell>Year of Passing</Table.HeaderCell>
                                    <Table.HeaderCell>% of Marks</Table.HeaderCell>
                                </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                {_.map(dataq, card => (
                                <Table.Row>
                                    <Table.Cell>{card.vc_qualification_id}</Table.Cell>
                                    <Table.Cell>{card.vc_degree_id}</Table.Cell>
                                    <Table.Cell>{card.vc_subject_stream}</Table.Cell>
                                    <Table.Cell>{card.vc_university_college_board}</Table.Cell>
                                    <Table.Cell>{card.vc_date_passing}</Table.Cell>
                                    <Table.Cell>{card.dc_percentage}</Table.Cell>
                                </Table.Row>
                             ))}
                                </Table.Body>
                            </Table>
                            </Grid.Column>


                            <Grid.Column floated='left' width={16}>
                            <Segment inverted color='blue'  >
                                <Header as='h5' >
                                    <Header.Content >Work Experience Details</Header.Content>
                                </Header>
                            </Segment>

                            <Grid.Column floated='left' mobile={16} tablet={8} computer={12}>
                            <label>Do you have minimum 24 months of working experience in any Scheduled Airlines on Aviation related software (e.g. AMOS/RAMCO/TRAX etc.) or in SAP </label>
                            <span class="colorblr"> * </span>
                        </Grid.Column>
                        <Grid.Column floated='right' mobile={16} tablet={8} computer={4}>
                            <Grid divided='vertically'>
                                <Grid columns={4}>
                                    <Grid.Column> 
                                        <Radio
                                            label='Yes'
                                            name='iWorkGroup'
                                            value='1'
                                            checked={data.iWorkGroup == '1'}
                                            disabled
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Radio
                                            label='No'
                                            name='iWorkGroup'
                                            value='0'
                                            checked={data.iWorkGroup == '0'}
                                            disabled
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Grid>
                        </Grid.Column>


                            <Table singleLine>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name of Employer</Table.HeaderCell>
                                    <Table.HeaderCell>Designation</Table.HeaderCell>
                                    <Table.HeaderCell  >Total Period of Service</Table.HeaderCell>
                                    <Table.HeaderCell >Nature of Duties</Table.HeaderCell> 
                                    <Table.HeaderCell >Experience</Table.HeaderCell> 
                                </Table.Row> 
                            </Table.Header>

                                <Table.Body>
                                {_.map(data_xprnc, card => (
                                <Table.Row>
                                <Table.Cell>{card.nvc_employer_name}</Table.Cell>
                                <Table.Cell >{card.nvc_designation}</Table.Cell>
                                <Table.Cell> 
                                {card.nvc_service_period}
                                     </Table.Cell>
                                 <Table.Cell> 
                                 {card.nvc_nature_duties}
                                    </Table.Cell> 
                                 <Table.Cell> 
                                 {card.nvc_experience}
                                    </Table.Cell> 
                            </Table.Row>
                           
                             ))}
                                </Table.Body>
                            </Table>
                            </Grid.Column>

                            <Grid.Column floated='left' width={16}>
                            <Segment inverted color='blue'  >
                                <Header as='h5' >
                                    <Header.Content >Other Detail</Header.Content>
                                </Header>
                            </Segment>

                            <Table singleLine>
                                <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Language Known</Table.HeaderCell>
                                    <Table.HeaderCell>Read</Table.HeaderCell>
                                    <Table.HeaderCell>Write</Table.HeaderCell>
                                    <Table.HeaderCell>Speak</Table.HeaderCell>
                                </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                <Table.Row>
                                    <Table.Cell>{data.nvc_language}</Table.Cell>
                                    <Table.Cell>{(data.bt_language_read) ? "Yes" : "No"}</Table.Cell>
                                    <Table.Cell>{(data.bt_language_write) ? "Yes" : "No"}</Table.Cell>
                                    <Table.Cell>{(data.bt_language_speak) ? "Yes" : "No"}</Table.Cell>
                                </Table.Row> 
                                <Table.Row>
                                    <Table.Cell>{data.nvc_language2}</Table.Cell>
                                    <Table.Cell>{(data.bt_language2_read) ? "Yes" : "No"}</Table.Cell>
                                    <Table.Cell>{(data.bt_language2_write) ? "Yes" : "No"}</Table.Cell>
                                    <Table.Cell>{(data.bt_language2_speak) ? "Yes" : "No"}</Table.Cell>
                                </Table.Row>
                                </Table.Body>
                            </Table>
                            </Grid.Column>
                            

                            <Grid.Column floated='left' width={16}>  
                            <Segment inverted color='blue'>
                                <Header as='h5' >
                                    <Header.Content >Declaration</Header.Content>
                                </Header>
                            </Segment>
                            </Grid.Column>

                            <Grid  >
                                <Grid.Column floated='left' width={16} >
                                    
                                    <p>I hereby declare  that all the statement made in the application are True, Complete and Correct to the best of my knowledge and belief. I understand that in the event of any information being found untrue or incorrect at any stage or I am not satisfying any of the eligibility criteria stipulated, and also in case of creating influence/undue pressure regarding recruitment shall tantamount to cancellation of my candidature,even after my selection or joining.</p>
                                </Grid.Column>
                            </Grid>
                            <Grid.Column floated='left' width={16} >
                            </Grid.Column>
                           </Grid>
                           <Grid.Column floated='left' mobile={16} tablet={16} computer={16} className="padingiagree">
                            <Checkbox
                                name='chk_tnc'
                                value='1'
                                label="I Agree"
                            />       {errors.chk_tnc && <Label basic color='red' pointing>{errors.chk_tnc}</Label>}
 
                          </Grid.Column>

                            <Grid className="pd_img">
                            <Grid.Column floated='left' mobile={8} tablet={8} computer={8}>
                               <img src={ServerUrl+data.vc_scanned_photo} width='150px'/><br/>
                               <Checkbox
                                name='chk_img'
                                value='1'
                                label="I Confirm This is my image"
                            />{errors.chk_img && <Label basic color='red' pointing>{errors.chk_img}</Label>}
                            </Grid.Column>
                            <Grid.Column floated='right' mobile={8} tablet={8} computer={8}>
                            <img src={ServerUrl+data.vc_scanned_sign}  width='100px'/><br/>
                               <Checkbox
                                name='chk_sign'
                                value='1'
                                label="I Confirm This is my signature"
                            />{errors.chk_sign && <Label basic color='red' pointing>{errors.chk_sign}</Label>}
                            </Grid.Column>
                            {/* <Grid.Column floated='right' only='computer' computer={1}>
                            </Grid.Column> */}
                            </Grid> 
                          <Divider/>
                          <Grid>
                            <Grid.Column floated='left' only="computer,tablet" tablet={3} computer={3}>

                            <Button primary labelPosition='left' primary icon='left chevron' content='Back' onClick={(e) => this.submit(4)} />
                            </Grid.Column>
                            <Grid.Column floated='right' only="computer,tablet" tablet={5} computer={5}>
                            {/* <Button primary onClick={() => window.print()}>PRINT</Button> */}
                            </Grid.Column>
                            <Grid.Column floated='right' only="computer,tablet" tablet={3} computer={3} className="mg_20">
                                <Button primary >Final Submit</Button>
                            </Grid.Column>

                            {/* <Grid.Column floated='left' only="mobile" mobile={16}>
                                <Button.Group>
                                    <Button primary>Back</Button>
                                    <Button.Or />
                                    <Button primary>Final Submit</Button>
                                </Button.Group>
                            </Grid.Column> */}
                        </Grid>
                    </Form>
                </Container>
            </div>

        );
    }
}

export default Preview;