import React, { Component } from 'react';
import '../Css/custom.css';
import { Form, Input, Popup, Button,Modal, Container, Label, Message, Grid, Divider, GridColumn } from 'semantic-ui-react'
import Validator from 'validator';
import { Post } from "../Classes/ApiHelper";
import { Get } from '../Classes/ApiHelper';
import PropTypes from 'prop-types';
import $ from 'jquery';

const options = [
    { key: 'm', text: 'Male', value: 'male' },
    { key: 'f', text: 'Female', value: 'female' },
]

class StudentForm extends Component {

    state = { open: false }

    show = size => () => this.setState({ size, open: true })
    close = () => this.setState({ open: false })

    state = {
        FormData: {
            sFirstName: "",
            sMiddleName: "",
            sLastName: "",
            sFullName: "",
            sMobileNum1: "",
            sMobileNum2: "",
            sEmail: "",
            reg_id: '',
            mail_code: '',
            phone_code: '',
        },
        data: {},
        loading: false,
        errors: {},
        status: -1,
        status2: "",
        message: "",
        ApplicationNo: 0
    }


    componentDidMount() {
        this.setState({ ApplicationNo: this.props.ApplicationNo })
        if (this.props.ApplicationNo !== '0') {
           // alert('dsfdsf');
            $('.vrfy_btn').addClass('display_none');
            this.setState({ loading: true })
            var CandidateData = Get('CandidateBasicInfo/' + this.props.ApplicationNo)
            CandidateData.then(
                response => this.setState({
                    loading: false,
                    FormData: response.data.data
                }, console.log(this.state))
            ).catch(error => this.setState({ error, loading: false }));
        }
        else {
            $('.vrfy_btn').removeClass('display_none');
        }       
    }    

    mailData = () => {        
        var ApiUrl = 'RegVerification?id='+  this.state.FormData.reg_id +'&type=mail';
        var mobileDataApi = Get(ApiUrl)
        mobileDataApi.then(
            response => this.setState({
                loading: false
            })
        ).catch(error => {
            const errors = {};
            errors.FormError = error;
            this.setState({ errors, loading: false });
        }); 
        return false;
    }

    mobData = () => {   
           
        var ApiUrl = 'RegVerification?id='+ this.state.FormData.reg_id +'&type=mo';
        var mobileDataApi = Get(ApiUrl)
        mobileDataApi.then(
            response => this.setState({
                loading: false
            })
        ).catch(error => {
            const errors = {};
            errors.FormError = error;
            this.setState({ errors, loading: false });
        });
        return false;
    }
   

    FormChange = e => {
        this.setState({
            FormData: { ...this.state.FormData, [e.target.name]: e.target.value }
        })
    }

    mail_fun = e =>{
        this.mailData({

        });
    }

    mob_fun = e =>{
        this.mobData({

        });
    }

    
    mobilevalidate = (text) => {
        const reg = /^[0]?[6789]\d{9}$/;
        if (reg.test(text) === false) {
            return false;
        } else {
            return true;
        }
    }

    validate = (data) => {
        const errors = {};
        if (data.sFirstName === '') {
            errors.FirstName = "Can't be blank.";
        }
        if (data.sMobileNum1 === '') {
            errors.MobileNum1 = "Can't be blank.";
        }
        else {
            if (!this.mobilevalidate(data.sMobileNum1)) {
                errors.MobileNum1 = "Invalid Mobile No.";
            }
        }
        // if (data.sMobileNum2 !== '') {
        //     if (!this.mobilevalidate(data.sMobileNum2)) {
        //         errors.MobileNum2 = "Invalid Mobile No.";
        //     }
        // }
        if (data.sEmail === '') {
            errors.Email = "Can't be blank.";
        }
        else {
            if (!Validator.isEmail(data.sEmail)) {
                errors.Email = "Invalid email";
            }
        }
        return errors;
    }

    openModal() {
        this.setState({modalIsOpen: true});
      }


    validateverify = (data) => {
        const errors = {};
       
        if (data.reg_id === '') {
            errors.reg_id = "Can't be blank.";
        }
        if (data.mail_code === '') {
            errors.mail_code = "Can't be blank.";
        }
        if (data.phone_code === '') {
            errors.phone_code = "Can't be blank.";
        }
        return errors;
    }

    SaveData = () => {
        const errors = this.validate(this.state.FormData)
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            var PostData = this.state.FormData;
            PostData.sFullName = PostData.sFirstName + ' ' + PostData.sMiddleName + ' ' + PostData.sLastName;
            PostData.sPassword = PostData.sMobileNum1;
            if(PostData.sMobileNum2==""){
                PostData.sMobileNum2="0";
            }
            PostData.lId = this.props.pplicationNo;
            if (this.props.ApplicationNo !== '0') { PostData.iStep = 2; }
            else { PostData.iStep = 1; }
            var ExamData = Post('CandidateBasicInfo', PostData)
            FormData.sMobileNum2="";
            this.show('tiny')
            ExamData.then(
                response => this.setState({
                    loading: false,
                    status: response.data.status,
                    message: response.data.message,
                    data: response.data.data
                }, () => {
                    if (this.props.ApplicationNo !== '0') {
                        this.props.submit(2);
                    }
                })
            ).catch(error => {
                const errors = {};
                errors.FormError = error;
                this.setState({ errors, loading: false });
            }).then(()=>{
                this.state.sMobileNum2="";
            }); 
        }
        return false;
    }

    verifydata = () => {
        const errors = this.validateverify(this.state.FormData)
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            var PostData = this.state.FormData;
            PostData.reg_id = PostData.reg_id;
            PostData.phone_code = PostData.phone_code;
            PostData.mail_code = PostData.mail_code;
            var ExamData = Post('RegVerification', PostData)
            ExamData.then(
                response => this.setState({
                    loading: false,
                    status2: response.data
                }, () => {
                    if (this.props.ApplicationNo !== '0') {
                        this.props.submit(2);
                    }
                })
            ).catch(error => {
                const errors = {};
                errors.FormError = error;
                this.setState({ errors, loading: false });
            });
        }
        return false;
    }

    verifyForm = () => {
        const errors = this.validateverify(this.state.FormData)
        this.setState({ errors });
        if (Object.keys(errors).length === 0) {
            this.setState({ loading: true })
            var PostData = this.state.FormData;
            PostData.reg_id = PostData.reg_id;
            PostData.phone_code = PostData.phone_code;
            PostData.mail_code = PostData.mail_code;
            var ExamData = Post('RegVerification', PostData)
            ExamData.then(
                response => this.setState({
                    loading: false,
                    message:response.data
                })
            ).catch(error => {
                const errors = {};
                errors.FormError = error;
                this.setState({ errors, loading: false });
            }).then(()=>{alert(this.state.message);
            if(this.state.message=="Success"){this.close()}});  
        }   
        return false;
    }

    verificatonsave = () => {
        
        return false;
    }

    render() {
        const { FormData, errors, data, loading, status, message, value,open, size  } = this.state
        return (
            <div>
                <Container>
                    <Form onSubmit={this.SaveData} loading={loading}>
                        <Form.Group widths='equal'>
                            <Grid widths={16}>
                                <GridColumn width={5}>
                                    <Form.Field >
                                        <Form.Field label='First name' />
                                        <Input name='sFirstName' placeholder='First name' onChange={this.FormChange} value={FormData.sFirstName} />
                                        {errors.FirstName && <Label basic color='red' pointing>{errors.FirstName}</Label>}
                                    </Form.Field>

                                </GridColumn>
                                <GridColumn width={5}>
                                    <Form.Field control={Input} name='sMiddleName' label='Middle name' placeholder='Middle name' onChange={this.FormChange} value={FormData.sMiddleName} />
                                </GridColumn>
                                <GridColumn width={6} >
                                    <Form.Field>
                                        <Form.Field label='Last name' />
                                        <Input name='sLastName' placeholder='Last name' onChange={this.FormChange} value={FormData.sLastName} />
                                        {errors.LastName && <Label basic color='red' pointing>{errors.LastName}</Label>}
                                    </Form.Field>
                                </GridColumn>
                            </Grid>
         ``               </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field  className="fullname-width">
                                <Popup trigger={<Form.Field control={Input}  className="fullname-width"  name='sFullName' label='Full name' placeholder='Full Name'
                                    value={FormData.sFirstName + ' ' + FormData.sMiddleName + ' ' + FormData.sLastName}
                                    readOnly 
                                />}>
                                    The Name entered in the online application form should match exactly with the requisite documents (photo ID etc.) to be produced at the time of the examination and interview. You will be debarred from the selection process, if there is any discrepancy (difference) in the name entered by you in the online application form and the name printed in the documents produced at the time of    interview.However only 35 characters will be printed in your call letter. Any requests for subsequent changes/ consideration regarding any changes will not be entertained.
                            </Popup>
                            </Form.Field >
                        </Form.Group>
                        <Form.Group widths='equal' className='mt-lg-email'>
                            <Form.Field label='Mobile Number' />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field >
                                <Input label='+91' name='sMobileNum1' placeholder='Mobile Number' onChange={this.FormChange} value={FormData.sMobileNum1} />
                                {errors.MobileNum1 && <Label basic color='red' pointing>{errors.MobileNum1}</Label>}
                            </Form.Field>
                            <Form.Field>
                                <Input label='+91' name='sMobileNum2' placeholder='Alternate Mobile Number' onChange={this.FormChange} value={FormData.sMobileNum2} />
                                {/* {errors.MobileNum2 && <Label basic color='red' pointing>{errors.MobileNum2}</Label>} */}
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal' className='mt-lg-email' >
                            <Form.Field label='Enter Email Id' />
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field className="fullname-width">
                                <Input size='small'
                                    name='sEmail'
                                    labelPosition='right'
                                    placeholder='Email Id'
                                    onChange={this.FormChange}
                                    value={FormData.sEmail}
                                />
                                {errors.Email && <Label basic color='red' pointing>{errors.Email}</Label>}
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                        <Button inverted color='blue' size='large'> 
                            Save & Submit
                        </Button>
                        <Button type="button" className='vrfy_btn' inverted color='blue' size='large' onClick={this.show('tiny')}> 
                            Verify
                        </Button>
                        </Form.Group>
                        <div>
        {/* <Button onClick={this.show('tiny')}>Tiny</Button> */}
                      </div>
                    </Form>

                    <Modal size={size} open={open} onClose={this.close}>
                        <Modal.Header>Please Enter Email & Mobile Verification Codes</Modal.Header>
                        <Modal.Content>
                        {status === 1 && this.props.ApplicationNo === '0' &&
                                <Message
                                    error
                                    header={'You have successfully registered. Your application number is : ' + data.ApplicationNo}
                                    content='Now you need to verify your email id and mobile number. For this please click on the "Verify" button. 
                                    OTP needed for this verification has been sent on your email id and mobile number entered by you. 
                                    Post verification please go to "Existing Candidate" column to fill the form. Please note that without payment, the application will be deemed incomplete.'
                                />
                            }
                        <Form onSubmit={this.verifydata} loading={loading}>
                        <Form.Field inline>
                    <label>Enter Your Application No.</label>
                    <input type="text" name='reg_id' onChange={this.FormChange} value={FormData.reg_id} />
                        {errors.reg_id && <Label basic color='red' pointing>{errors.reg_id}</Label>}
                    </Form.Field>
                    <Form.Field inline>
                    <label>Email Verification Code</label>
                    <input type="text" name='mail_code' onChange={this.FormChange} value={FormData.mail_code} />
                        {errors.mail_code && <Label basic color='red' pointing>{errors.mail_code}</Label>} 
                            <Button type="button" icon='refresh' onClick={this.mail_fun} content='Resend' />
                    </Form.Field>
                    <Form.Field inline>
                    <label>Mobile Verification Code</label>
                    <input type="text" name='phone_code' onChange={this.FormChange} value={FormData.phone_code} />
                        {errors.phone_code && <Label basic color='red' pointing>{errors.phone_code}</Label>}
                            <Button type="button" icon='refresh' onClick={this.mob_fun} content='Resend' />
                            {/* <a role="button" onClick={this.mob_fun}>Resend</a> */}
                    </Form.Field>
                    <Modal.Actions>
                            <Button type="button" positive icon='checkmark' labelPosition='right' onClick={this.verifyForm} content='Submit' />
                        </Modal.Actions>
                    </Form>
                        </Modal.Content>
                        </Modal>


                    <Divider horizontal></Divider>
                    <Grid columns={3} stackable textAlign='center'>
                        <Grid.Row verticalAlign='middle'>
                            {/* <Grid.Column width={4} >
                            </Grid.Column> */}
                            <Grid.Column width={16}> 
                                {status === 1 && this.props.ApplicationNo === '0' &&
                                  <Message
                                  error
                                  header={'You have successfully registered. Your application number is : ' + data.ApplicationNo}
                                  content='Now you need to verify your email id and mobile number. For this please click on the "Verify" button. 
                                  OTP needed for this verification has been sent on your email id and mobile number entered by you. 
                                  Post verification please go to "Existing Candidate" column to fill the form. Please note that without payment, the application will be deemed incomplete.'
                              />
                                }                               
                                {status === 0 &&
                                    <Message
                                        negative
                                        header=' '
                                        content={message}
                                    />
                                }

                                {errors.FormError &&
                                    <Message
                                        negative
                                        header='Something went Wrong.'
                                        content={errors.FormError}
                                    />
                                }
                            </Grid.Column>
                            {/* <Grid.Column width={4}>

                            </Grid.Column> */}
                        </Grid.Row>
                    </Grid>
                </Container>
            </div>
        );
    }
}

StudentForm.propTypes = {
    submit: PropTypes.func.isRequired
}

export default StudentForm;