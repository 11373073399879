import React, { Component } from 'react';
import { Grid, Segment, Container } from 'semantic-ui-react'
import Fee from '../Components/Fee';
import MainHeader from '../Components/MainHeader';
import MainMenu from '../Components/MainMenu';
import EventList from '../Components/EventList';
import Footer from '../Components/Footer';



class FeesPage extends Component {
    render() {
        return (
            <div>
                <MainHeader />
                <MainMenu />
                <Container >
                    <Grid columns={3} stackable className="MainBody">
                        <Grid.Row stretched>
                            <Grid.Column width={4}>
                                <Segment>
                                    <EventList ConType={1} />
                                </Segment>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <Segment>
                                    <Fee />
                                </Segment>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </Container>
                <Footer />
            </div>
        );
    }
}

export default FeesPage;