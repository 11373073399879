// eslint-disable-next-line
import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import LoginPage from './Pages/LoginPage';
import FeesPage from './Pages/FeesPage';
import ExamCity from './Pages/ExamCity';
import EligibilityPage from './Pages/EligibilityPage';
import ExamSchedulePage from './Pages/ExamSchedulePage';
import StudentRegistrationPage from './Pages/StudentRegistrationPage';
import PhotoSignaturePage from './Pages/PhotoSignaturePage';
import LoginAndRegistration from './Pages/LoginAndRegistration';
import ExamPage from './Pages/ExamPage';
import PreviewPage from './Pages/PreviewPage';
import Qualification from './Forms/Qualification';
import Personalinfo from './Forms/Personalinfo';
import { connect } from 'react-redux';
import { history } from './_helpers';
import { alertActions } from './_actions';
import { PrivateRoute } from './_components';
import Payment from './Components/Payment';
import Uploading from './Components/Uploading';

class App extends Component {

  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
        // clear alert on location change
        dispatch(alertActions.clear());
    });
}

  render() {
    return (
      <div className="App">
        <Router>
          <div className="ui">
            <Route path='/' exact component={ExamPage} />
            <Route path='/home' exact component={HomePage} />
            <Route path='/login' exact component={LoginPage} />
            <Route path='/Fees' exact component={FeesPage} />
            <Route path='/ExamCity' exact component={ExamCity} />
            <Route path='/Eligibility' exact component={EligibilityPage} />
            <Route path='/ExamSchedule' exact component={ExamSchedulePage} />
            <PrivateRoute  path='/StudentRegistration' exact component={StudentRegistrationPage} />
            <PrivateRoute  path='/PhotoSignaturePage' exact component={PhotoSignaturePage} />
            <Route path='/LoginAndRegistration' exact component={LoginAndRegistration} />
            <PrivateRoute  path='/PreviewPage ' exact component={PreviewPage} />
            <Route  path='/Qualification' exact component={Qualification} />
            <Route path='/Personalinfo' exact component={Personalinfo} />
            <Route path='/Payment' exact component={Payment} />
            <Route path='/Uploading' exact component={Uploading} />
            
          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
      alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 
