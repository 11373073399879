import _ from 'lodash';
import React, { Component } from 'react';
import { Container, Divider } from 'semantic-ui-react';
import { Table,Dimmer,Loader } from 'semantic-ui-react'
import { Post } from "../Classes/ApiHelper";

class Fee extends Component {
  state = {
    loading: true,
    status: -1,
    message: "",
    data: [],
    error: null
  }

  componentDidMount() {
    this.setState({ loading: true })
    let ExamLoadData = JSON.parse(sessionStorage.getItem('ExamData'));
    var PostData =
    {
      "lID": ExamLoadData.ExamID
    }
    var ExamData = Post('ExamFee', PostData)
    ExamData.then(
      response => this.setState({
        loading: false,
        status: response.data.status,
        message: response.data.message,
        data: response.data.data
      })
    ).catch(error => this.setState({ error, loading: false }));
  }


  render() {
    const { data, loading } = this.state
    return (
      <div>
        <Container>
          <Dimmer active={loading}>
            <Loader size='medium'>Loading</Loader>
          </Dimmer>
          <h3 className="fee">Exam Fee</h3>
          <Divider />
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Paper</Table.HeaderCell>
                <Table.HeaderCell>Gender</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Fees</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(data, FeeData => (
                <Table.Row>
                  <Table.Cell>{FeeData.nvc_paper_name}</Table.Cell>
                  <Table.Cell>{FeeData.vc_gender}</Table.Cell>
                  <Table.Cell>{FeeData.vc_category_name}</Table.Cell>
                  <Table.Cell>{FeeData.fee}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>
      </div>
    );
  }
}

export default Fee;