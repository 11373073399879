import _ from 'lodash';
import React, { Component, Fragment} from 'react';
import { Button, Card, Container, Image, Message, Placeholder } from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import { Post,ClientId,ServerUrl } from "../Classes/ApiHelper";


class ExamList extends Component {

    state = {
        loading: true,
        status: -1,
        message: "",
        data: [],
        error: null
    }

    componentDidMount() {
        this.setState({ loading: true })
        var ExamData = Post('ExamLists', { "lClientId": ClientId })
        ExamData.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            })
        ).catch(error => this.setState({ error, loading: false }));
    }

    render() {
        const { loading, data, error, message, status } = this.state
        return (
            <div>
                <Container>
                    <Fragment>
                        {loading &&  
                            <Card.Group doubling itemsPerRow={3} stackable>
                                <Card>
                                    <Placeholder>
                                        <Placeholder.Image rectangular />
                                    </Placeholder>
                                    <Card.Content>
                                        <Placeholder>
                                            <Placeholder.Header>
                                                <Placeholder.Line length='very short' />
                                                <Placeholder.Line length='medium' />
                                            </Placeholder.Header>
                                            <Placeholder.Paragraph>
                                                <Placeholder.Line length='short' />
                                            </Placeholder.Paragraph>
                                        </Placeholder>
                                    </Card.Content>
                                    <Card.Content extra>
                                        <Button disabled={loading} primary fluid>
                                            View Details
                                        </Button>
                                    </Card.Content>
                                </Card>
                                <Card>
                                    <Placeholder>
                                        <Placeholder.Image rectangular />
                                    </Placeholder>
                                    <Card.Content>
                                        <Placeholder>
                                            <Placeholder.Header>
                                                <Placeholder.Line length='very short' />
                                                <Placeholder.Line length='medium' />
                                            </Placeholder.Header>
                                            <Placeholder.Paragraph>
                                                <Placeholder.Line length='short' />
                                            </Placeholder.Paragraph>
                                        </Placeholder>
                                    </Card.Content>
                                    <Card.Content extra>
                                        <Button disabled={loading} primary fluid>
                                            View Details
                                        </Button>
                                    </Card.Content>
                                </Card>
                                <Card>
                                    <Placeholder>
                                        <Placeholder.Image rectangular />
                                    </Placeholder>
                                    <Card.Content>
                                        <Placeholder>
                                            <Placeholder.Header>
                                                <Placeholder.Line length='very short' />
                                                <Placeholder.Line length='medium' />
                                            </Placeholder.Header>
                                            <Placeholder.Paragraph>
                                                <Placeholder.Line length='short' />
                                            </Placeholder.Paragraph>
                                        </Placeholder>
                                    </Card.Content>
                                    <Card.Content extra>
                                        <Button disabled={loading} primary fluid>
                                            View Details
                                        </Button>
                                    </Card.Content>
                                </Card>
                            </Card.Group>
                        }
                        <Card.Group doubling itemsPerRow={3} stackable className="ExamList" centered>
                            {_.map(data, card => (
                                <Card key={card.in_id}>
                                    <Image src={ServerUrl + card.vc_logo} size='small' centered/>
                                    <Card.Content>
                                        <Fragment>
                                            <Card.Header>{card.nvc_name}</Card.Header>
                                            <Card.Meta>{card.nvc_title}</Card.Meta>
                                            <Card.Description>Last Date of Registration :{card.dt_registration_end}</Card.Description>
                                        </Fragment>
                                    </Card.Content>
                                    <Card.Content extra>
                                        <Link className="ui fluid primary button" to={
                                            { 
                                                pathname: "/home",  //+ this.props.product.Id,
                                                ExamData: { ExamID: card.in_id, ClientID: ClientId }
                                            }
                                        }>View Details</Link>
                                    </Card.Content>
                                </Card>
                            ))}
                        </Card.Group>
                    </Fragment>
                    {
                        error != null &&
                        <Message negative
                                 icon='exclamation triangle'
                                 header='Error'
                                 content={message}>
                        </Message>
                    }

                    {
                        status === 0 &&
                        <Message negative
                                 icon='info circle'
                                 header='Information'
                                 content={message}>
                        </Message>
                    }
                </Container>

            </div >
        );
    }
}

export default ExamList;