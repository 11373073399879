import _ from 'lodash';
import React, { Component } from 'react';
import { List, Header, Icon, Dimmer, Loader, Message, Modal,Button } from 'semantic-ui-react'
import { Post,ServerUrl } from "../Classes/ApiHelper";

function HeaderType(props) {
    if (props.ConType === 1) 
    {
      return 'Current Event';
    }
    else if (props.ConType === 2)
    {
        return 'News';
    }
    else if (props.ConType === 3)
    {
        return 'Important Links';        
    }
}

class EventList extends Component {
    state = {
        loading: true,
        status: -1,
        message: "",
        data: [],
        error: null,
        header: '',
        content: '',
        dimmer: false,
        ConOpen: false
    }

    show = (dimmer,ListId) => () => {
        let ListData = this.state.data;
        var DataIndex = null;
        for (let index = 0; index < ListData.length; index++) {
            if (ListId === ListData[index].in_id) {
                DataIndex = index;
            }
        }
        this.setState({ dimmer, ConOpen: true, header:ListData[DataIndex].nvc_Title, content:ListData[DataIndex].nvc_content })
    } 
        
    close = () => this.setState({ ConOpen: false })

    componentDidMount() {
        this.setState({ loading: true })
        let ExamLoadData = JSON.parse(sessionStorage.getItem('ExamData'));
        var PostData =
        {
            "lClientID": ExamLoadData.ClientID,
            "lExamID": ExamLoadData.ExamID,
            "lInfoType": this.props.ConType
        }
        var ExamData = Post('EventNewsLink', PostData)
        ExamData.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            })
        ).catch(error => this.setState({ error, loading: false }));
    }

    render() {
        const { loading, data, error, message, status } = this.state
        const { header, content, ConOpen,dimmer } = this.state
        return (
            <div>
                <Dimmer active={loading}>
                    <Loader size='medium'>Loading</Loader>
                </Dimmer>
                <Header as='h4'>
                    <Icon size='large' circular name='calendar alternate outline' />
                    <Header.Content>
                        <HeaderType ConType={this.props.ConType} />
                    </Header.Content>
                </Header>
                <List divided relaxed>
                    {_.map(data, listdata => (
                        <List.Item key={listdata.in_id}>
                            <List.Content>
                                {
                                    listdata.in_content_type === 1 &&
                                    <List.Header as='a' onClick={this.show('blurring',listdata.in_id)} >{listdata.nvc_Title}</List.Header>
                                }
                                {
                                    listdata.in_content_type === 2 &&
                                    <List.Header as='a' href={ServerUrl + listdata.nvc_file_path} target="_blank">{listdata.nvc_Title}</List.Header>
                                }
                                <List.Description as='p'>{listdata.dt_created}</List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
                <Modal dimmer={dimmer} open={ConOpen} onClose={this.close} inverted>
                    <Modal.Header>{header}</Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description>
                           <div dangerouslySetInnerHTML={{__html: content}}></div>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='black' onClick={this.close}>
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                {
                    error != null &&
                    <Message negative
                        icon='exclamation triangle'
                        header='Error'
                        content={message}>
                    </Message>
                }

                {
                    status === 0 &&
                    <Message negative
                        icon='info circle'
                        header='Information'
                        content={message}>
                    </Message>
                }
            </div>
        );
    }
}

export default EventList;