import React, { Component } from 'react';
import { Grid, Segment, Container } from 'semantic-ui-react'
import EventList from '../Components/EventList'
import HomeContent from '../Components/HomeContent'
import MainHeader from '../Components/MainHeader';
import MainMenu from '../Components/MainMenu';
import Footer from '../Components/Footer';
import { Redirect } from 'react-router-dom'

class HomePage extends Component {
    state = {
        ExamData: ''
    }

    constructor(props) {
        super(props);
        if(this.props.location.ExamData !== undefined)
        {
            sessionStorage.setItem('ExamData', JSON.stringify(this.props.location.ExamData));
        }
        if (sessionStorage.getItem('ExamData') !== null) {
            this.setState(
                {
                    redirectMe: false,
                    ExamData: sessionStorage.getItem('ExamData')
                }
            );
        }
        else
        {
            if (this.props.location.ExamData === undefined) {
                this.state = {
                    redirectMe: true,
                    ExamData: null
                }
            }
            else
            {
                sessionStorage.setItem('ExamData', JSON.stringify(this.props.location.ExamData));
                this.setState(
                    {
                        redirectMe: false,
                        ExamData: sessionStorage.getItem('ExamData')
                    }
                );
            }
        }
    }

    render() {
        const { ExamData } = this.state;
        let { redirectMe } = this.state;
        if (redirectMe) {
            return (
                <Redirect to="/" />
            );
        }
        return (
            <div >
                {ExamData != null &&
                    <div>
                        <MainHeader />
                        <MainMenu />
                        <Container >
                            <Grid columns={3} stackable className="MainBody">
                                <Grid.Row stretched>
                                    <Grid.Column width={4}>
                                        <Segment>
                                            <EventList ConType={1} />
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Segment>
                                            <HomeContent />
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Segment>
                                            <EventList  ConType={2} />
                                        </Segment>
                                        <Segment>
                                            <EventList ConType={3} />
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                        <Footer />
                    </div>
                }
            </div>
        );
    }
}

export default HomePage;