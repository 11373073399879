import React, { Component } from 'react';
import { Container,Segment,Icon,Header } from 'semantic-ui-react';

class PhotoSignatureUpload extends Component {
    render() {
        return (
            <div>
            <Container  >
              
              <Segment inverted color='olive' >
              <Header as='h5' >
     <Icon name='info circle' />
     <Header.Content >Your Provisional registration Number is 18000521 and your Password is BMVS8U83. <br />
 Your registration will be valid only when Application Fee/ Intimation Charges are paid and your candidature will be considered for RECRUITMENT OF SPECIALIST CADRE OFFICER ON CONTRACTUAL BASIS ADVERTISEMENT NO: CRPD/SCO-DBA (PM)/2018-19/11 in STATE BANK OF INDIA</Header.Content>
   </Header>
  
              </Segment>
 
              </Container>
            </div>
        );
    }
}

export default PhotoSignatureUpload;