import _ from 'lodash';
import React, { Component } from 'react';
import { Container, Divider } from 'semantic-ui-react';
import { Table,Dimmer,Loader } from 'semantic-ui-react'
import { Post } from "../Classes/ApiHelper";

class ExaminationCity extends Component {

    state = {
        loading: true,
        status: -1,
        message: "",
        data: [],
        error: null
    }

    componentDidMount() {
        this.setState({ loading: true })
        let ExamLoadData = JSON.parse(sessionStorage.getItem('ExamData'));
        var PostData =
        {
            "lClientID": ExamLoadData.ClientID,
            "lID": ExamLoadData.ExamID,
        }
        var ExamData = Post('ExamCity', PostData)
        ExamData.then(
            response => this.setState({
                loading: false,
                status: response.data.status,
                message: response.data.message,
                data: response.data.data
            })
        ).catch(error => this.setState({ error, loading: false }));
    }


    render() {
        const { data,loading } = this.state
        return (
            <div>
                <Container>
                <Dimmer active={loading}>
                    <Loader size='medium'>Loading</Loader>
                </Dimmer>
                    <h3 className="fee">Examination City</h3>
                    <Divider />
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>STATE</Table.HeaderCell>
                                <Table.HeaderCell>CITY CODE</Table.HeaderCell>
                                <Table.HeaderCell>CITY NAME</Table.HeaderCell>
                                <Table.HeaderCell>REGION</Table.HeaderCell>
                                <Table.HeaderCell>COUNTRY</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {_.map(data, LoctationData => (
                                <Table.Row>
                                    <Table.Cell>{LoctationData.State}</Table.Cell>
                                    <Table.Cell>{LoctationData.CityCode}</Table.Cell>
                                    <Table.Cell>{LoctationData.City}</Table.Cell>
                                    <Table.Cell>{LoctationData.Region}</Table.Cell>
                                    <Table.Cell>{LoctationData.Country}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default ExaminationCity;