import React, { Component } from 'react';
import { Menu, Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom'


class MainMenu extends Component {
    state = {
        ApplicationNo: 0,
        redirectMe: false
    }
    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    handleLogoutClick = () => {
        localStorage.removeItem('user');
        this.setState({
            redirectMe: true
        })
    }

    componentDidMount() {
        let UserData = JSON.parse(localStorage.getItem('user'));
        if(UserData !== null)
        {
            this.setState({ ApplicationNo: UserData.ApplicationNo });
        }
    }


    render() {
        let { redirectMe } = this.state;
        if (redirectMe) {
            return (
                <Redirect to="/home" />
            );
        }
        const { activeItem, ApplicationNo } = this.state
        return (
            <div>
                <Menu inverted stackable>
                    <Container>
                        <Menu.Item as={Link} to='/home'
                            name='home'
                            active={activeItem === 'home'}
                            onClick={this.handleItemClick}
                        />
                        {/* <Menu.Item as={Link} to='/Fees'
                            name='fees'
                            active={activeItem === 'fees'}
                            onClick={this.handleItemClick} />

                        <Menu.Item as={Link} to='/ExamCity'
                            name='examinationCities'
                            active={activeItem === 'examinationCities'}
                            onClick={this.handleItemClick}
                        />
                        <Menu.Item as={Link} to='/Eligibility'
                            name='eligibility'
                            active={activeItem === 'eligibility'}
                            onClick={this.handleItemClick}
                        /> */}
                        <Menu.Item as={Link} to='/ExamSchedule'
                            name='General Instructions'
                            active={activeItem === 'examinationSchedule'}
                            onClick={this.handleItemClick}
                        />
                        {
                            ApplicationNo !== 0 &&
                            <Menu.Menu position='right'>
                                <Menu.Item
                                    name='Logout'
                                    active={activeItem === 'logout'}
                                    onClick={this.handleLogoutClick}
                                />
                            </Menu.Menu>
                        }
                        {
                            ApplicationNo === 0 &&
                            <Menu.Menu position='right'>
                                <Menu.Item as={Link} to='/LoginAndRegistration'
                                    name='Login'
                                    active={activeItem === 'login'}
                                    onClick={this.handleItemClick}
                            />
                            </Menu.Menu>
                        }

                    </Container>
                </Menu>
            </div>
        );
    }
}

export default MainMenu;